import { Switch, SwitchProps, styled } from "@mui/material";
import { memo } from "react";

interface IToggleItemProps extends SwitchProps {
	remDimension?: number;
}

const ToggleItem = ({ remDimension, ...rest }: IToggleItemProps) => {
	const StyledMuiSwitch = styled(Switch)<SwitchProps>(({ theme }) => {
		const dim = remDimension ?? 2.75;

		return {
			width: `${dim * 2.125}rem`,
			height: `${dim * 1.0625}rem`,
			padding: `${dim / 4}rem`,
			margin: "1.25rem 0.5rem",
			"& .MuiSwitch-switchBase": {
				margin: `${dim * 0.03125}rem`,
				padding: 0,
				transform: "translateX(0)",
				"&.Mui-checked": {
					transform: `translateX(${dim}rem)`
				},
				"&:not(.Mui-checked)": {
					"& .MuiSwitch-thumb": {
						backgroundColor: theme.palette.grey.A400
					}
				}
			},
			"& .MuiSwitch-thumb": {
				width: `${dim}rem`,
				height: `${dim}rem`
			},
			"& .MuiSwitch-track": {
				borderRadius: `${dim / 2}rem`
			}
		};
	});

	return <StyledMuiSwitch {...rest} />;
};

export default memo(ToggleItem);
