import { memo } from "react";
import { DragDropContext, Droppable, OnDragEndResponder } from "react-beautiful-dnd";
import { UpSellingItem } from "../../../types";
import DraggableListItem from "./DraggableListItem";

export type DraggableListProps = {
	items: UpSellingItem[];
	onDragEnd: OnDragEndResponder;
	onExcludedChange: (pluId: string) => void;
	onVerticalSlide: (slidingPluId: string, direction: "up" | "down") => void;
};

const DraggableList = memo(({ items, onDragEnd, onExcludedChange, onVerticalSlide }: DraggableListProps) => {
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable-list">
				{(provided) => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						{items.map((item, index) => (
							<DraggableListItem
								key={item.pluId}
								item={item}
								index={index}
								isFirst={index === 0}
								isLastEnabled={index === items.filter((item) => !item.excluded).length - 1}
								isFirstDisabled={index === items.filter((item) => !item.excluded).length}
								isTop3={[0, 1, 2].includes(index)}
								isThird={index === 2}
								onExcludedChange={onExcludedChange}
								onVerticalSlide={onVerticalSlide}
							/>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
});

export default DraggableList;
