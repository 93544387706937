import { memo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { cart_getSimplifiedUid } from "../../../hooks/useCart";
import { Cart, CartItem } from "../../../types";
import TableWrapper from "../../Layout/Table/TableWrapper";
import "../OrderMaker.scss";
import "./OrderReviewDialog.scss";
import OrderReviewRow from "./OrderReviewRow";
import DialogCenter from "./Wrappers/DialogCenter";

interface FilteredOrderReviewDialogProps {
	cart: Cart;
	filterUid: string;
	addItemToCart: (cartItem: CartItem) => void;
	removeItemFromCart: (uid: string) => void;
	closeModal: () => void;
}

const FilteredOrderReviewDialog = ({ cart, filterUid, addItemToCart, removeItemFromCart, closeModal }: FilteredOrderReviewDialogProps) => {
	const { t } = useTranslation();

	const doNothing = useCallback(() => {}, []);

	useEffect(() => {
		if (cart.items.filter((item: CartItem) => cart_getSimplifiedUid(item.uid) === filterUid).length === 0) {
			closeModal();
		}
	}, [cart, filterUid, closeModal]);

	return (
		<DialogCenter closeModal={closeModal} modalTitle={t("checkout.orderReview.disambiguateRemove")}>
			<TableWrapper>
				{cart.items
					?.filter((item: CartItem) => cart_getSimplifiedUid(item.uid) === filterUid)
					.map((row: CartItem) => (
						<OrderReviewRow
							key={row.uid}
							cartItem={row}
							clickRow={doNothing}
							addItemToCart={addItemToCart}
							removeItemFromCart={removeItemFromCart}
							hideCustomize={true}
						/>
					))}
			</TableWrapper>
		</DialogCenter>
	);
};

export default memo(FilteredOrderReviewDialog);
