import { SwipeDownOutlined } from "@mui/icons-material";
import { Box, Button, Paper } from "@mui/material";
import { cloneDeep, isEqual } from "lodash-es";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { upSellingItemNotOrdered } from "../../constants/defaults";
import { UpSellingItem } from "../../types";
import { calculateUpSellingResultList, indexUpSellingList, reorderUpSellingList, switchPosition } from "../../utils/UpSelling";
import DraggableList from "../Layout/Draggable/DraggableList";

interface AdminUpSellingProps {
	pluListFromMenu: UpSellingItem[];
	currentUpSellingList: UpSellingItem[];
	setCurrentUpSellingList: (currentUpSellingList: UpSellingItem[]) => void;
}

const AdminUpSelling = ({ pluListFromMenu, currentUpSellingList, setCurrentUpSellingList }: AdminUpSellingProps) => {
	const { t } = useTranslation();

	const initialUpSelling: UpSellingItem[] = useMemo(
		() => calculateUpSellingResultList(currentUpSellingList, pluListFromMenu),
		[currentUpSellingList, pluListFromMenu]
	);

	const [localUpSellingList, setLocalUpSellingList] = useState<UpSellingItem[]>(initialUpSelling);

	const resetListOrder = useCallback((): void => setLocalUpSellingList([]), []);

	const handleExcludedChange = useCallback(
		(changingPluId: string): void => {
			const newUpSellingList: UpSellingItem[] = cloneDeep(localUpSellingList);
			const changingIndex: number = newUpSellingList.findIndex((item: UpSellingItem) => item.pluId === changingPluId);
			if (changingIndex === -1) return;

			newUpSellingList[changingIndex].excluded = !newUpSellingList[changingIndex].excluded;
			newUpSellingList[changingIndex].order = upSellingItemNotOrdered;

			setLocalUpSellingList(indexUpSellingList(reorderUpSellingList(newUpSellingList)));
		},
		[localUpSellingList]
	);

	const handleDragEnd = useCallback(
		({ destination, source }: DropResult): void => {
			// dropped outside the list
			if (!destination) return;
			if (localUpSellingList[destination.index].excluded) return;

			setLocalUpSellingList(switchPosition(localUpSellingList, source.index, destination.index));
		},
		[localUpSellingList]
	);

	const handleVerticalSlide = useCallback(
		(slidingPluId: string, direction: "up" | "down"): void => {
			const newUpSellingList: UpSellingItem[] = cloneDeep(localUpSellingList);
			const sourceIndex: number = newUpSellingList.findIndex((item: UpSellingItem) => item.pluId === slidingPluId);
			if (sourceIndex === -1) return;

			// avoid slide up  first item and slide down last item of the list
			if (sourceIndex === 0 && direction === "up") return;
			if (sourceIndex === newUpSellingList.length - 1 && direction === "down") return;

			const destinationIndex = direction === "up" ? sourceIndex - 1 : sourceIndex + 1;
			setLocalUpSellingList(switchPosition(localUpSellingList, sourceIndex, destinationIndex));
		},
		[localUpSellingList]
	);

	useEffect(() => {
		const newCurrentUpSellingList = localUpSellingList.map((item: UpSellingItem) => {
			return { ...item, itemSelectable: null };
		});
		if (isEqual(currentUpSellingList, newCurrentUpSellingList)) return;

		setCurrentUpSellingList(newCurrentUpSellingList);
	}, [localUpSellingList, currentUpSellingList, setCurrentUpSellingList]);

	return (
		<Box className="reorderFrame">
			<Paper sx={{ p: "1.5rem" }} elevation={0} className="upSellingTab">
				<DraggableList
					items={localUpSellingList}
					onDragEnd={handleDragEnd}
					onExcludedChange={handleExcludedChange}
					onVerticalSlide={handleVerticalSlide}
				/>
				{localUpSellingList.length > 13 ? (
					<SwipeDownOutlined
						color="disabled"
						sx={{
							position: "fixed",
							top: "50%",
							right: "5%",
							width: "6rem",
							height: "6rem"
						}}
					/>
				) : null}
			</Paper>
			&nbsp;
			<hr />
			<Button
				sx={{ mx: "1rem", my: "0.5rem" }}
				color="success"
				variant="contained"
				onClick={resetListOrder}
				size="small"
				disabled={localUpSellingList.length === 0}
			>
				{t("common.reset").toUpperCase()}
			</Button>
		</Box>
	);
};

export default memo(AdminUpSelling);
