import { Add, Check, CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	List,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Slider,
	Stack,
	Typography
} from "@mui/material";
import { Dispatch, SetStateAction, SyntheticEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckoutMethod, getCheckoutMethodName, getPaymentMethodName, Language, PaymentMethod, WebviewApp } from "../../constants";
import { defaultConfig } from "../../constants/defaults";
import { useLanguage } from "../../hooks/useLanguage";
import { ElectronicPaymentSatispay, ElectronicPaymentStripe } from "../../services/4Delivery";
import { PaymentErrorResponse, SettingsConfig, Vat } from "../../types";
import LanguageFlag from "../LanguageFlags/LanguageFlag";
import AdminDivider from "../Layout/Divider/AdminDivider";
import InputSave from "../Layout/Form/InputSave";
import { useMessage } from "../MessageHandler/MessageService";
import { useTheme } from "../Theme/ThemeWrapper";
import AdminSettingsCash from "./AdminSettingsCash";

interface AdminSettingsProps {
	isAdmin: boolean;
	onChooseKioskId: (kioskId: number) => void;
	currentSettings: SettingsConfig;
	setCurrentSettings: Dispatch<SetStateAction<SettingsConfig>>;
	currentHideSnoozed: number;
	setCurrentHideSnoozed: Dispatch<SetStateAction<number>>;
	menuVatList: Vat[];
	satispayDisabled: boolean;
	setSatispayDisabled: Dispatch<SetStateAction<boolean>>;
}

const AdminSettings = ({
	isAdmin,
	onChooseKioskId,
	currentSettings,
	setCurrentSettings,
	currentHideSnoozed,
	setCurrentHideSnoozed,
	menuVatList,
	satispayDisabled,
	setSatispayDisabled
}: AdminSettingsProps) => {
	const { t } = useTranslation();
	const { theme, container, bridge } = useTheme();
	const message = useMessage();
	const { language: currentLanguage } = useLanguage();

	const [dialogOpen, setDialogOpen] = useState<boolean>(false);

	const availableIds: number[] = useMemo(
		() =>
			// looking for the first integer available between 101 and 199.
			// Available here means not used as Settings Kiosk Id yet.
			Array.from({ length: 99 }, (_, i) => i + 101).filter((key: number) => {
				return theme.config.settings.every((config: SettingsConfig) => config.kioskId !== key);
			}),
		[theme.config.settings]
	);

	const settingToggler = (key: keyof SettingsConfig) => {
		setCurrentSettings((prev: SettingsConfig) => ({ ...prev, [key]: !prev[key] }));
	};

	const toggleIconOutlinedInversed = () => settingToggler("iconOutlineInversed");
	const toggleSoundEffectEnabled = () => settingToggler("isSoundEffectEnabled");
	const toggleFastSync = () => settingToggler("hasFastSync");
	const toggleShowImages = () => settingToggler("showImages");
	const toggleShowDescription = () => settingToggler("showDescription");
	const toggleCardButtonsBehaviourInverted = () => settingToggler("cardButtonsBehaviourInverted");
	const toggleSkipPlaceNumberChoice = () => settingToggler("skipPlaceNumberChoice");
	const toggleDebugEnabled = () => settingToggler("isDebugEnabled");
	const toggleSingleCategoryInverted = () => settingToggler("singleCategory");
	const toggleDirectPayInverted = () => settingToggler("directPay");

	const handleCardHideLinkDetailsChange = () => {
		settingToggler("cardHideLinkToDetails");
		if (!currentSettings.cardHideLinkToDetails && currentSettings.cardHideOneClickAdd) {
			settingToggler("cardHideOneClickAdd");
		}
	};
	const handleCardHideOneClickAddChange = () => {
		settingToggler("cardHideOneClickAdd");
		if (!currentSettings.cardHideOneClickAdd && currentSettings.cardHideLinkToDetails) {
			settingToggler("cardHideLinkToDetails");
		}
	};

	const toggleHideSnoozed = () => setCurrentHideSnoozed((prev: number) => (prev ? 0 : 1));

	const handlePlaceNumberLettersChange = (value: string) => {
		setCurrentSettings((prev: SettingsConfig) => ({ ...prev, placeNumberLetters: value }));
	};

	const handleTerminalSerialChange = (value: string) => {
		setCurrentSettings((prev: SettingsConfig) => ({ ...prev, terminalSerialNumber: value }));
	};

	const handleMenuColumnChange = (event: Event, value: number | number[]) => {
		setCurrentSettings((prev: SettingsConfig) => ({ ...prev, menuColumn: value as number }));
	};

	const handleAvailableLanguagesChange = (changedLanguage: Language) => {
		const languages: Language[] = [...currentSettings.availableLanguages];
		const index: number = languages.indexOf(changedLanguage);

		if (index === -1) {
			languages.push(changedLanguage);
		} else {
			if (changedLanguage === currentLanguage) return null;
			if (languages.length === 1) {
				message({
					title: t("common.warning"),
					description: t("system.admin.settings.languageMandatory"),
					okCallback: () => {},
					okLabel: t("common.ok")
				});
				return null; // at least one language must be enabled
			}
			languages.splice(index, 1);
		}

		setCurrentSettings((prev: SettingsConfig) => ({ ...prev, availableLanguages: languages }));
	};

	const handleAvailableCheckoutChange = (changedMethod: CheckoutMethod) => {
		const methods: CheckoutMethod[] = [...currentSettings.availableCheckoutMethods];
		const index: number = methods.indexOf(changedMethod);

		if (index === -1) {
			methods.push(changedMethod);
		} else {
			methods.splice(index, 1);
		}

		setCurrentSettings((prev: SettingsConfig) => ({ ...prev, availableCheckoutMethods: methods }));
	};

	const postSatispayTest = async (): Promise<boolean> => {
		const electronicPaymentSatispay = new ElectronicPaymentSatispay();

		message({
			title: t("common.crud.testing", { item: t("checkout.paymentModal.type.satispayLabel") }),
			description: t("system.admin.settings.alertWaitAction"),
			okCallback: () => {},
			okLabel: ""
		});

		let success = false;

		await electronicPaymentSatispay
			.test()
			.then(() => {
				success = true;
				message({
					title: `${t("common.crud.testing", { item: t("checkout.paymentModal.type.satispayLabel") })}: ${t("common.ok")}`,
					description: t("system.admin.settings.testOk"),
					okCallback: () => {},
					okLabel: t("common.ok")
				});
			})
			.catch((err: PaymentErrorResponse) => {
				message({
					title: `${t("common.crud.testing", { item: t("checkout.paymentModal.type.satispayLabel") })}: ${t("common.error")}`,
					description: t(`system.error.${err.error_code}`),
					okCallback: () => {},
					okLabel: t("common.ok")
				});
			});
		return success;
	};

	const handleAvailablePaymentChange = async (changedMethod: PaymentMethod) => {
		const methods: PaymentMethod[] = [...currentSettings.availablePaymentMethods];
		const index: number = methods.indexOf(changedMethod);

		const indexAlternativeStripeMethod: number = methods.indexOf(changedMethod === PaymentMethod.STRIPE ? PaymentMethod.STRIPE_RN : PaymentMethod.STRIPE);

		if (index === -1) {
			// add method
			switch (changedMethod) {
				case PaymentMethod.SATISPAY:
					await postSatispayTest().then((testResponse: boolean) => {
						if (testResponse) {
							methods.push(changedMethod); // you can't add Satispay: not enabled
						} else {
							setSatispayDisabled(true);
							message({
								title: t("common.warning"),
								description: t("system.admin.settings.satispayComing"),
								okCallback: () => {},
								okLabel: t("common.ok")
							});
						}
					});

					break;

				case PaymentMethod.STRIPE:
				case PaymentMethod.STRIPE_RN:
					if (indexAlternativeStripeMethod !== -1) {
						methods.splice(indexAlternativeStripeMethod, 1);
					}
					methods.push(changedMethod);
					break;

				default:
					methods.push(changedMethod);
					break;
			}
		} else {
			// remove method
			if (methods.length === 1) {
				message({
					title: t("common.warning"),
					description: t("system.admin.settings.paymentsMandatory"),
					okCallback: () => {},
					okLabel: t("common.ok")
				});
				return; // at least one method must be enabled
			}
			methods.splice(index, 1);
		}

		setCurrentSettings((prev: SettingsConfig) => ({ ...prev, availablePaymentMethods: methods }));
	};

	const postTerminalTest = async () => {
		const electronicPaymentStripe = new ElectronicPaymentStripe(currentSettings.terminalSerialNumber);

		message({
			title: t("common.crud.testing", { item: t("system.admin.settings.reader") }),
			description: t("system.admin.settings.alertWaitAction"),
			okCallback: () => {},
			okLabel: ""
		});

		await electronicPaymentStripe
			.test()
			.then(() => {
				message({
					title: `${t("common.crud.testing", { item: t("system.admin.settings.reader") })}: ${t("common.ok")}`,
					description: t("system.admin.settings.testOk"),
					okCallback: () => {},
					okLabel: t("common.ok")
				});
			})
			.catch((err: PaymentErrorResponse) => {
				message({
					title: `${t("common.crud.testing", { item: t("system.admin.settings.reader") })}: ${t("common.error")}`,
					description: t(`system.error.${err.error_code}`),
					okCallback: () => {},
					okLabel: t("common.ok")
				});
			});
	};

	const stripeRnTest = async () => {
		if (container === WebviewApp.reactNative && bridge.bridgeActive) {
			await bridge
				.testUsbStripeReader()
				.then((response: boolean) => {
					if (response) {
						message({
							title: `${t("common.crud.testing", { item: t("system.admin.settings.reader") })}: ${t("common.ok")}`,
							description: t("system.admin.settings.testOk"),
							okCallback: () => {},
							okLabel: t("common.ok")
						});
					} else throw new Error("error");
				})
				.catch(() => {
					message({
						title: `${t("common.crud.testing", { item: t("system.admin.settings.reader") })}: ${t("common.error")}`,
						description: t(`system.error.reader_is_offline`),
						okCallback: () => {},
						okLabel: t("common.ok")
					});
				});
		}
	};

	const handleClickOpen = () => setDialogOpen(true);

	const handleClose = (event: SyntheticEvent<unknown>, reason?: string) => {
		if (reason !== "backdropClick") {
			setDialogOpen(false);
		}
	};

	const handleNewKioskId = (event: SelectChangeEvent<number>) => {
		message({
			title: t("common.crud.new", { item: t("system.admin.settings.kioskProfile") + " " + event.target.value }),
			description: t("system.admin.settings.newKioskIdConfirm"),
			okCallback: () => onChooseKioskId(event.target.value as number),
			okLabel: t("common.yes"),
			cancelCallback: () => handleClose({} as SyntheticEvent<unknown>),
			cancelLabel: t("common.cancel") ?? ""
		});
	};

	const doNothing = () => {};

	return (
		<Paper sx={{ p: "1.5rem" }} elevation={0}>
			{isAdmin ? (
				<Box>
					<Stack direction="row" alignItems="top" justifyContent="space-between">
						{theme.config.settings.length > 1 ? (
							<List
								sx={{
									borderRadius: "1rem",
									border: `0.0625rem solid ${theme.systemTheme.palette.grey.A400}`,
									padding: "0.25rem 1rem",
									width: "60%"
								}}
							>
								{theme.config.settings.map((settingsConfig: SettingsConfig) => {
									const currentProfile: boolean = currentSettings.kioskId === settingsConfig.kioskId;
									const defaultProfile: boolean = settingsConfig.kioskId === 0;
									// const disabled: boolean = defaultProfile || currentProfile;

									const handleClickProfile = () => onChooseKioskId(settingsConfig.kioskId);

									return (
										<ListItemButton
											key={settingsConfig.kioskId}
											onClick={handleClickProfile}
											sx={{ "&.hidden": { display: "none" } }}
											className={defaultProfile ? "hidden" : ""}
										>
											<ListItemAvatar>{currentProfile ? <Check color="secondary" /> : null}</ListItemAvatar>
											<ListItemText color="secondary">
												<Typography color={currentProfile ? "secondary" : undefined}>
													{t("system.admin.settings.kioskProfile") +
														" " +
														(settingsConfig.kioskId !== 0 ? settingsConfig.kioskId : t("common.default"))}
												</Typography>
											</ListItemText>
											{currentProfile ? <CheckBox color="secondary" /> : <CheckBoxOutlineBlank />}
										</ListItemButton>
									);
								})}
							</List>
						) : null}
						<Box sx={{ maxWidth: "35%" }}>
							<Button
								variant="contained"
								color="success"
								onClick={handleClickOpen}
								disabled={false}
								startIcon={<Add />}
								sx={{ lineHeight: 1.25, minHeight: "3.5rem" }}
							>
								{t("common.crud.new", { item: t("system.admin.settings.kioskProfile") }).toUpperCase()}
							</Button>
							<Dialog disableEscapeKeyDown open={dialogOpen} onClose={handleClose}>
								<DialogTitle>{t("common.crud.new", { item: t("system.admin.settings.kioskProfile") }).toUpperCase()}</DialogTitle>
								<DialogContent>
									<Typography>{t("system.admin.settings.kioskProfileAvailable")}</Typography>
									<Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
										<FormControl fullWidth sx={{ mt: 1 }}>
											<InputLabel>{t("common.ellipsis")}</InputLabel>
											<Select value={""} label={t("system.admin.settings.kioskProfile")} onChange={handleNewKioskId}>
												{availableIds.map((availableId: number, index: number) => (
													<MenuItem key={index} value={availableId}>
														{availableId}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Box>
								</DialogContent>
								<DialogActions>
									<Button onClick={handleClose}>{t("common.cancel")}</Button>
								</DialogActions>
							</Dialog>
						</Box>
					</Stack>
				</Box>
			) : null}

			{currentSettings.kioskId > 0 ? (
				<Card sx={{ mt: "4rem" }} elevation={3}>
					<CardHeader
						title={
							<Typography color={theme.systemTheme.palette.primary.contrastText} fontWeight="bold">
								{t("system.admin.settings.kioskProfile").toUpperCase() + " " + currentSettings.kioskId}
							</Typography>
						}
						sx={{ backgroundColor: `${theme.systemTheme.palette.primary.main}` }}
					/>

					<CardContent>
						{isAdmin ? (
							<>
								<AdminDivider label={t("system.admin.settings.languages")} />
								<Stack direction="row" alignItems="center" justifyContent="left" flexWrap="wrap">
									{Object.values(Language).map((value: string, index, array) => {
										const language: Language = value as Language;
										const availableLanguages = currentSettings.availableLanguages ?? defaultConfig.settings.availableLanguages;
										const existing: boolean = availableLanguages.some((searchLang: string) => searchLang === value);
										const last: boolean = array.length === index + 1;

										const onChange = () => handleAvailableLanguagesChange(language);

										return (
											<Stack key={value} direction="row" alignItems="center" justifyContent="left" sx={{ mr: last ? 0 : "2rem" }}>
												<Checkbox
													color="success"
													checked={existing}
													onChange={onChange}
													sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
												/>
												<LanguageFlag language={language} selected={language === currentLanguage} onClick={doNothing} />
											</Stack>
										);
									})}
								</Stack>

								<AdminDivider label={t("system.admin.settings.checkoutMethod")} />
								<Box>
									{Object.values(CheckoutMethod).map((value: string | CheckoutMethod) => {
										if (isNaN(Number(value))) return null; //looping over a "Numeric ENUM" returns both names and values. Get rid of names!
										const method: CheckoutMethod = Number(value) as CheckoutMethod;
										const methodName: string = getCheckoutMethodName(method);
										const existing: boolean = currentSettings.availableCheckoutMethods.some(
											(searchMethod: CheckoutMethod) => searchMethod === method
										);

										const handleClick = () => handleAvailableCheckoutChange(method);

										return (
											<Box key={method}>
												<Checkbox
													color="success"
													checked={existing}
													onChange={handleClick}
													sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
												/>
												{t(`checkout.choose.${methodName}`)}
											</Box>
										);
									})}
								</Box>

								{currentSettings.availableCheckoutMethods.includes(CheckoutMethod.PLACE_NUMBER) ? (
									<>
										<Box sx={{ mt: "2rem" }}>
											<Typography>{t("system.admin.settings.placeNumberLetters")}</Typography>
											<InputSave
												color="secondary"
												label={t("system.admin.settings.placeNumber")}
												value={currentSettings.placeNumberLetters}
												onSave={(value) => handlePlaceNumberLettersChange(value.toString())}
												InputProps={{ inputProps: { maxLength: 4, minLength: 0 } }}
											/>
										</Box>

										<Box sx={{ mt: 0 }}>
											<Checkbox
												color="success"
												checked={(currentSettings.skipPlaceNumberChoice as boolean) ?? false}
												onChange={toggleSkipPlaceNumberChoice}
												sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
											/>
											{t("system.admin.settings.skipPlaceNumberChoice")}
										</Box>
									</>
								) : null}

								<AdminDivider label={t("system.admin.settings.sync")} />
								<Box sx={{ mt: "1rem" }}>
									<Checkbox
										color="success"
										checked={(currentSettings.hasFastSync as boolean) ?? false}
										onChange={toggleFastSync}
										sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
									/>
									{t("system.admin.settings.hasFastSync")}
								</Box>

								<AdminDivider label={t("system.admin.theme.productsPresentation")} />
								<Box sx={{ mt: "1rem" }}>
									<Checkbox
										color="success"
										checked={(currentSettings.showImages as boolean) ?? false}
										onChange={toggleShowImages}
										sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
									/>
									{t("system.admin.theme.preset.showImages")}
								</Box>

								<AdminDivider label={t("system.admin.theme.productPreview")} />
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center"
									}}
								>
									<Slider
										color="secondary"
										sx={{ m: "3rem", width: "100%" }}
										value={currentSettings.menuColumn}
										min={1}
										max={3}
										marks={[
											{ value: 1, label: t("system.admin.theme.listView") },
											{ value: 2, label: "2 " + t("system.admin.theme.columns") },
											{ value: 3, label: "3 " + t("system.admin.theme.columns") }
										]}
										step={1}
										valueLabelDisplay="on"
										onChange={handleMenuColumnChange}
									/>
								</Box>
								<Box sx={{ mt: "1rem" }}>
									<Checkbox
										color="success"
										checked={(currentSettings.showDescription as boolean) ?? false}
										onChange={toggleShowDescription}
										sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
									/>
									{t("system.admin.theme.preset.showDescription")}
								</Box>
								<Box>
									<Checkbox
										color="success"
										checked={Boolean(currentHideSnoozed)}
										onChange={toggleHideSnoozed}
										sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
									/>
									{t("system.admin.settings.hideSnoozed")}
								</Box>
								<Box sx={{ mt: 0 }}>
									<Checkbox
										color="success"
										checked={(currentSettings.cardHideLinkToDetails as boolean) ?? false}
										onChange={handleCardHideLinkDetailsChange}
										sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
									/>
									{t("system.admin.settings.cardHideLinkToDetails")}
								</Box>
								<Box sx={{ mt: 0 }}>
									<Checkbox
										color="success"
										checked={(currentSettings.cardHideOneClickAdd as boolean) ?? false}
										onChange={handleCardHideOneClickAddChange}
										sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
									/>
									{t("system.admin.settings.cardHideOneClickAdd")}
								</Box>
								{currentSettings.menuColumn !== 1 ? (
									<Box sx={{ mt: 0 }}>
										<Checkbox
											color="success"
											checked={(currentSettings.cardButtonsBehaviourInverted as boolean) ?? false}
											onChange={toggleCardButtonsBehaviourInverted}
											sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
										/>
										{t("system.admin.settings.cardButtonsBehaviourInverted")}
									</Box>
								) : null}

								<AdminDivider label={t("system.admin.theme.menuPresentation")} />
								<Box>
									<Checkbox
										color="success"
										checked={(currentSettings.iconOutlineInversed as boolean) ?? false}
										onChange={toggleIconOutlinedInversed}
										sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
									/>
									{t("system.admin.theme.preset.iconOutlineInversed")}
								</Box>
								<Box>
									<Checkbox
										color="success"
										checked={(currentSettings.singleCategory as boolean) ?? false}
										onChange={toggleSingleCategoryInverted}
										sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
									/>
									{t("system.admin.theme.preset.isSingleCategoryEnabled")}
								</Box>
								<Box>
									<Checkbox
										color="success"
										checked={(currentSettings.directPay as boolean) ?? false}
										onChange={toggleDirectPayInverted}
										sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
									/>
									{t("system.admin.theme.preset.isDirectPayEnabled")}
								</Box>

								<AdminDivider label={t("system.admin.theme.sounds")} />
								<Box>
									<Checkbox
										color="success"
										checked={(currentSettings.isSoundEffectEnabled as boolean) ?? false}
										onChange={toggleSoundEffectEnabled}
										sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
									/>
									{t("system.admin.theme.preset.isSoundEffectEnabled")}
								</Box>
							</>
						) : null}

						<AdminDivider label={t("system.admin.settings.payments")} />
						<Box>
							{isAdmin ? (
								<>
									{Object.values(PaymentMethod).map((value: string | PaymentMethod) => {
										if (isNaN(Number(value))) return null; //looping over a "Numeric ENUM" returns both names and values. Get rid of names!
										const method: PaymentMethod = Number(value) as PaymentMethod;
										const existing: boolean = currentSettings.availablePaymentMethods.some(
											(searchMethod: PaymentMethod) => searchMethod === method
										);
										const isSatispay: boolean = method === PaymentMethod.SATISPAY;
										const isStripeRn: boolean = method === PaymentMethod.STRIPE_RN;

										const handleClick = () => handleAvailablePaymentChange(method);

										return (
											<Box key={method}>
												<Checkbox
													color="success"
													checked={existing}
													onChange={handleClick}
													sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
													disabled={isSatispay ? satispayDisabled : isStripeRn ? container !== WebviewApp.reactNative : false}
												/>
												{t(`checkout.paymentModal.type.${getPaymentMethodName(method)}`)}
											</Box>
										);
									})}
								</>
							) : null}

							{currentSettings.availablePaymentMethods.includes(PaymentMethod.STRIPE) ? (
								<Paper elevation={0} sx={{ p: "2rem" }}>
									<Stack direction="row" alignItems="center" justifyContent="left">
										<InputSave
											color="secondary"
											label={t("system.admin.settings.terminalSerialNumber")}
											value={currentSettings.terminalSerialNumber}
											onSave={(value) => handleTerminalSerialChange(value.toString().toUpperCase())}
											InputProps={{ inputProps: { maxLength: 20, minLength: 10 } }} //should be 15 characters: STRIPE
											disabled={!currentSettings.availablePaymentMethods.includes(PaymentMethod.STRIPE) || !isAdmin}
										/>
										<Button
											sx={{ marginLeft: "0.25rem" }}
											variant="contained"
											color="success"
											onClick={postTerminalTest}
											disabled={!currentSettings.availablePaymentMethods.includes(PaymentMethod.STRIPE)}
										>
											{t("common.test").toUpperCase()}
										</Button>
									</Stack>
								</Paper>
							) : null}

							{currentSettings.availablePaymentMethods.includes(PaymentMethod.STRIPE_RN) ? (
								<Paper elevation={0} sx={{ p: "2rem" }}>
									<Stack direction="row" alignItems="center" justifyContent="left">
										<Button
											sx={{ marginLeft: "0.25rem" }}
											variant="contained"
											color="success"
											onClick={stripeRnTest}
											disabled={!currentSettings.availablePaymentMethods.includes(PaymentMethod.STRIPE_RN)}
										>
											{t("common.crud.testing", { item: t("system.admin.settings.reader") })}
										</Button>
									</Stack>
								</Paper>
							) : null}
						</Box>

						<AdminDivider label={t("system.admin.settings.cashSystem")} />
						<AdminSettingsCash
							isAdmin={isAdmin}
							currentSettings={currentSettings}
							setCurrentSettings={setCurrentSettings}
							menuVatList={menuVatList}
						/>

						<AdminDivider label={t("system.admin.settings.isDebugEnabled")} />
						<Box sx={{ mt: "1rem" }}>
							<Checkbox
								color="success"
								checked={(currentSettings.isDebugEnabled as boolean) ?? false}
								onChange={toggleDebugEnabled}
								sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
							/>
							{t("system.admin.settings.isDebugEnabled")}
						</Box>
					</CardContent>
				</Card>
			) : null}
		</Paper>
	);
};

export default AdminSettings;
