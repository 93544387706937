import { Box } from "@mui/material";
import { cloneDeep } from "lodash-es";
import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ItemInfoTypology } from "../../../constants";
import { useCurrency } from "../../../hooks/useCurrency";
import { CartItem } from "../../../types";
import i18n from "../../../utils/i18n/i18n";
import { tItemInfo } from "../../../utils/i18nMenu";
import "./OrderReviewDialog.scss";

interface OrderReviewRowOptionsProps {
	options: CartItem[];
}

interface TypologyList {
	name: string;
	list: CartItem[];
}

const OrderReviewRowOptions = ({ options }: OrderReviewRowOptionsProps) => {
	const { t } = useTranslation();
	const { currency } = useCurrency();
	const nonBreakableSpace: string = "\u00A0";
	const whiteSpacesRegex: RegExp = /\s+/g;

	const filterByTypology = useCallback((typology: string, options: CartItem[]): CartItem[] => {
		return options.filter((c: CartItem) => c.item.itemInfo.tipology === typology);
	}, []);

	const optionList: TypologyList[] = useMemo(() => {
		const clonedOptions = cloneDeep(options);

		const newOptionList = [] as TypologyList[];
		for (const typology in ItemInfoTypology) {
			newOptionList.push({
				name: typology,
				list: filterByTypology(typology, clonedOptions)
			});
		}

		return newOptionList;
	}, [options, filterByTypology]);

	return (
		<ul className="variations">
			{optionList
				.filter((typology: TypologyList) => typology.list.length > 0)
				.map((typology: TypologyList, indexParent: number) => {
					const typologyName: string = t(`checkout.orderReview.variation.${typology.name}`).toUpperCase();

					return (
						<li key={typology.name + "_" + indexParent}>
							<strong>{typologyName}</strong>

							{typology.list.map((cartItem: CartItem, index: number) => {
								const sign: string = typology.name === ItemInfoTypology.cond_sub ? "-" : "+";
								const text: string = tItemInfo(i18n.language, cartItem.item.itemInfo, "shortText").replace(whiteSpacesRegex, nonBreakableSpace);

								return (
									<span key={typology.name + "_" + indexParent + "_" + index}>
										{index > 0 ? "," : ""} {cartItem.quantity > 1 ? cartItem.quantity : ""}
										{cartItem.quantity > 1 ? (
											<Box component="span" sx={{ fontSize: "0.75rem" }}>
												x&nbsp;
											</Box>
										) : null}
										{text}
										{cartItem.quantity > 0 && cartItem.resultingPrice > 0
											? "(" + sign + currency(cartItem.resultingPrice * cartItem.quantity) + ")"
											: ""}
									</span>
								);
							})}
						</li>
					);
				})}
		</ul>
	);
};

export default memo(OrderReviewRowOptions);
