import { alpha, Box, Container, ThemeProvider, Typography } from "@mui/material";
import { memo, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useStaticImage from "../../../hooks/useStaticImage";
import { useTheme } from "../../Theme/ThemeWrapper";
import "./BottomBar.scss";

type Props = {
	children?: ReactNode;
};

const BottomBar = ({ children }: Props) => {
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { image: logo } = useStaticImage("system/logoLabware.svg");

	const textColor: string = theme.systemTheme.palette.secondary.main;
	const bgColor: string = useMemo(() => alpha(theme.systemTheme.palette.info.main, 0.95), [theme.systemTheme]);
	const poweredColor: string = useMemo(() => alpha(theme.systemTheme.palette.background.paper, 0.4), [theme.systemTheme]);

	return (
		<ThemeProvider theme={theme.systemTheme}>
			<Container className="bottom-bar-container" sx={{ backgroundColor: bgColor, color: textColor }}>
				<Box className="bottom-bar-main-box">
					<Box className="bottom-bar-children-box">{children}</Box>
					<Box className="bottom-bar-powered-by">
						<Typography fontSize="1rem" color={poweredColor}>
							{t("checkout.powered")}
						</Typography>
						<Box
							className="icon-image"
							sx={{
								backgroundColor: poweredColor,
								mask: `url(${logo}) no-repeat center / contain`,
								height: "1.5rem",
								width: "5rem"
							}}
						/>
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
	);
};

export default memo(BottomBar);
