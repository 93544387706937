import { Box, Typography, alpha } from "@mui/material";
import { memo, useMemo, useRef } from "react";
import ScrollSpy from "react-ui-scrollspy";
import { ModalViewName } from "../../../constants";
import { Cart, Category, ItemSelectable } from "../../../types";
import i18n from "../../../utils/i18n/i18n";
import { tCategory } from "../../../utils/i18nMenu";
import { useTheme } from "../../Theme/ThemeWrapper";
import "./ItemsCategoriesList.scss";
import ItemsCategory from "./ItemsCategory";

interface ItemsCategoriesListProps {
	selectedCategory: Category;
	cart: Cart;
	onNewCartItem: (itemSelectable: ItemSelectable, openDetail?: boolean, referral?: ModalViewName) => void;
	decreaseItemCart: (itemSelectable: ItemSelectable) => void;
}

const ItemsCategoriesList = ({ selectedCategory, cart, onNewCartItem, decreaseItemCart }: ItemsCategoriesListProps) => {
	const { theme, settings } = useTheme();
	const parentScrollContainerRef = useRef<HTMLDivElement | null>(null);

	const bgFaderColor: string = useMemo(
		() =>
			`linear-gradient(0deg, ${alpha(theme.customTheme.palette.background.default, 1)} 0%, ${alpha(
				theme.customTheme.palette.background.default,
				0
			)} 100%)`,
		[theme.customTheme.palette.background.default]
	);

	const categoryLabel = useMemo(() => tCategory(i18n.language, selectedCategory), [selectedCategory]);

	return (
		<>
			<Typography
				className={settings.singleCategory ? "categoryTitleSingle" : "categoryTitle"}
				variant={settings.singleCategory ? "h1" : "h3"}
				color={"primary"}
			>
				{categoryLabel}
			</Typography>
			<Box className="menuItemList" ref={parentScrollContainerRef}>
				<ScrollSpy scrollThrottle={100} useBoxMethod={true} parentScrollContainerRef={parentScrollContainerRef} activeClass="ss-active-demo-2">
					<Box id="init"></Box>
					{selectedCategory.children.map((categoryChild, index, children) => (
						<ItemsCategory
							key={categoryChild.categoryCode}
							categoryChild={categoryChild}
							categorySiblings={children.length}
							cart={cart}
							onNewCartItem={onNewCartItem}
							decreaseItemCart={decreaseItemCart}
						/>
					))}
				</ScrollSpy>
				<Box className="verticalFiller items" />
				<Box className="verticalFader" sx={{ background: bgFaderColor }} />
			</Box>
		</>
	);
};

export default memo(ItemsCategoriesList);
