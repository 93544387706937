import { useEffect, useState } from "react";

const useStaticImage = (fileName: string) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState(null);
	const [image, setImage] = useState(null);

	useEffect(() => {
		const fetchImage = async () => {
			try {
				const { default: asset } = await import(`../assets/${fileName}`);
				setImage(asset);
			} catch (err: any) {
				setError(err);
			} finally {
				setLoading(false);
			}
		};

		fetchImage();
	}, [fileName]);

	return {
		loading,
		error,
		image
	};
};

export default useStaticImage;
