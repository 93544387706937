import { ColorizeOutlined, Star } from "@mui/icons-material";
import { alpha, Box, Card, CardContent, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Typography } from "@mui/material";
import { cloneDeep } from "lodash-es";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Font } from "../../constants";
import { defaultConfig } from "../../constants/defaults";
import { Color, LayoutConfig } from "../../types";
import { areColorsContrasted } from "../../utils/color";
import ColorPicker from "../Layout/ColorPicker/ColorPicker";
import { useMessage } from "../MessageHandler/MessageService";
import { useTheme } from "../Theme/ThemeWrapper";
import "./AdminTheme.scss";

interface AdminThemeProps {
	currentCustomTheme: LayoutConfig;
	setCurrentCustomTheme: (customTheme: LayoutConfig) => void;
}

const AdminTheme = ({ currentCustomTheme, setCurrentCustomTheme }: AdminThemeProps) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const message = useMessage();
	const selectablePresets: LayoutConfig[] = defaultConfig.customThemes;
	const customName: string = "custom";

	const [isPickerOpen, setIsPickerOpen] = useState<boolean[]>([false, false, false, false]);

	const openPopover = (index: number): void => {
		const newIsPickerOpen = { ...isPickerOpen };
		newIsPickerOpen[index] = true;
		setIsPickerOpen(newIsPickerOpen);
	};

	const closePopover = (): void => {
		setIsPickerOpen([false, false, false, false]);
	};

	const doNothing = () => {};

	const updateCurrentCustomTheme = (customTheme: LayoutConfig, isPreset: boolean = false): void => {
		const newCustomTheme: LayoutConfig = { ...customTheme };
		if (isPreset === false) {
			newCustomTheme.themeName = customName;
		}
		setCurrentCustomTheme(newCustomTheme);
	};

	const activatePreset = (presetName: string): void => {
		message({
			title: t("system.admin.theme.changePresetWarningTitle"),
			description: t("system.admin.theme.changePresetWarning"),
			okLabel: t("common.ok"),
			okCallback: () => {
				const activatingConfig: LayoutConfig =
					defaultConfig.customThemes.find((preset) => preset.themeName === presetName) ?? defaultConfig.customThemes[0];
				const newCustomTheme: LayoutConfig = cloneDeep(activatingConfig);
				updateCurrentCustomTheme(newCustomTheme, true);
			},
			cancelLabel: t("common.cancel") ?? "",
			cancelCallback: doNothing
		});
	};

	const handleFontTitleChange = (event: SelectChangeEvent<Font>) => {
		const newCustomTheme: LayoutConfig = cloneDeep(currentCustomTheme);
		newCustomTheme.font.fontTitle = event.target.value as Font;
		updateCurrentCustomTheme(newCustomTheme);
	};

	const handleColorChange = (colorName: string, newColor: string, oldColor: string) => {
		if (!newColor) return;
		if (newColor === oldColor) return;

		const newCustomTheme: LayoutConfig = cloneDeep(currentCustomTheme);
		newCustomTheme.color = {
			...currentCustomTheme.color,
			[`${colorName}`]: newColor as Color
		};
		updateCurrentCustomTheme(newCustomTheme);
	};

	const activeTriangle = (isActive: boolean): JSX.Element => {
		return (
			<>
				{isActive && (
					<Box
						className="activeTriangle"
						sx={{
							borderTopColor: theme.systemTheme.palette.secondary.main,
							borderRightColor: theme.systemTheme.palette.secondary.main
						}}
					>
						<Box sx={{ color: theme.systemTheme.palette.secondary.contrastText }}>
							<Star />
						</Box>
					</Box>
				)}
			</>
		);
	};

	return (
		<Paper sx={{ p: "1.5rem" }} elevation={0}>
			<Card className="preset large" sx={{ overflow: "visible", boxShadow: theme.systemTheme.shadows[6] }}>
				{activeTriangle(currentCustomTheme.themeName === customName)}
				<CardContent>
					<Box className="squares" sx={{ mt: "1rem !important" }}>
						{Object.entries(currentCustomTheme.color).map(([colorName, colorCode]: [string, Color], index: number) => {
							const handleOpenPopover = () => openPopover(index);
							const handleLocalColorChange = (newColor: string) => handleColorChange(colorName, newColor, colorCode);
							const textColor = alpha(theme.systemTheme.palette.getContrastText(colorCode), 0.8);

							return (
								<Box key={`custom_square_${colorName}`}>
									<Box component="span">
										<Typography className="paletteTitle" variant="body2" fontSize={15}>
											{t(`system.admin.theme.preset.palette.${colorName}`)}
										</Typography>
										<Box onClick={handleOpenPopover} className="square" sx={{ backgroundColor: colorCode, color: textColor }}>
											<Box sx={{ position: "absolute", width: "100%" }}>
												<ColorizeOutlined />
											</Box>
											<Typography variant="body2">{colorCode}</Typography>
										</Box>
									</Box>
									<ColorPicker color={colorCode} onChange={handleLocalColorChange} isOpen={isPickerOpen[index]} close={closePopover} />
								</Box>
							);
						})}
					</Box>
					{!areColorsContrasted(currentCustomTheme.color.primary, currentCustomTheme.color.background) ||
					!areColorsContrasted(currentCustomTheme.color.secondary, currentCustomTheme.color.background) ? (
						<Box sx={{ maxWidth: "35rem" }}>
							<Typography fontSize={"1rem"} color={theme.systemTheme.palette.warning.main}>
								{t("system.admin.theme.contrastWarning")}
							</Typography>
						</Box>
					) : null}
					<Box sx={{ mt: "1.5rem", mb: 0 }}>
						<FormControl className="selectFont">
							<InputLabel id="demo-simple-select-label">{t("system.admin.theme.preset.fontTitle")}</InputLabel>
							<Select
								color="success"
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={currentCustomTheme.font.fontTitle}
								label={<Typography>{t("system.admin.theme.preset.fontTitle")}</Typography>}
								onChange={handleFontTitleChange}
							>
								{Object.values(Font).map((key: string) => (
									<MenuItem key={key} value={key}>
										<Typography fontFamily={key}>{key}</Typography>
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
				</CardContent>
			</Card>

			<br></br>
			<br></br>
			<Typography color={theme.systemTheme.palette.text.primary}>{t("system.admin.theme.choseThemePreset")}</Typography>
			<br></br>
			<Box className="presets">
				{selectablePresets.map((config: LayoutConfig) => {
					const isActive: boolean = currentCustomTheme.themeName === config.themeName;

					const handleActivatePreset = () => {
						if (!isActive) activatePreset(config.themeName);
					};

					return (
						<Card
							className="preset"
							key={`preset_${config.themeName}`}
							sx={{ boxShadow: theme.systemTheme.shadows[6] }}
							onClick={handleActivatePreset}
						>
							{activeTriangle(isActive)}
							<CardContent className="content">
								<Typography variant="h4" fontFamily={config.font.fontTitle}>
									{config.themeName}
								</Typography>
								<Box className="squares">
									{Object.entries(config.color).map(([colorName, colorCode]: [string, Color]) => (
										<Box component="span" key={`preset_${config.themeName}_square_${colorName}`}>
											<Box className="square" sx={{ backgroundColor: colorCode }}></Box>
										</Box>
									))}
								</Box>
								<Box>
									<Typography>
										<Typography variant="caption" fontFamily={config.font.fontTitle}>
											{config.font.fontTitle}
										</Typography>
									</Typography>
								</Box>
							</CardContent>
						</Card>
					);
				})}
			</Box>
		</Paper>
	);
};

export default AdminTheme;
