import { Chip, ChipProps, styled } from "@mui/material";
import { memo } from "react";

const ChipRem = ({ ...rest }: ChipProps) => {
	const StyledMuiChip = styled(Chip)<ChipProps>(() => {
		return {
			height: "2rem",
			borderRadius: "1rem"
		};
	});

	return <StyledMuiChip {...rest} />;
};

export default memo(ChipRem);
