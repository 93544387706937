import { RestartAltRounded } from "@mui/icons-material";
import { Box, Container, Dialog, Typography } from "@mui/material";
import { FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import ConfirmModal from "./Checkout/Modals/Wrappers/ConfirmModal";
import TouchButton from "./Layout/Buttons/TouchButton";

const ErrorPage = ({ error, resetErrorBoundary }: FallbackProps) => {
	const { t } = useTranslation();

	// Call resetErrorBoundary() to reset the error boundary and retry the render.

	return (
		<Container>
			<Dialog open={true} fullScreen={true} fullWidth={true} maxWidth="xl" onClose={() => {}} scroll="paper">
				<ConfirmModal>
					<Box>
						<Box>
							<Typography color="primary" variant="h4" textAlign="center">
								{`${t("common.error").toUpperCase()}!`}
							</Typography>
						</Box>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<TouchButton color="error" variant="contained" size="large" startIcon={<RestartAltRounded />} onClick={resetErrorBoundary}>
							<Typography variant="button">{t("common.reload")}</Typography>
						</TouchButton>
					</Box>
				</ConfirmModal>
			</Dialog>
		</Container>
	);
};

export default ErrorPage;
