import { linkBridge } from "@webview-bridge/web";
import { useMemo } from "react";
import type { AppBridge, AppBridgeState } from "../types/reactNative/bridgeOutput"; // Import the type 'appBridge' declared in native

export const initialBridge: AppBridgeState = {
	bridgeActive: false,
	collectUsbPayment: async (piClientSecret: string) => {
		alert("not support collectUsbPayment: " + piClientSecret);
		return {};
	},
	cancelUsbPayment: async () => {
		alert("not support cancelUsbPayment");
		return false;
	},
	testUsbStripeReader: async () => {
		alert("not support testUsbStripeReader");
		return false;
	},
	persistLog: async (value: string) => {
		alert("not support persistLog: " + value);
	},
	data: {
		stripeLocationId: null
	},
	setStripeLocationId: async (value: string | null) => {
		alert("not support setStripeLocationId: " + value);
	}
};

export const useBridgeInit = () => {
	const bridge = useMemo(
		() =>
			linkBridge<AppBridge>({
				throwOnError: true,
				initialBridge: initialBridge
				// onReady: () => {
				// 	console.log("bridge is ready");
				// }
			}),
		[]
	);

	/**
	 * Check the bridge is available and it is an actual bridge (non the initial fake one)
	 */
	const isWebViewBridgeAvailable = useMemo((): boolean => {
		return bridge.isWebViewBridgeAvailable && bridge.store.getState().bridgeActive;
	}, [bridge]);

	return {
		bridge,
		isWebViewBridgeAvailable
	};
};
