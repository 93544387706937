import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LogChannel, PaymentRequestStatus, WebviewApp } from "../../../../constants";
import { BridgeResult } from "../../../../types/reactNative/bridgeOutput";
import { useLogger } from "../../../../utils/useLogger";
import { useMessage } from "../../../MessageHandler/MessageService";
import { useTheme } from "../../../Theme/ThemeWrapper";

interface ActivateReaderRNProps {
	clientSecret: string;
	setRequestStatus: Dispatch<SetStateAction<PaymentRequestStatus>>;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	cancelOrder: () => void;
}
const ActivateReaderRN = ({ clientSecret, setRequestStatus, setIsLoading, cancelOrder }: ActivateReaderRNProps) => {
	const { container, bridge } = useTheme();
	const { t } = useTranslation();
	const message = useMessage();
	const { warn } = useLogger();

	const activate = useCallback(async () => {
		const handleError = () => {
			warn("ActivateReaderRN error", LogChannel.payment);
			message({
				title: "",
				description: t("system.error.reader_not_found"),
				okCallback: () => {
					activate();
				},
				okLabel: t("common.retry"),
				cancelCallback: () => cancelOrder(),
				cancelLabel: t("common.cancel") ?? ""
			});
		};

		if (!bridge.bridgeActive) {
			cancelOrder();
		} else {
			setIsLoading(true);

			if (container === WebviewApp.reactNative) {
				await bridge
					.collectUsbPayment(clientSecret ?? "")
					.then((result: BridgeResult) => setRequestStatus(PaymentRequestStatus.checkingIntent))
					.catch(() => handleError())
					.finally(() => setIsLoading(false));
			}
		}
	}, [clientSecret, bridge, container, setRequestStatus, setIsLoading, message, cancelOrder, t, warn]);

	useEffect(() => {
		/* eslint-disable react-hooks/exhaustive-deps */
		activate();
	}, []);

	return null;
};

export default ActivateReaderRN;
