import { CheckCircleOutlineRounded, Info } from "@mui/icons-material";
import { Box, Container, Grid, Paper, Stack, Typography, alpha } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IIdleTimer } from "react-idle-timer";
import Markdown from "react-markdown";
import { cart_clean } from "../../../hooks/useCart";
import { useCurrency } from "../../../hooks/useCurrency";
import { CartItem, CategoryList, ItemLinkedList } from "../../../types";
import { useGetRestaurantMedia } from "../../../utils/cdnAssets";
import i18n from "../../../utils/i18n/i18n";
import { tItemInfo } from "../../../utils/i18nMenu";
import CounterButtonGroup from "../../Layout/Buttons/CounterButtonGroup";
import TouchArea from "../../Layout/Buttons/TouchArea";
import TouchButton from "../../Layout/Buttons/TouchButton";
import ChipRem from "../../Layout/Chip/ChipRem";
import ProductImage from "../../Layout/Images/ProductImage";
import { useMessage } from "../../MessageHandler/MessageService";
import { useTheme } from "../../Theme/ThemeWrapper";
import EditableItemBottomBar from "../BottomBar/EditableItemBottomBar";
import "../OrderMaker.scss";
import "./EditableItemDialog.scss";
import SetMenu from "./SetMenu/SetMenu";
import VariationItemLinkedList from "./Variations/VariationItemLinkedList";
import DialogFull from "./Wrappers/DialogFull";

interface EditableItemDialogProps {
	isNewCartItemSelected: boolean;
	cartItem: CartItem;
	closeModal: () => void;
	addItemToCart: (cartItem: CartItem) => void;
	categories: CategoryList[];
	idleInstance?: IIdleTimer;
	disclaimer: string;
	disclaimerNotFound: boolean;
}

const EditableItemDialog = ({
	isNewCartItemSelected = true,
	cartItem,
	closeModal,
	addItemToCart,
	categories,
	idleInstance,
	disclaimer,
	disclaimerNotFound
}: EditableItemDialogProps) => {
	const { theme, settings } = useTheme();
	const { t } = useTranslation();
	const { currency } = useCurrency();
	const message = useMessage();
	const { url: logoUrl, isFallback: isLogoFallback } = useGetRestaurantMedia("logo");

	const [currentCartItem, setCurrentCartItem] = useState<CartItem>(cartItem);
	const [linkedListWithIssue, setLinkedListWithIssue] = useState<number[]>([]);
	const [validate, setValidate] = useState<boolean>(false);

	const getCustomCategory = useCallback((itemLinkedList: ItemLinkedList[]): CategoryList[] => {
		return itemLinkedList.length > 0 ? [{ categoryListId: "CUSTOM", itemLinkedList: itemLinkedList } as CategoryList] : [];
	}, []);

	const getCategoriesFromIds = useCallback(
		(ids: string[]): CategoryList[] => {
			return ids.length === 0 ? [] : categories.filter((c: CategoryList) => ids.includes(c.categoryListId));
		},
		[categories]
	);

	const lists: ItemLinkedList[] = useMemo(() => {
		const categoryList: CategoryList[] = [
			...getCustomCategory(currentCartItem.item.itemLinkedList),
			...getCategoriesFromIds(currentCartItem.item.categoryListLink),
			...getCategoriesFromIds(currentCartItem.item.categoryOptListLink)
		];

		const listArray: ItemLinkedList[] = [];
		categoryList?.forEach((category: CategoryList, index: number) =>
			category.itemLinkedList?.forEach((itemLinkedList: ItemLinkedList) => {
				if (!itemLinkedList.itemSelectableAllSnoozed) {
					// remove list with all items not selectable
					const tempId: string = index + "|CAT|" + category.categoryListId;
					listArray.push({ ...itemLinkedList, tempId: tempId });
				}
			})
		);

		return listArray;
	}, [currentCartItem.item, getCustomCategory, getCategoriesFromIds]);

	useEffect(() => {
		setValidate(currentCartItem.quantity !== 0 && linkedListWithIssue.length === 0);
	}, [linkedListWithIssue, currentCartItem.quantity]);

	const changeQuantity = useCallback(
		(value: 1 | -1): void => {
			const newCartItem: CartItem = { ...currentCartItem };
			newCartItem.quantity += value;
			setCurrentCartItem(newCartItem);
		},
		[currentCartItem]
	);

	const cleanAndSave = useCallback((): void => {
		addItemToCart({
			...currentCartItem,
			options: cart_clean(currentCartItem.options)
		});
	}, [currentCartItem, addItemToCart]);

	const handleShowAllergenModal = useCallback(() => {
		idleInstance?.pause();
		message({
			title: t("checkout.editableItemDialog.allergensTitle").toUpperCase(),
			description: (
				<Box
					className="disclaimer"
					sx={{
						"& h3": { color: theme.systemTheme.palette.primary.main },
						"& h4": { color: theme.systemTheme.palette.secondary.main }
					}}
				>
					<Markdown>{disclaimer}</Markdown>
				</Box>
			),
			okCallback: () => {
				idleInstance?.resume();
			},
			okLabel: t("common.ok").toUpperCase()
		});
	}, [disclaimer, idleInstance, t, message, theme.systemTheme.palette]);

	const renderAddButtons: JSX.Element | null = useMemo(() => {
		const increment = () => changeQuantity(+1);
		const decrement = () => changeQuantity(-1);
		const labelDesc = currency(currentCartItem.resultingPrice * currentCartItem.quantity) + " | " + t("checkout.cartBottomBar.addToCart");

		return currentCartItem.item?.itemInfo ? (
			<Stack direction="column" justifyContent="center" alignItems="center" sx={{ mb: "1rem" }}>
				<CounterButtonGroup
					counter={currentCartItem.quantity}
					min={1}
					size="large"
					quantityToCompare={isNewCartItemSelected ? undefined : cartItem.quantity}
					onDecrement={decrement}
					onIncrement={increment}
				/>

				<TouchButton
					color="success"
					variant="contained"
					fillBgWhenDisabled={true}
					size="large"
					endIcon={<CheckCircleOutlineRounded />}
					disableElevation
					sx={{ mt: "1.5rem" }}
					onClick={cleanAndSave}
					disabled={!validate}
					tone="cart"
				>
					<Typography variant="button">{labelDesc}</Typography>
				</TouchButton>
			</Stack>
		) : null;
	}, [currentCartItem, isNewCartItemSelected, cartItem.quantity, validate, cleanAndSave, changeQuantity, currency, t]);

	return (
		<DialogFull>
			<Box className="main" sx={{ margin: "0 !important" }}>
				<Box className={`itemContainer ${currentCartItem.item.isSetMenu ? "setMenu" : ""}`}>
					<Box
						className="titles"
						sx={{
							backgroundColor: theme.systemTheme.palette.background.default,
							height: (currentCartItem.item.isSetMenu ? "36vh" : "48vh") + " !important"
						}}
					>
						<Grid container spacing="1rem">
							<Grid item xs={6}>
								<Box className="itemImg">
									<ProductImage
										imageName={settings.showImages ? cartItem.item.itemInfo.imageName : ""}
										parentCategoryIcon={cartItem.item.parentCategoryIcon}
										height="100%"
										width="100%"
										alt={cartItem.item.itemInfo.imageName}
										iconColor={theme.customTheme.palette.primary.main}
									/>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Container className="itemName">
									<Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ height: "7vh" }}>
										{isLogoFallback ? null : <img className="customLogo" src={logoUrl} alt="" loading="lazy" />}
									</Box>
									<Paper className="boxNamePrice" elevation={0}>
										<Typography variant="h3" sx={{ fontSize: "3rem" }}>
											{tItemInfo(i18n.language, cartItem.item.itemInfo, "shortText")}
										</Typography>
										<Typography variant="h4">{currency(currentCartItem.item.itemInfo.price)}</Typography>
									</Paper>
								</Container>
							</Grid>
							<Grid item xs={12}>
								<Paper className="itemDescription" elevation={0}>
									<Typography variant="h5"> {tItemInfo(i18n.language, currentCartItem.item.itemInfo, "longText") ?? ""} </Typography>

									<Typography
										component="div"
										variant="body2"
										color={theme.systemTheme.palette.grey[500]}
										p={2}
										sx={disclaimerNotFound ? { opacity: 0 } : null}
									>
										<sub>{t("checkout.editableItemDialog.disclaimer")}</sub>
										<TouchArea onClick={handleShowAllergenModal} disabled={disclaimerNotFound}>
											<ChipRem
												variant="outlined"
												icon={<Info sx={{ color: `${theme.systemTheme.palette.secondary.main} !important` }} />}
												sx={{ ml: "1rem", color: theme.systemTheme.palette.secondary.main }}
												label={t("checkout.editableItemDialog.allergensTitle")}
											/>
										</TouchArea>
									</Typography>
								</Paper>
							</Grid>
						</Grid>

						{currentCartItem.item.isSetMenu ? null : renderAddButtons}
					</Box>

					<Box className="scrollable" sx={{ width: "100%", mt: currentCartItem.item.isSetMenu ? "36vh" : "48vh" }}>
						{currentCartItem.item.hasFields && lists.length > 0 ? (
							currentCartItem.item.isSetMenu ? (
								<Box sx={{ my: "1.5rem", py: "1rem" }}>
									<SetMenu
										lists={lists}
										currentCartItem={currentCartItem}
										setCurrentCartItem={setCurrentCartItem}
										oldOptions={cartItem.options}
										linkedListWithIssue={linkedListWithIssue}
										setLinkedListWithIssue={setLinkedListWithIssue}
										renderAddButtons={renderAddButtons}
									/>
								</Box>
							) : (
								<Box sx={{ m: "1.5rem", p: "1rem" }}>
									<Typography variant="h5" color="primary">
										{t("checkout.editableItemDialog.variationTitle")}
									</Typography>

									{lists?.map((itemLinkedList: ItemLinkedList) => (
										<VariationItemLinkedList
											key={itemLinkedList.tempId}
											currentCartItem={currentCartItem}
											setCurrentCartItem={setCurrentCartItem}
											itemLinkedList={itemLinkedList}
											oldOptions={cartItem.options}
											linkedListWithIssue={linkedListWithIssue}
											setLinkedListWithIssue={setLinkedListWithIssue}
										/>
									))}
								</Box>
							)
						) : null}
					</Box>
				</Box>

				<div className="verticalFiller" />
				<Box
					className="verticalFader"
					sx={{
						background: `linear-gradient(0deg, ${alpha(theme.systemTheme.palette.background.default, 1)} 0%, ${alpha(
							theme.systemTheme.palette.background.default,
							0
						)} 100%)`
					}}
				/>
			</Box>

			<EditableItemBottomBar
				amount={currentCartItem.resultingPrice * currentCartItem.quantity}
				cancel={closeModal}
				confirm={cleanAndSave}
				itemsCount={currentCartItem.quantity}
				disabled={!validate}
			></EditableItemBottomBar>
		</DialogFull>
	);
};

export default memo(EditableItemDialog);
