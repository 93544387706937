import { Container, Dialog } from "@mui/material";
import { MouseEvent as ReactMouseEvent, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { AdminRole, CheckoutMethod, ModalViewName, RoutePath } from "../../constants";
import { defaultAppConfig } from "../../constants/defaults";
import { useModal } from "../../hooks/useModal";
import useOrderDocumentStack from "../../hooks/useOrderDocumentStack";
import { useWebview } from "../../hooks/useWebview";
import { GetMenuFromStorage, useMenu, useRestaurantInfo } from "../../services/4Delivery";
import { useRT } from "../../services/Rt/useRT";
import { CheckoutMenu, KioskMenu, PrintToCashSystemResponse, Vat } from "../../types";
import PinPickerModal from "../Checkout/Modals/PinPickerModal";
import LanguageFlagStrip from "../LanguageFlags/LanguageFlagStrip";
import HiddenAdminButton from "../Layout/Buttons/HiddenAdminButton";
import TouchArea from "../Layout/Buttons/TouchArea";
import { useMessage } from "../MessageHandler/MessageService";
import { useTheme } from "../Theme/ThemeWrapper";
import "./Home.scss";
import HomeBackground from "./HomeBackground";

const Home = () => {
	const message = useMessage();
	const { theme, settings } = useTheme();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { modal, openModal, closeModal } = useModal();
	const { menuLoading } = useMenu();
	const { cashSystemCheckStatus } = useRT();
	const { processDocumentStack } = useOrderDocumentStack();
	const { getCloudConfigAndMenu } = useRestaurantInfo();
	const { appReload } = useWebview();

	const missingLocalStorageRequirements = (): boolean => {
		const u = localStorage.getItem("username") ?? "";
		const p = localStorage.getItem("password") ?? "";
		const h = localStorage.getItem("menuHash") ?? "";
		const m = localStorage.getItem("menu") ?? "";
		return u === "" || p === "" || h === "" || m === "";
	};

	const gotoCheckoutChoose = (e: ReactMouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		if (missingLocalStorageRequirements()) {
			navigate(RoutePath.loginPage);
			return;
		}

		// If Fast Sync is enabled always reload menu on any interaction
		if (settings.hasFastSync) {
			getCloudConfigAndMenu();
		}

		// Do not access checkout if fiscal mode is enabled and not all the Vats are mapped
		const kioskMenu: KioskMenu = GetMenuFromStorage();
		let everyVatsAreMapped: boolean = true;
		kioskMenu.vatList?.forEach((vat: Vat) => {
			const vatIsMapped: boolean = settings.vatAssociations.some((searchVat: Vat) => searchVat.id === vat.id);
			if (!vatIsMapped) everyVatsAreMapped = false;
		});
		if (!everyVatsAreMapped && settings.isFiscalMode) {
			message({
				title: t("common.warning"),
				description: t("system.admin.settings.vatMappingMandatory"),
				okCallback: () => {},
				okLabel: t("common.ok")
			});
			return;
		}

		// Do not access "Choose Page" if less than 2 checkout methods are available and actually exist in the Menu
		// Read available checkout method from Configuration
		const methods: CheckoutMethod[] = settings.availableCheckoutMethods;

		// Refine the list of methods. Exclude those not present in the json menu
		const existingMethods = methods.filter((method: CheckoutMethod) =>
			kioskMenu.checkoutMenus.some((checkoutMenu: CheckoutMenu) => checkoutMenu.checkout === method)
		);

		// If there are available and existing checkout methods... go to choose page
		if (existingMethods.length > 1) {
			navigate(RoutePath.choosePage);
		} else if (existingMethods.length === 1) {
			// if there is just one method... skip choose page
			navigate(RoutePath.orderPage.replace(":method", existingMethods[0] as unknown as string));
		} else {
			//...otherwise warn and stay on homepage
			message({
				title: t("common.warning"),
				description: t("system.admin.settings.checkoutMandatory"),
				okCallback: () => {},
				okLabel: t("common.ok")
			});
		}
	};

	const handleConfirmPin = (pin: string): void => {
		if (pin === (theme.config.pin ?? defaultAppConfig.pin)) {
			navigate(RoutePath.adminPage.replace(":role", AdminRole.administrator as unknown as string));
		} else if (pin === (theme.config.pinOperator ?? defaultAppConfig.pinOperator)) {
			navigate(RoutePath.adminPage.replace(":role", AdminRole.operator as unknown as string));
		} else {
			closeModal();
		}
	};

	const handleOpenModal = (): void => {
		if (missingLocalStorageRequirements()) {
			navigate(RoutePath.loginPage);
		} else {
			openModal(ModalViewName.pinPicker);
		}
	};

	useEffect(() => {
		if (!missingLocalStorageRequirements()) getCloudConfigAndMenu();

		processDocumentStack();

		// Initialize last update since reopening the app the service worker is fresh
		if (sessionStorage.getItem("lastUpdate") === null) sessionStorage.setItem("lastUpdate", new Date().toISOString());
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	const timer = useIdleTimer({
		name: "30minutes-inactive-timer-home-page",
		onIdle: () => {
			getCloudConfigAndMenu();
			timer.reset();

			// if it's passed 24h since last update reload the app
			const lastUpdate = new Date(sessionStorage.getItem("lastUpdate") ?? "");
			const now = new Date();
			if (now.valueOf() - lastUpdate.valueOf() > 1000 * 60 * 60 * 24) {
				appReload();
			}
		},
		timeout: 1000 * 60 * 30
	});

	const checkStatusCashSystem = useIdleTimer({
		name: "5minutes-inactive-check-cash-system-and-fiscal-document-to-send",
		onIdle: () => {
			if (settings.isCashSystemEnabled) {
				checkCashSystemStatus();
				checkStatusCashSystem.reset();
			}
			processDocumentStack();
		},
		timeout: 1000 * 60 * 5
	});

	const checkCashSystemStatus = async () => {
		await cashSystemCheckStatus(settings.cashSystemIP).then((response: PrintToCashSystemResponse) => {
			// while idling in home, cashsystem error gets showed only if response is successful
			// and it's in error, it's in inactivity or key != 1
			if (
				response.status === 200 &&
				(response.data.status.error === true || response.data.status.inattivita === true || response.data.status.key !== 1)
			) {
				message({
					title: "",
					description: t("system.error.cashSystem.statusError"),
					okCallback: () => checkCashSystemStatus(),
					okLabel: t("common.retry"),
					cancelCallback: () => navigate(RoutePath.homePage),
					cancelLabel: t("common.cancel") ?? ""
				});
			}
		});
	};

	const handleContainerClick = useCallback(
		(e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
			modal.open || menuLoading ? e.stopPropagation() : gotoCheckoutChoose(e);
		},
		[modal, menuLoading]
	);

	return (
		<Container className="homeContainer">
			<TouchArea className={`bgLayer`} onClick={handleContainerClick}>
				<HomeBackground />
			</TouchArea>

			<HiddenAdminButton handleAction={handleOpenModal} />

			<LanguageFlagStrip onClick={gotoCheckoutChoose} />

			<Dialog open={modal.open} fullScreen={!modal.overlay} fullWidth={true} maxWidth="xl" onClose={closeModal} scroll="paper">
				{modal.name === ModalViewName.pinPicker && <PinPickerModal closeModal={closeModal} confirmPin={handleConfirmPin} />}
			</Dialog>

			<Dialog open={menuLoading} fullScreen={true} fullWidth={true} maxWidth="xl" scroll="paper" className="menuLoading">
				<br></br>
				&nbsp;&nbsp;&nbsp;{t("common.crud.loading", { item: t("system.admin.cloud.menu") }) + t("common.ellipsis")}
			</Dialog>
		</Container>
	);
};

export default Home;
