import { CheckCircleOutlineRounded, HighlightOffRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import TouchButton from "../../Layout/Buttons/TouchButton";
import PinKeyboard from "../../Layout/Keyboard/PinKeyboard";
import { useTheme } from "../../Theme/ThemeWrapper";
import ConfirmModal from "./Wrappers/ConfirmModal";

interface PlacePickerModalProps {
	closeModal: () => void;
	confirmPlaceNumber: (placeNumber: string) => void;
}

const PlacePickerModal = ({ closeModal, confirmPlaceNumber }: PlacePickerModalProps) => {
	const { t } = useTranslation();
	const { settings } = useTheme();

	const [placeText, setPlaceText] = useState<string>("");

	const handleConfirm = useCallback(() => confirmPlaceNumber(placeText), [placeText, confirmPlaceNumber]);

	return (
		<ConfirmModal>
			<Box>
				<Typography color="primary" variant="h4" textAlign="center">
					{t("checkout.eatInPlacePicker.pickYourPlace")}
				</Typography>
				<PinKeyboard digitText={placeText} setDigitText={setPlaceText} additional4Characters={settings.placeNumberLetters ?? ""} maxLength={4} />
			</Box>
			<Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
				<TouchButton color="error" variant="contained" size="large" startIcon={<HighlightOffRounded />} onClick={closeModal}>
					<Typography variant="button">{t("checkout.eatInPlacePicker.cancel")}</Typography>
				</TouchButton>
				<TouchButton
					color="success"
					variant="contained"
					size="large"
					endIcon={<CheckCircleOutlineRounded />}
					disabled={placeText === "" || Number(placeText) === 0}
					onClick={handleConfirm}
				>
					<Typography variant="button">{t("checkout.eatInPlacePicker.confirm")}</Typography>
				</TouchButton>
			</Box>
		</ConfirmModal>
	);
};

export default memo(PlacePickerModal);
