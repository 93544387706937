import { StepButton, StepButtonProps } from "@mui/material";
import { memo } from "react";
import useSoundEffect, { SoundEffectProps } from "../../../hooks/effects/useSoundEffect";

interface TouchStepButtonProps extends StepButtonProps, SoundEffectProps /*, LongPressEffectProps*/ {}

const TouchStepButton = ({ muteSound, tone, /*hasLongPress,*/ ...rest }: TouchStepButtonProps) => {
	const { playSound } = useSoundEffect({ muteSound, tone });

	/** All step buttons must have the LongPress effect active */
	// const { pressEvent, pressRelease } = useLongPressEffect({ hasLongPress: true, longPressClickCallback: rest.onClick });

	return <StepButton disableRipple disableTouchRipple onClickCapture={playSound} /* onTouchStart={pressEvent} onTouchEnd={pressRelease} */ {...rest} />;
};

export default memo(TouchStepButton);
