import { callAxios, callAxiosResponseInterface } from "../../utils/httpClient/AxiosWrapper";

const prefixUrl: string = "http://system.";

/**
 * Webview endpoints
 */
export const ServiceWebviewEndpoints = {
	terminate: prefixUrl + "appterminate",
	reload: prefixUrl + "webviewreload"
};

const callWebview = (url: string): Promise<callAxiosResponseInterface> => {
	return new Promise((resolve) => {
		callAxios({
			method: "post",
			url: url,
			body: JSON.stringify({}),
			headers: JSON.stringify({ "Content-Type": "text/plain" }),
			cashsystem: true
		})
			.then((response) => resolve(response))
			.catch((err) => resolve(err));
	});
};

export const callCloseApp = () => callWebview(ServiceWebviewEndpoints.terminate);
export const callReloadApp = () => callWebview(ServiceWebviewEndpoints.reload);
