"use client";

import { CssBaseline } from "@mui/material";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./components/ErrorPage";
import Main from "./components/Main";
import { MessageServiceProvider } from "./components/MessageHandler/MessageService";
import { ThemeWrapper } from "./components/Theme/ThemeWrapper";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./utils/i18n/i18n";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
// Call resetErrorBoundary() to reset the error boundary and retry the render.
const handleReset = () => {
	// Reset the state of your app so the error doesn't happen again
	localStorage.clear();
	window.location.href = window.location.origin;
};

// The theme provider is declared here and handled in Main
root.render(
	// <StrictMode>
	<ErrorBoundary FallbackComponent={ErrorPage} onReset={handleReset}>
		<ThemeWrapper>
			<MessageServiceProvider>
				<CssBaseline />
				<Main />
			</MessageServiceProvider>
		</ThemeWrapper>
	</ErrorBoundary>
	// </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// Temporary remove console warning: react 19 will deprecated some feature -warning from react 18.3
if (process.env.NODE_ENV !== "production") {
	const originalWarn = console.error;
	console.error = (...args) => {
		if (args[0].includes("Support for defaultProps will be removed")) {
			return;
		}
		originalWarn(...args);
	};
}
