import { useState } from "react";
import { ModalViewName } from "../constants";
import { defaultModalView } from "../constants/defaults";
import { ModalView } from "../types";

interface useModalResponse {
	modal: ModalView;
	openModal: (viewName: ModalViewName, overlay?: boolean, referral?: ModalViewName) => void;
	closeModal: () => void;
}

export const useModal = (): useModalResponse => {
	const [modal, setModal] = useState<ModalView>(defaultModalView);

	const openModal = (viewName: ModalViewName, overlay?: boolean, referral?: ModalViewName) => {
		setModal({ ...modal, open: true, name: viewName, overlay: overlay ?? false, referral: referral ?? undefined });
	};
	const closeModal = () => {
		if (modal.referral) {
			openModal(modal.referral);
		} else {
			setModal({ ...modal, open: false, name: undefined, overlay: false, referral: undefined });
		}
	};

	return { modal, openModal, closeModal };
};
