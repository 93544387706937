import { Category, CategoryChild, CategoryChildTranslations, CategoryTranslations, ItemInfo, ItemInfoTranslations } from "../types";

/**
 * i18n Translation function for Menu's Category
 *
 * @param currentLang
 * @param category
 * @returns returns the translated "title" attribute of given Category
 */
export function tCategory(currentLang: string, category: Category): string {
	const defaultValue: string = category.title;

	const translation: CategoryTranslations | undefined = category.translations?.find((translation: CategoryTranslations) => translation.lang === currentLang);

	if (!translation) return defaultValue;

	return translation["title"];
}

/**
 * i18n Translation function for Menu's CategoryChild
 *
 * @param currentLang
 * @param categoryChild
 * @returns returns the translated "categoryTitle" attribute of given CategoryChild
 */
export function tCategoryChild(currentLang: string, categoryChild: CategoryChild): string {
	const defaultValue: string = categoryChild.categoryTitle;

	const translation: CategoryChildTranslations | undefined = categoryChild.translations?.find(
		(translation: CategoryChildTranslations) => translation.lang === currentLang
	);

	if (!translation) return defaultValue;

	return translation["categoryTitle"];
}

/**
 * i18n Translation function for Menu's ItemInfo
 *
 * @param currentLang
 * @param itemInfo
 * @param attribute
 * @returns returns the given attribute (translated) for the given ItemInfo
 */
export function tItemInfo(currentLang: string, itemInfo: ItemInfo | undefined, attribute: "shortText" | "shortTextAsciiSafe" | "longText"): string {
	if (!itemInfo) return "";

	const defaultValue: string = itemInfo[attribute] ?? itemInfo["shortText"] ?? "";

	const translation: ItemInfoTranslations | undefined = itemInfo.translations?.find((translation: ItemInfoTranslations) => translation.lang === currentLang);

	if (!translation) return defaultValue;

	return translation[attribute] ?? translation["shortText"] ?? defaultValue;
}
