import { Service4DeliveryEndpoints } from "..";
import { buildTheme } from "../../../components/Theme/ThemeConfig";
import { calculateKioskIdSetting, useTheme } from "../../../components/Theme/ThemeWrapper";
import { LogChannel } from "../../../constants";
import { defaultRestaurantInfo } from "../../../constants/defaults";
import { AppConfig, getCloudConfigResponse, RestaurantInfo } from "../../../types";
import { loadMediaAssets } from "../../../utils/cdnAssets";
import { callAxios, callAxiosResponseInterface, Payload } from "../../../utils/httpClient/AxiosWrapper";
import { RestaurantInfoVersionMapper } from "../../../utils/RestaurantInfoVersionMapper";
import { useLogger } from "../../../utils/useLogger";
import { useMenu } from "../Menu/useMenu";

export const useRestaurantInfo = () => {
	const { log, warn } = useLogger();
	const { settings, setContextTheme, setContextSetting, setContextRestaurantInfo, setContextHideSnoozed } = useTheme();
	const { downloadMenu } = useMenu();

	const getCloudConfigAndMenu = async () => {
		const restaurantInfo: RestaurantInfo = await getCloudConfig();
		const restaurantId = localStorage.getItem("username") ?? "";

		localStorage.setItem("restaurant_info", JSON.stringify(restaurantInfo));
		localStorage.setItem("localConfig", JSON.stringify(restaurantInfo.config ?? defaultRestaurantInfo.config));
		localStorage.setItem("localHideSnoozed", JSON.stringify(restaurantInfo.hide_snoozed ?? defaultRestaurantInfo.hide_snoozed));
		if (setContextTheme && setContextSetting && setContextRestaurantInfo && setContextHideSnoozed) {
			setContextRestaurantInfo(restaurantInfo);
			setContextTheme(buildTheme(restaurantInfo.config));
			setContextSetting(calculateKioskIdSetting(restaurantInfo.config.settings, settings.kioskId));
			setContextHideSnoozed(restaurantInfo.hide_snoozed ?? defaultRestaurantInfo.hide_snoozed);
		}

		await loadMediaAssets(restaurantInfo.media, restaurantInfo.media_baseurl, restaurantId).catch((error) => {
			//log(error);
		});

		const forceMenuDownload: boolean = restaurantInfo.config.versionNumber !== defaultRestaurantInfo.config.versionNumber;
		if (forceMenuDownload) {
			restaurantInfo.config.versionNumber = defaultRestaurantInfo.config.versionNumber;
			await setCloudConfig(restaurantInfo.config);
		}

		await downloadMenu(forceMenuDownload);
	};

	const getCloudConfig = async (): Promise<RestaurantInfo> => {
		return new Promise((resolve) => {
			callAxios({
				method: "post",
				url: Service4DeliveryEndpoints.configGet,
				body: JSON.stringify({}),
				headers: JSON.stringify({ accept: "*/*" })
			})
				.then((response: callAxiosResponseInterface) => {
					log("RestaurantInfo downloaded", LogChannel.cloudSync);
					const goodResponse = response as getCloudConfigResponse;

					// adapt the new restaurantInfo to the current version of the software
					resolve(new RestaurantInfoVersionMapper(goodResponse.data).map());
				})
				.catch((err) => {
					warn(["RestaurantInfo - get error: ", err], LogChannel.cloudSync);

					// try to load restaurantInfo from storage;
					const storedRestaurantInfo: string | null = localStorage.getItem("restaurant_info");
					resolve(storedRestaurantInfo ? (JSON.parse(storedRestaurantInfo) as RestaurantInfo) : defaultRestaurantInfo);
				});
		});
	};

	const setCloudConfig = async (currentConfig: AppConfig, hideSnoozed?: number): Promise<boolean> => {
		const payload: Payload = {
			config: JSON.stringify(currentConfig)
		};
		if (hideSnoozed !== undefined) payload.hide_snoozed = hideSnoozed;

		return new Promise((resolve) => {
			callAxios({
				method: "post",
				url: Service4DeliveryEndpoints.configSet,
				body: JSON.stringify(payload),
				headers: JSON.stringify({ accept: "*/*" })
			})
				.then((response: callAxiosResponseInterface) => {
					log("RestaurantInfo - Config uploaded", LogChannel.cloudSync);
					resolve(true);
				})
				.catch((err) => {
					warn(["RestaurantInfo - Set config error: ", err], LogChannel.cloudSync);
					resolve(false);
				});
		});
	};

	return { getCloudConfigAndMenu, getCloudConfig, setCloudConfig };
};
