import { cloneDeep } from "lodash-es";
import { defaultItemSelectable, upSellingItemNotOrdered } from "../constants/defaults";
import { CheckoutMenu, ItemSelectable, UpSellingItem } from "../types";

/**
 * Merge the PLU list extracted from the menu (pluListFromMenu) with previously uploaded ordered list in configuration (orderedConfList), if any.
 * The result is and ordered and refined list of UpSelling items.
 *
 * @param orderedConfList
 * @param pluListFromMenu
 * @returns
 */
export const calculateUpSellingResultList = (orderedConfList: UpSellingItem[], pluListFromMenu: UpSellingItem[]): UpSellingItem[] => {
	if (!pluListFromMenu) return [];
	if (!orderedConfList) return pluListFromMenu;

	const calculated: UpSellingItem[] = pluListFromMenu?.map((item: UpSellingItem) => {
		const index: number = orderedConfList?.findIndex((foundItem: UpSellingItem) => foundItem.pluId === item.pluId);
		if (index > -1) {
			item.excluded = orderedConfList[index].excluded;
			item.order = orderedConfList[index].order;
		}
		return item;
	});

	return reorderUpSellingList(calculated);
};

export const reorderUpSellingList = (unorderedList: UpSellingItem[]): UpSellingItem[] => {
	if (!unorderedList) return [];

	return unorderedList?.sort((a, b) => {
		// Only sort on excluded if not identical
		if (!a.excluded && b.excluded) return -1;
		if (a.excluded && !b.excluded) return 1;
		// Sort on order
		if (a.order < b.order) return -1;
		if (a.order > b.order) return 1;
		// Both identical, return 0
		return 0;
	});
};

export const indexUpSellingList = (list: UpSellingItem[]): UpSellingItem[] => {
	return list?.map((item: UpSellingItem, index: number) => {
		let newOrder: number = upSellingItemNotOrdered;
		if (!item.excluded) {
			newOrder = index + 1;
		}
		item.order = newOrder;
		return item;
	});
};

export const switchPosition = (list: UpSellingItem[], startIndex: number, endIndex: number): UpSellingItem[] => {
	const result = cloneDeep(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return indexUpSellingList(result);
};

export const getUpSellingItems = (checkoutMenu: CheckoutMenu, orderedConfList: UpSellingItem[], pluListFromMenu: UpSellingItem[]): ItemSelectable[] => {
	// calculate the upSelling list following the one in Administration panel
	const orderedUpSellingItems: UpSellingItem[] = calculateUpSellingResultList(orderedConfList, pluListFromMenu);

	if (!orderedUpSellingItems) return [];

	// remove excluded and snoozed; get only the itemSelectable
	const orderedItemSelectable: ItemSelectable[] = orderedUpSellingItems
		.filter((item: UpSellingItem) => item.excluded === false && item.itemSelectable?.itemInfo.snoozed === "false")
		.map((item: UpSellingItem) => item.itemSelectable ?? defaultItemSelectable);

	// remove items not in this checkoutMenu. Please note the pluListFromMenu contains PLUs from each CheckoutMenu;
	const checkOutPluIds: string[] = getPluIdFromCheckoutMenu(checkoutMenu);
	if (checkOutPluIds) {
		return orderedItemSelectable.filter((item: ItemSelectable) => checkOutPluIds.includes(item.itemInfo.uid));
	}

	return orderedItemSelectable;
};

const getPluIdFromCheckoutMenu = (checkoutMenu: CheckoutMenu): string[] => {
	const pluIds: string[] = [];

	checkoutMenu.categories.forEach((category) => {
		category.children.forEach((categoryChild) => {
			categoryChild.itemSelectable.forEach((itemSelectable) => {
				pluIds.push(itemSelectable.itemInfo.uid);
			});
		});
	});
	return pluIds;
};
