import { Step, StepProps, alpha, styled } from "@mui/material";
import { memo } from "react";

interface IStepSetMenuProps extends StepProps {
	isActive: boolean;
	isUpdating: boolean;
}

const StepSetMenu = ({ isActive, isUpdating, ...rest }: IStepSetMenuProps) => {
	const StyledMuiStep = styled(Step)<StepProps>(({ theme }) => {
		const dim = 2;

		return {
			flexBasis: "20%",
			"& .MuiStepConnector-horizontal": {
				display: "none"
			},
			"& .MuiStepButton-horizontal": {
				backgroundColor: rest.completed ? alpha(theme.palette.secondary.main, isUpdating ? 0.15 : 0.3) : theme.palette.grey[100],
				boxShadow: theme.shadows[isActive ? 5 : 1],
				borderRadius: "0.5rem",
				padding: `0 ${dim / 16}rem ${dim / 2}rem`,
				margin: `0 -${dim / 16}rem ${dim}rem`,
				minHeight: "11rem",
				alignItems: "flex-start",
				"& .MuiStepLabel-horizontal": {
					marginTop: "-1rem"
				}
			},
			"& .MuiSvgIcon-root.Mui-completed": {
				color: theme.palette.secondary.main
			},
			"& .MuiSvgIcon-root.Mui-active": {
				width: "3rem",
				height: "3rem",
				color: theme.palette.primary.main
			}
		};
	});

	return <StyledMuiStep {...rest} />;
};

export default memo(StepSetMenu);
