import { Box, Divider } from "@mui/material";
import { memo, useCallback, useMemo } from "react";
import { ModalViewName } from "../../../constants";
import { Cart, CartItem, CategoryChild, ItemSelectable } from "../../../types";
import i18n from "../../../utils/i18n/i18n";
import { tCategoryChild } from "../../../utils/i18nMenu";
import ChipRem from "../../Layout/Chip/ChipRem";
import TableWrapper from "../../Layout/Table/TableWrapper";
import { useTheme } from "../../Theme/ThemeWrapper";
import CardsWrapper from "./CardsWrapper";
import ItemCard, { ItemProps } from "./ItemCard";
import ItemRow from "./ItemRow";
import "./ItemsCategoriesList.scss";

interface ItemsCategoryProps {
	categoryChild: CategoryChild;
	categorySiblings: number;
	cart: Cart;
	onNewCartItem: (itemSelectable: ItemSelectable, openDetail?: boolean, referral?: ModalViewName) => void;
	decreaseItemCart: (itemSelectable: ItemSelectable) => void;
}

const ItemsCategory = ({ categoryChild, categorySiblings, cart, onNewCartItem, decreaseItemCart }: ItemsCategoryProps) => {
	const { theme, hideSnoozed, settings } = useTheme();

	/**
	 * For a single item, calculates quantities selected, using the current cart
	 */
	const getItemCount = useCallback(
		(i: ItemSelectable): number => {
			let counter: number = 0;
			cart.items.map((ci: CartItem) => (counter += ci.item.itemInfo.uid === i.itemInfo.uid ? ci.quantity : 0));
			return counter;
		},
		[cart.items]
	);

	/**
	 * Retrieve props for a single Item
	 */
	const getItemProps = useCallback(
		(i: ItemSelectable): ItemProps => {
			return {
				itemSelectable: i,
				itemCount: getItemCount(i),
				onNewCartItem,
				decreaseItemCart
			};
		},
		[getItemCount, onNewCartItem, decreaseItemCart]
	);

	const renderCardItem = useMemo(() => (i: ItemSelectable) => <ItemCard key={i.itemInfo.uid} {...getItemProps(i)} />, [getItemProps]);
	const renderRowItem = useMemo(() => (i: ItemSelectable) => <ItemRow key={i.itemInfo.uid} {...getItemProps(i)} />, [getItemProps]);

	return (
		<Box
			sx={hideSnoozed && categoryChild.itemSelectableAllSnoozed ? { display: "none" } : null}
			id={"category-" + categoryChild.categoryCode}
			className="subCategory"
			data-label={tCategoryChild(i18n.language, categoryChild)}
		>
			{categorySiblings > 1 && !settings.singleCategory ? (
				<Divider className="divider ">
					<ChipRem
						label={tCategoryChild(i18n.language, categoryChild)}
						color={"secondary"}
						sx={{ fontFamily: theme.customTheme.typography.caption.fontFamily }}
					/>
				</Divider>
			) : (
				<div className="shortFiller"></div>
			)}
			{settings.menuColumn !== 1 ? (
				<CardsWrapper menuColumn={settings.menuColumn}>
					{categoryChild.itemSelectable?.filter((i: ItemSelectable) => i.hasDepthLevel3 === false).map((i: ItemSelectable) => renderCardItem(i))}
				</CardsWrapper>
			) : (
				<TableWrapper>
					{categoryChild.itemSelectable?.filter((i: ItemSelectable) => i.hasDepthLevel3 === false).map((i: ItemSelectable) => renderRowItem(i))}
				</TableWrapper>
			)}
		</Box>
	);
};

export default memo(ItemsCategory);
