/* PAYMENT FLOWS */

/* STRIPE (external reader)
checkingCashSystem - creatingIntentAndActivateReader - checkingIntent - cancelingIntent */

/* STRIPE REACT NATIVE (embedded reader)
checkingCashSystem - creatingIntent - activateReaderRN - checkingIntent - cancelingIntent */

/* SATISPAY
checkingCashSystem - creatingIntentAndActivateReader - checkingIntent - cancelingIntent */

export enum PaymentRequestStatus {
	checkingCashSystem = "checkingCashSystem",
	creatingIntent = "creatingIntent",
	activateReaderRN = "activateReaderRN", // activate reader through React Native bridge
	creatingIntentAndActivateReader = "creatingIntentAndActivateReader",
	checkingIntent = "checkingIntent",
	cancelingIntent = "cancelingIntent"
}
