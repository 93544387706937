import { Button, ButtonProps, PaletteColor, styled } from "@mui/material";
import { memo } from "react";
import useSoundEffect, { SoundEffectProps } from "../../../hooks/effects/useSoundEffect";

interface ITouchButtonProps extends ButtonProps, SoundEffectProps /*, LongPressEffectProps*/ {
	fillBgWhenDisabled?: boolean;
}

const TouchButton = ({ fillBgWhenDisabled, muteSound, tone, /*hasLongPress,*/ onClick, ...rest }: ITouchButtonProps) => {
	const { playSound } = useSoundEffect({ muteSound, tone });
	// const { pressEvent, pressRelease } = useLongPressEffect({ hasLongPress: hasLongPress, longPressClickCallback: onClick });

	const StyledMuiButton = styled(Button)<ButtonProps>(({ theme, color, variant, size }) => {
		const needDecoration: boolean = !(variant === "text" || variant === undefined);

		const paletteColorName: "primary" | "secondary" | "success" | "error" | "info" | "warning" =
			color === undefined || color === "inherit" ? "primary" : color;
		const paletteColor: PaletteColor = theme.palette[`${paletteColorName}`];

		const sizeOffset: number = size === "large" ? 2 : size === "medium" || size === undefined ? 1 : 0;
		const initialPaddingX: number = variant === "text" || variant === undefined ? 0.3125 : variant === "contained" ? 0.5625 : 0.625;
		const paddingStepX: number = variant === "text" || variant === undefined ? 0.1875 : 0.375;
		const initialPaddingY: number = variant === "outlined" ? 0.1875 : 0.25;
		const paddingStepY: number = 0.125;

		return {
			minWidth: "4rem",
			borderRadius: "0.625rem",
			padding: `${initialPaddingY + paddingStepY * sizeOffset}rem ${initialPaddingX + paddingStepX * sizeOffset}rem`,
			"& svg": {
				fontSize: "1.25rem !important"
			},
			"&:hover, &:focus, &.Mui-focusVisible": needDecoration
				? {
						backgroundColor: `${color === "inherit" ? "inherit" : paletteColor.main} !important`
					}
				: null,
			"&.Mui-disabled":
				fillBgWhenDisabled && needDecoration
					? {
							backgroundColor: `${color === "inherit" ? "inherit" : paletteColor.dark}`
						}
					: null
		};
	});

	return (
		<StyledMuiButton
			disableRipple
			disableFocusRipple
			onClickCapture={playSound}
			// onTouchStart={pressEvent}
			// onTouchEnd={pressRelease}
			onClick={onClick}
			{...rest}
		/>
	);
};

export default memo(TouchButton);
