const base = (process.env.REACT_APP_4DELIVERY_BASE_URL ?? "") + "/api/v1/kiosk";

/**
 * 4Delivery Service - Kiosk endpoint's
 */
export const Service4DeliveryEndpoints = {
	authLogin: base + "/login-test",
	configGet: base + "/restaurant-info-get",
	configSet: base + "/restaurant-info-set",
	menuGet: base + "/menu",
	orderNoPayment: base + "/order/without-payment", // orderUid as idempotence key
	orderDocument: base + "/order/document",
	satispayRequest: base + "/satispay/request", // orderUid as idempotence key
	satispayUpdate: base + "/satispay/update-status",
	satispayCancel: base + "/satispay/cancel",
	satispayTest: base + "/satispay/test",
	stripeRequest: base + "/payment/request", // orderUid as idempotence key
	stripeIntentRequest: base + "/payment/intent-request",
	stripeUpdate: base + "/payment/update-status",
	stripeCancel: base + "/payment/cancel",
	stripeTest: base + "/payment/test"
};

export * from "./Auth/Login";
export * from "./Config/useRestaurantInfo";
export * from "./Menu/useMenu";
export * from "./OrderPayments/ElectronicPayment";
export * from "./OrderPayments/Order";
export * from "./OrderPayments/Payment";
