import { getContrastRatio } from "@mui/material";
import { CustomPalette } from "../types";

export const customPalette: CustomPalette = {
	labwareRed: "#D40F14",
	satispay: "#ef3338",
	white: "#ffffff",
	lightBlack: "rgba(0, 0, 0, 0.87)"
};

const darkLimitRatio: number = 3; // 4.5 for hightContrast

// a color is considered dark when is too far from white (ratio: 3)
export function isDarkColor(color: string): boolean {
	return getContrastRatio(color, customPalette.white) > darkLimitRatio;
}

export function areColorsContrasted(color1: string, color2: string): boolean {
	return getContrastRatio(color1, color2) > darkLimitRatio;
}
