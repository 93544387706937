import { useState } from "react";
import { Service4DeliveryEndpoints } from "..";
import { LogChannel } from "../../../constants";
import { defaultKioskMenu } from "../../../constants/defaults";
import { callMenuResponse, KioskMenu, useMenuResponse } from "../../../types";
import { loadProductImages } from "../../../utils/cdnAssets";
import { callAxios, callAxiosResponseInterface } from "../../../utils/httpClient/AxiosWrapper";
import { menuAdapter } from "../../../utils/menuAdapter";
import { useLogger } from "../../../utils/useLogger";

export const useMenu = (): useMenuResponse => {
	const { log, warn } = useLogger();

	const [menuLoading, setMenuLoading] = useState<boolean>(false);
	const [menuError, setMenuError] = useState<boolean>(false);
	const [menuNotified, setMenuNotified] = useState<boolean>(true);

	const downloadMenu = async (isForced: boolean = false) => {
		if (isForced === true) {
			localStorage.setItem("menuHash", "forced");
		}

		setMenuLoading(true);

		await MenuLoad()
			.then((result: boolean) => {
				setMenuError(!result);
			})
			.catch(() => {
				setMenuError(true);
			})
			.finally(() => {
				setMenuNotified(false);
				setMenuLoading(false);
			});
	};

	const MenuLoad = async (): Promise<boolean> => {
		const menuHash = localStorage.getItem("menuHash") ?? "";
		const restaurantId = localStorage.getItem("username") ?? "";

		return new Promise((resolve) => {
			callAxios({
				method: "post",
				url: Service4DeliveryEndpoints.menuGet,
				body: JSON.stringify({ hash: menuHash }),
				headers: JSON.stringify({})
			})
				.then((response: callAxiosResponseInterface) => {
					const goodResponse = response as callMenuResponse;
					if (menuHash !== goodResponse.data.hash) {
						const adaptedMenu: KioskMenu = new menuAdapter(goodResponse.data.menu).adapt();
						localStorage.setItem("menu", JSON.stringify(adaptedMenu));
						localStorage.setItem("menuHash", goodResponse.data.hash);

						loadProductImages(adaptedMenu.images ?? [], restaurantId).catch((error) => {
							// log(error);
						});
						log("Menu downloaded", LogChannel.cloudSync);
					}
					resolve(true);
				})
				.catch((err) => {
					warn(["Menu - get error: ", err], LogChannel.cloudSync);
					resolve(false);
				});
		});
	};

	return { downloadMenu, setMenuNotified, menuLoading, menuError, menuNotified };
};

export const GetMenuFromStorage = (): KioskMenu => {
	return JSON.parse(localStorage.getItem("menu") ?? JSON.stringify(defaultKioskMenu));
};
