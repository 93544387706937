import { Service4DeliveryEndpoints } from "..";
import { callLoginResponse } from "../../../types";
import { callAxios, callAxiosResponseInterface } from "../../../utils/httpClient/AxiosWrapper";

export const callLogin = (): Promise<callLoginResponse | callAxiosResponseInterface> => {
	return new Promise((resolve, reject) => {
		callAxios({
			method: "post",
			url: Service4DeliveryEndpoints.authLogin,
			body: JSON.stringify({}),
			headers: JSON.stringify({ accept: "*/*" })
		})
			.then((response: unknown) => {
				resolve(response as callLoginResponse);
			})
			.catch((err) => {
				if (err.data.error_code.startsWith("error.")) {
					reject({ ...err, data: { error_code: err.data.error_code.replace("error.", "") } });
				} else {
					reject(err);
				}
			});
	});
};
