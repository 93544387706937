import { Box } from "@mui/material";
import { memo, RefObject, useCallback, useRef, useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { useOnClickOutside } from "usehooks-ts";

interface IColorPickerProps {
	color: string;
	onChange: (color: string) => void;
	isOpen: boolean;
	close: () => void;
}

const ColorPicker = ({ color, onChange, isOpen, close }: IColorPickerProps) => {
	const [value, setValue] = useState<string>(color ?? "ffffff");
	const pickersPopover: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
	const closePopover = useCallback(() => {
		onChange(value);
		close();
	}, [close, value, onChange]);

	useOnClickOutside(pickersPopover, closePopover);

	return (
		<>
			{isOpen ? (
				<Box className="popoverWrapper">
					<Box className="popover" ref={pickersPopover} sx={{ backgroundColor: value }}>
						<HexColorPicker color={value} onChange={setValue} />
						<HexColorInput
							color={value}
							onChange={(newColor) => {
								if (newColor.length === 7) setValue(newColor);
							}}
							className="hexInput"
						/>
					</Box>
				</Box>
			) : null}
		</>
	);
};

export default memo(ColorPicker);
