export enum CheckoutFlowStep {
	orderMaking = "00_make",
	orderReview = "10_review",
	checkoutMethod = "20_method",
	placeNumber = "21_placeNum",
	paymentMethod = "30_payment",
	paymentInfo = "31_paymentInfo",
	// receiptMethod = "40_receiptMethod",
	orderConfirmation = "60_confirm",
	orderCancel = "90_cancel"
}
