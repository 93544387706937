import { Theme, createTheme, darken, lighten } from "@mui/material";
import { AppConfig, AppTheme, LayoutConfig } from "../../types";
import { customPalette, isDarkColor } from "../../utils/color";

declare module "@mui/material/styles" {
	interface Palette {
		labwareRed: Palette["primary"];
		satispay: Palette["primary"];
	}

	interface PaletteOptions {
		labwareRed?: PaletteOptions["primary"];
		satispay?: PaletteOptions["primary"];
	}
}

export function buildTheme(config: AppConfig): AppTheme {
	function createFromConfig(subConfig: LayoutConfig): Theme {
		return createTheme({
			palette: {
				// activate Dark Mode when the chosen background is a dark color
				mode: isDarkColor(subConfig.color.background) ? "dark" : "light",
				primary: {
					main: subConfig.color.primary
				},
				secondary: {
					main: subConfig.color.secondary
				},
				background: {
					default: subConfig.color.background,
					paper: isDarkColor(subConfig.color.background) ? darken(subConfig.color.background, 0.15) : lighten(subConfig.color.background, 0.15)
				},
				info: {
					main: isDarkColor(subConfig.color.background) ? customPalette.white : customPalette.lightBlack
				},
				success: {
					main: subConfig.color.secondary
				},
				error: {
					main: subConfig.color.primary
				},
				labwareRed: {
					main: customPalette.labwareRed
				},
				satispay: {
					main: customPalette.satispay
				}
			},
			typography: {
				fontFamily: subConfig.font.fontBody,
				fontSize: 20,
				h1: {
					fontFamily: subConfig.font.fontTitle,
					fontSize: "5.5rem",
					fontWeight: "bold"
				},
				h2: {
					fontFamily: subConfig.font.fontTitle,
					fontSize: "4.5rem",
					fontWeight: "bold"
				},
				h3: {
					fontFamily: subConfig.font.fontTitle,
					fontSize: "3.5rem",
					fontWeight: "bold"
				},
				h4: {
					fontFamily: subConfig.font.fontTitle,
					fontSize: "2.5rem",
					fontWeight: "bold"
				},
				h5: {
					fontFamily: subConfig.font.fontTitle,
					fontSize: "1.75rem",
					fontWeight: "bold"
				},
				h6: {
					fontFamily: subConfig.font.fontTitle,
					fontSize: "1.25rem",
					fontWeight: "bold"
				},
				subtitle1: {
					fontFamily: subConfig.font.fontTitle,
					fontSize: "1rem",
					lineHeight: 1
				},
				subtitle2: {
					fontFamily: subConfig.font.fontTitle
				},
				button: {
					fontFamily: subConfig.font.fontTitle,
					fontWeight: 700,
					lineHeight: 2.4,
					textTransform: "none"
				},
				caption: {
					fontFamily: subConfig.font.fontTitle,
					fontWeight: "bold"
				}
			},
			shape: {
				borderRadius: 10
			}
			// remove all MUI transitions and animations
			// transitions: {
			//   create: () => 'none',
			// },
			// components: {
			// 	MuiCssBaseline: {
			// 		styleOverrides: {
			// 		'*, *::before, *::after': {
			// 			transition: 'none !important',
			// 			//animation: 'none !important',
			// 		},
			// 		},
			// 	},
			// },
		});
	}

	return {
		config: config,
		customTheme: createFromConfig(config.themes.custom),
		systemTheme: createFromConfig(config.themes.system)
	};
}
