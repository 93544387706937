import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LogChannel, PaymentRequestStatus } from "../../../../constants";
import { ElectronicPayment } from "../../../../services/4Delivery";
import { Order, PaymentErrorResponse, PaymentInfo, PaymentIntentResponse, PaymentStatusResponse } from "../../../../types";
import { OrderRequestManager } from "../../../../utils/OrderRequestManager";
import { useLogger } from "../../../../utils/useLogger";
import { useMessage } from "../../../MessageHandler/MessageService";

interface CheckIntentProps {
	paymentIntent: PaymentIntentResponse;
	order: Order;
	electronicPayment: ElectronicPayment;
	isStripePaymentRN: boolean;
	setRequestStatus: Dispatch<SetStateAction<PaymentRequestStatus>>;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	confirmPayment: (paymentInfo: PaymentInfo, kioskCartId: number | null) => void;
}
const CheckIntent = ({ paymentIntent, order, electronicPayment, isStripePaymentRN, setRequestStatus, setIsLoading, confirmPayment }: CheckIntentProps) => {
	const { t } = useTranslation();
	const message = useMessage();
	const { log, warn } = useLogger();
	const orderRequestManager = new OrderRequestManager(order);

	const updateStatusInterval: number = 5; // 5s
	const updateStatusMax: number = 60; // 60 * 5s = 5 minutes (Limit polling attempts)
	const updateStatusCounter = useRef<number>(0);
	const [attemptResponseCounter, setAttemptResponseCounter] = useState<number>();

	useEffect(() => {
		const interval = setInterval(() => {
			checkPayment();
		}, updateStatusInterval * 1000);

		return () => {
			clearInterval(interval);
		};
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [attemptResponseCounter]);

	const handleError = (errorCode: string) => {
		/* inhibit any further attempts of polling */
		setIsLoading(false);
		updateStatusCounter.current = updateStatusMax + 1;

		warn(`CheckIntent error: ${errorCode}`, LogChannel.payment);

		message({
			title: t(`system.error.${errorCode}`).toUpperCase(),
			description: t("common.retryQuestion"),
			okCallback: () => {
				setRequestStatus(isStripePaymentRN ? PaymentRequestStatus.creatingIntent : PaymentRequestStatus.creatingIntentAndActivateReader);
			},
			okLabel: t("common.yes").toUpperCase(),
			cancelCallback: () => {
				setRequestStatus(PaymentRequestStatus.cancelingIntent);
			},
			cancelLabel: t("common.cancel").toUpperCase()
		});
	};

	const checkPayment = async () => {
		if (!paymentIntent.kiosk_cart_id) return;
		if (attemptResponseCounter !== updateStatusCounter.current) return;
		updateStatusCounter.current++;

		// Reached the limit of polling attempts to capture the updated status: forced the cancel action.
		if (updateStatusCounter.current > updateStatusMax) {
			log(`CheckIntent - Reached the limit of ${updateStatusMax} polling attempts`, LogChannel.payment);

			setIsLoading(false);
			setRequestStatus(PaymentRequestStatus.cancelingIntent);
		} else {
			await electronicPayment
				.checkStatusPayment(paymentIntent.kiosk_cart_id)
				.then((response: PaymentStatusResponse) => {
					switch (response.status) {
						case "success":
							setIsLoading(false);
							confirmPayment(response?.payment, paymentIntent.kiosk_cart_id);
							break;

						case "waiting":
							setAttemptResponseCounter(updateStatusCounter.current);
							break;

						case "error":
						case "canceled":
							handleError(response?.last_error);
							break;
						default:
							handleError("ERR_BAD_RESPONSE");
					}

					log(`CheckIntent ${updateStatusCounter.current}: ${response.status}`, LogChannel.payment);
				})
				.catch((err: PaymentErrorResponse) => {
					orderRequestManager.revertLastOrderRequestId();
					handleError(err.error_code);
				});
		}
	};

	useEffect(() => {
		setIsLoading(true);
		setAttemptResponseCounter(0);
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	return null;
};

export default CheckIntent;
