import { Box } from "@mui/material";
import { memo, MouseEvent as ReactMouseEvent, useCallback, useMemo } from "react";
import { Language, LogChannel } from "../../constants";
import { defaultConfig } from "../../constants/defaults";
import { useLanguage } from "../../hooks/useLanguage";
import { useLogger } from "../../utils/useLogger";
import { useTheme } from "../Theme/ThemeWrapper";
import LanguageFlag from "./LanguageFlag";
import "./LanguageFlagStrip.scss";

export interface LanguageFlagStripProps {
	className?: string;
	isCondensed?: boolean;
	onClick?: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const LanguageFlagStrip = ({ className, isCondensed, onClick }: LanguageFlagStripProps) => {
	const { language, setLanguage } = useLanguage();
	const { settings } = useTheme();
	const { log } = useLogger();
	const availableLanguages: Language[] = useMemo(() => settings.availableLanguages ?? defaultConfig.settings.availableLanguages, [settings]);

	const changeLanguage = useCallback(
		(e: ReactMouseEvent<HTMLDivElement, MouseEvent>, selectedLanguage: Language) => {
			e.stopPropagation();
			if (!(isCondensed ?? false)) {
				log(selectedLanguage, LogChannel.language);
				setLanguage(selectedLanguage);
			}

			if (onClick) onClick(e);
		},
		[isCondensed, onClick, setLanguage, log]
	);

	// Do not show language switch when only one language is available
	return availableLanguages.length > 1 ? (
		<Box className={(className ?? "") + " language-flag-strip" + ((isCondensed ?? false) ? " condensed" : "")}>
			{
				// Loop through System languages; filter by available languages in current settings; render related icons.
				Object.values(Language)
					.filter((filteredLang: Language) => availableLanguages.some((availableLang: Language) => availableLang === filteredLang))
					.map((lang: Language) => {
						const handleChangeLanguage = (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => changeLanguage(e, lang);

						return <LanguageFlag key={lang} language={lang} selected={language === lang} onClick={handleChangeLanguage} />;
					})
			}
		</Box>
	) : null;
};

export default memo(LanguageFlagStrip);
