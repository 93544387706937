import { Save, Undo } from "@mui/icons-material";
import { CircularProgress, Divider, TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent, memo, useCallback, useState } from "react";
import TouchIcon from "../Buttons/TouchIcon";

type TInputSaveProps = {
	label: string;
	value: string | number;
	onSave: (value: string | number) => void;
	isSaving?: boolean;
} & TextFieldProps;

const InputSave = ({ label, value, onSave, isSaving, InputProps, ...rest }: TInputSaveProps) => {
	const [localValue, setLocalValue] = useState<string | number>(value);
	const [valid, setValid] = useState<boolean>(false);

	const handleValidation = useCallback(
		(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
			const newValue: string | number = e.target.value;

			setValid(false);

			// check maxLength if exist
			if (InputProps?.inputProps?.maxLength !== undefined) {
				if (newValue.length > InputProps?.inputProps?.maxLength) return;
			}
			// check min and max if exist
			if (InputProps?.inputProps?.max !== undefined && InputProps?.inputProps?.min !== undefined) {
				const number: number = Number(newValue);
				if (number > InputProps.inputProps.max || number < InputProps.inputProps.min) return;
			}

			// set Valid by length
			if (InputProps?.inputProps?.maxLength !== undefined && InputProps?.inputProps?.minLength !== undefined) {
				if (newValue.length <= InputProps?.inputProps?.maxLength && newValue.length >= InputProps?.inputProps?.minLength) {
					setValid(true);
				}
			} else {
				setValid(true);
			}

			setLocalValue(newValue);
		},
		[InputProps]
	);

	const updateValue = useCallback(() => onSave(localValue), [localValue, onSave]);

	const resetValue = useCallback(() => setLocalValue(value), [value]);

	return (
		<TextField
			label={label}
			value={localValue}
			onChange={handleValidation}
			InputProps={{
				...InputProps,
				endAdornment: (
					<>
						<TouchIcon onClick={updateValue} color={rest.color} disabled={isSaving || !valid}>
							{isSaving ? (
								<CircularProgress
									color="info"
									sx={{
										width: "1.5rem !important",
										height: "1.5rem !important"
									}}
								/>
							) : (
								<Save />
							)}
						</TouchIcon>

						<Divider sx={{ height: "1.75rem", m: "0.25rem" }} orientation="vertical" />

						<TouchIcon onClick={resetValue} color={rest.color} disabled={value === localValue}>
							<Undo />
						</TouchIcon>
					</>
				)
			}}
			{...rest}
		/>
	);
};

export default memo(InputSave);
