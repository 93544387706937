import { CloudDone, CloudDownload, CloudOff, Info } from "@mui/icons-material";
import { Alert, Box, Button, CircularProgress, Collapse, Grid, Paper, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import ReactPlayer from "react-player/file";
import useNetworkStatus from "../../hooks/useNetworkStatus";
import { useMenu } from "../../services/4Delivery";
import { useGetRestaurantMedia, UseGetRestaurantMediaResponse } from "../../utils/cdnAssets";
import AdminDivider from "../Layout/Divider/AdminDivider";
import { useMessage } from "../MessageHandler/MessageService";
import { useTheme } from "../Theme/ThemeWrapper";

interface AdminCloudProps {
	isAdmin: boolean;
	disclaimer: string;
	disclaimerNotFound: boolean;
}

interface AssetType {
	[key: string]: UseGetRestaurantMediaResponse;
}

const AdminCloud = ({ isAdmin, disclaimer, disclaimerNotFound }: AdminCloudProps) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const message = useMessage();
	const { downloadMenu, setMenuNotified, menuLoading, menuError, menuNotified } = useMenu();
	const { isOnline } = useNetworkStatus();

	const assets: AssetType = {
		standby: useGetRestaurantMedia("standby"),
		graphic: useGetRestaurantMedia("graphic"),
		plain: useGetRestaurantMedia("plain"),
		logo: useGetRestaurantMedia("logo")
	};

	const handleDownloadMenu = () => downloadMenu(true);
	const handleMenuNotified = () => setMenuNotified(true);
	const handleShowMarkdown = () => {
		message({
			title: t("checkout.editableItemDialog.allergensTitle").toUpperCase(),
			description: (
				<Box>
					<Markdown>{disclaimer}</Markdown>
				</Box>
			),
			okCallback: () => {},
			okLabel: t("common.ok").toUpperCase()
		});
	};

	const [videoHasError, setVideoHasError] = useState<boolean>(false);
	const handleVideoPlayerError = useCallback(() => setVideoHasError(true), []);

	return (
		<Paper sx={{ p: "1.5rem" }} elevation={0} className="cloudTab">
			<AdminDivider label={t("system.admin.cloud.menu")} />
			<Typography sx={{ pb: 2 }}>{t("system.admin.cloud.menuDesc")}</Typography>

			<Button
				variant="contained"
				color="success"
				onClick={handleDownloadMenu}
				sx={{ mr: "1rem" }}
				endIcon={menuLoading ? <CircularProgress sx={{ marginLeft: "2rem" }} /> : <CloudDownload />}
				disabled={menuLoading}
			>
				{t("common.download").toUpperCase()}
			</Button>
			<Collapse in={menuNotified === false}>
				<Alert severity={menuError ? "error" : "success"} onClose={handleMenuNotified}>
					{menuError ? t("system.error.sync") : t("common.ok")}
				</Alert>
			</Collapse>

			<AdminDivider label={t("system.admin.cloud.network")} />
			{isOnline ? <CloudDone color="success" fontSize="large" /> : <CloudOff color="error" fontSize="large" />}

			{isAdmin ? (
				<>
					<AdminDivider label={t("system.admin.cloud.assets")} />
					<Typography sx={{ pb: "1rem" }}>{t("system.admin.cloud.assetsDesc")}</Typography>

					<Box>
						<Typography variant="h6" sx={{ pb: "1rem" }}>
							{t("system.admin.cloud.assetsType.logo")}
						</Typography>
						{assets.logo.isFallback ? <Typography variant="body2">{t("common.notSet")}</Typography> : null}
						<img className="logoImage" src={assets.logo.url} width="200" alt="" loading="lazy" />
					</Box>

					<Grid container spacing={0}>
						{["standby", "graphic", "plain"].map((value: string, index: number) => (
							<Grid key={"asset_" + index} xs={4} item={true}>
								<Typography variant="h6" sx={{ pt: "2rem", pb: "1rem" }}>
									{t(`system.admin.cloud.assetsType.${value}`)}
								</Typography>
								{assets[value].isFallback ? <Typography variant="body2">{t("common.notSet")}</Typography> : null}
								<Box className="bgImages" sx={{ backgroundColor: theme.customTheme.palette.primary.main }}>
									{assets[value].isVideo && !videoHasError ? (
										<ReactPlayer
											url={assets[value].url}
											muted={true}
											loop={true}
											playing={true}
											volume={0}
											width={"100%"}
											height={"100%"}
											onError={handleVideoPlayerError}
											fallback={<Box width={"100%"} height={"100%"} position={"absolute"} top={0} />}
											config={{
												attributes: { poster: undefined, preload: "none" }
											}}
										/>
									) : (
										<img src={assets[value].url} width="200" alt="" loading="lazy" />
									)}
								</Box>
							</Grid>
						))}
					</Grid>

					<Box sx={{ pt: "2rem" }}>
						<Typography variant="h6" sx={{ pb: "1rem" }}>
							{t("checkout.editableItemDialog.allergensTitle")}
						</Typography>

						{disclaimerNotFound ? <Typography variant="body2">{t("common.notSet")}</Typography> : null}
						<Button variant="contained" color="success" onClick={handleShowMarkdown} endIcon={<Info />} disabled={disclaimerNotFound}>
							{t("common.open").toUpperCase()}
						</Button>
					</Box>
				</>
			) : null}
		</Paper>
	);
};

export default AdminCloud;
