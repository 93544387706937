import { Table, TableBody, TableContainer, TableContainerProps } from "@mui/material";
import { memo } from "react";

const TableWrapper = ({ children, ...rest }: TableContainerProps) => {
	return (
		<TableContainer className="orderTable" {...rest}>
			<Table aria-label="simple-table" sx={{ border: 0 }}>
				<TableBody
					sx={{
						"& td": {
							padding: "1rem !important"
						}
					}}
				>
					{children}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default memo(TableWrapper);
