import { Edit } from "@mui/icons-material";
import { Box, Divider, Stepper, Typography } from "@mui/material";
import { memo, useMemo, useState } from "react";
import { CartItem, ItemLinkedList } from "../../../../types";
import i18n from "../../../../utils/i18n/i18n";
import { tItemInfo } from "../../../../utils/i18nMenu";
import { toTitleCase } from "../../../../utils/Strings";
import TouchStepButton from "../../../Layout/Buttons/TouchStepButton";
import StepSetMenu from "../../../Layout/Stepper/StepSetMenu";
import VariationItemLinkedList from "../Variations/VariationItemLinkedList";

interface SetMenuProps {
	lists: ItemLinkedList[];
	currentCartItem: CartItem;
	setCurrentCartItem: (cartItem: CartItem) => void;
	oldOptions: CartItem[];
	linkedListWithIssue: number[];
	setLinkedListWithIssue: (linkedListWithIssue: number[]) => void;
	renderAddButtons: JSX.Element | null;
}

interface Checklist {
	[k: number]: string;
}

const SetMenu = ({ lists, currentCartItem, setCurrentCartItem, oldOptions, linkedListWithIssue, setLinkedListWithIssue, renderAddButtons }: SetMenuProps) => {
	const initialChecklist: Checklist = useMemo(() => {
		const checklist: Checklist = {};

		lists.forEach((item: ItemLinkedList, index: number) => {
			const existingOption: CartItem | undefined = oldOptions.find((ci: CartItem) => ci.uid.split("_")[1] === item.tempId);
			if (existingOption) {
				checklist[index] = toTitleCase(tItemInfo(i18n.language, existingOption.item.itemInfo, "shortText"));
			}
		});

		return checklist;
	}, [lists, oldOptions]);

	const [activeStep, setActiveStep] = useState<number>(0);
	const [completed, setCompleted] = useState<Checklist>(initialChecklist);

	const isEditing: boolean = activeStep < lists.length;
	const allStepsCompleted = () => Object.keys(completed).length === lists.length;

	const handleNext = () => {
		let newActiveStep: number;
		if (allStepsCompleted()) {
			newActiveStep = lists.length;
		} else {
			// Not all steps have been completed: find the first step that has been completed
			newActiveStep = lists.findIndex((step, i) => !(i in completed));
		}
		setActiveStep(newActiveStep);
	};

	const handleComplete = (optionText: string) => {
		const newCompleted = completed;
		newCompleted[activeStep] = optionText;
		setCompleted(newCompleted);
		handleNext();
	};

	const handleCurrentCartItem = (cartItem: CartItem) => {
		setCompleted({});
		cartItem.options.forEach((option: CartItem) => {
			const text: string = toTitleCase(tItemInfo(i18n.language, option.item.itemInfo, "shortText"));
			handleComplete(text);
		});
		setCurrentCartItem(cartItem);
	};

	return (
		<Box>
			<Box sx={{ width: "100%", mt: "2rem" }}>
				<Stepper
					nonLinear
					activeStep={activeStep}
					alternativeLabel
					sx={{
						flexWrap: "wrap",
						display: lists.length > 2 ? "-webkit-box" : "flex"
					}}
				>
					{lists.map((itemLinkedList: ItemLinkedList, index: number) => {
						const stepChoice: string = completed[index];
						const isStepCompleted: boolean = stepChoice !== undefined;
						const isStepActive: boolean = index === activeStep;
						const isUpdating: boolean = isStepActive && isStepCompleted;
						const clickStep = () => setActiveStep(index);
						return (
							<StepSetMenu key={`step_${itemLinkedList.tempId}`} completed={isStepCompleted} isActive={isStepActive} isUpdating={isUpdating}>
								<TouchStepButton color="inherit" onClick={clickStep}>
									{itemLinkedList.listTitle}
									{stepChoice ? (
										<>
											<Divider textAlign="left" sx={{ my: 2 }} />
											<Typography fontWeight="bold">{stepChoice}</Typography>
											<Edit
												sx={{
													position: "absolute",
													bottom: "0.5rem",
													right: "0.5rem",
													opacity: isUpdating ? "1" : "0.25",
													height: isUpdating ? "2.5rem" : "2rem",
													width: isUpdating ? "2.5rem" : "2rem"
												}}
											/>
										</>
									) : null}
								</TouchStepButton>
							</StepSetMenu>
						);
					})}
				</Stepper>
			</Box>

			{lists?.map((itemLinkedList: ItemLinkedList, index: number) => (
				<VariationItemLinkedList
					key={`content_${itemLinkedList.tempId}`}
					currentCartItem={currentCartItem}
					setCurrentCartItem={handleCurrentCartItem}
					itemLinkedList={itemLinkedList}
					oldOptions={oldOptions}
					linkedListWithIssue={linkedListWithIssue}
					setLinkedListWithIssue={setLinkedListWithIssue}
					activeStep={activeStep === index}
					isSetMenu={true}
				/>
			))}

			<Box sx={{ my: "4rem" }}>{allStepsCompleted() && !isEditing ? renderAddButtons : null}</Box>
		</Box>
	);
};

export default memo(SetMenu);
