import { ListItem, ListItemText, Typography } from "@mui/material";
import { memo, MouseEvent as ReactMouseEvent, useCallback, useMemo } from "react";
import { CategoryChild } from "../../../types";
import i18n from "../../../utils/i18n/i18n";
import { tCategoryChild } from "../../../utils/i18nMenu";
import TouchListItemButton from "../../Layout/Buttons/TouchListItemButton";
import { useTheme } from "../../Theme/ThemeWrapper";
import "./Sidebar.scss";

interface SidebarItemChildProps {
	categoryChild: CategoryChild;
	changeSelectedCategoryChild: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>, categoryCode: string) => void;
	isSelectedCategoryChildCode: boolean;
}

const SidebarItemChild = ({ categoryChild, changeSelectedCategoryChild, isSelectedCategoryChildCode }: SidebarItemChildProps) => {
	const { theme, hideSnoozed } = useTheme();

	const categoryChildLabel = useMemo(() => tCategoryChild(i18n.language, categoryChild), [categoryChild]);

	const color = useMemo(
		() => (isSelectedCategoryChildCode ? theme.customTheme.palette.primary.main : "inherit"),
		[isSelectedCategoryChildCode, theme.customTheme.palette.primary.main]
	);

	const handleChangeSelectedCategory = useCallback(
		(e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => changeSelectedCategoryChild(e, categoryChild.categoryCode),
		[categoryChild.categoryCode, changeSelectedCategoryChild]
	);

	return (
		<ListItem sx={hideSnoozed && categoryChild.itemSelectableAllSnoozed ? { display: "none" } : null}>
			<TouchListItemButton onClick={handleChangeSelectedCategory}>
				<ListItemText
					data-to-scrollspy-id={"category-" + categoryChild.categoryCode}
					sx={{ color: color }}
					primary={<Typography variant="subtitle2">{categoryChildLabel}</Typography>}
				/>
			</TouchListItemButton>
		</ListItem>
	);
};

export default memo(SidebarItemChild);
