import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LogChannel, PaymentRequestStatus } from "../../../../constants";
import { useRT } from "../../../../services/Rt/useRT";
import { PrintToCashSystemResponse } from "../../../../types";
import { useLogger } from "../../../../utils/useLogger";
import { useMessage } from "../../../MessageHandler/MessageService";

interface CheckCashSystemProps {
	cashSystemIP: string;
	isStripePaymentRN: boolean;
	setRequestStatus: Dispatch<SetStateAction<PaymentRequestStatus>>;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	cancelOrder: () => void;
}
const CheckCashSystem = ({ cashSystemIP, isStripePaymentRN, setRequestStatus, setIsLoading, cancelOrder }: CheckCashSystemProps) => {
	const { t } = useTranslation();
	const message = useMessage();
	const { warn } = useLogger();
	const { cashSystemCheckStatus } = useRT();

	const check = useCallback(async () => {
		const handleError = () => {
			warn("CheckCashSystem error", LogChannel.payment);
			message({
				title: "",
				description: t("system.error.cashSystem.statusError"),
				okCallback: () => {
					check();
				},
				okLabel: t("common.retry"),
				cancelCallback: () => cancelOrder(),
				cancelLabel: t("common.cancel") ?? ""
			});
		};

		setIsLoading(true);
		await cashSystemCheckStatus(cashSystemIP)
			.then((response: PrintToCashSystemResponse) => {
				if (
					response.status === 200 &&
					response.data.status.error === false &&
					response.data.status.inattivita === false &&
					response.data.status.key === 1
				) {
					setRequestStatus(isStripePaymentRN ? PaymentRequestStatus.creatingIntent : PaymentRequestStatus.creatingIntentAndActivateReader);
				} else {
					handleError();
				}
			})
			.catch(() => handleError())
			.finally(() => setIsLoading(false));
	}, [cashSystemIP, isStripePaymentRN, setRequestStatus, setIsLoading, message, cancelOrder, t, cashSystemCheckStatus, warn]);

	useEffect(() => {
		/* eslint-disable react-hooks/exhaustive-deps */
		check();
	}, []);

	return null;
};

export default CheckCashSystem;
