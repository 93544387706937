import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Stack,
	Typography
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getRevenueServiceName, isValidRevenueService, RevenueService, Rt } from "../../constants";
import usePrintingQueue from "../../hooks/usePrintingQueue";
import { useRT } from "../../services/Rt/useRT";
import { PrintToCashSystemResponse, SettingsConfig, Vat } from "../../types";
import { callAxiosResponseInterface } from "../../utils/httpClient/AxiosWrapper";
import InputSave from "../Layout/Form/InputSave";
import ToggleItem from "../Layout/Toggle/ToggleItem";
import { useMessage } from "../MessageHandler/MessageService";
import { useTheme } from "../Theme/ThemeWrapper";
import AdminSettingsCashVat from "./AdminSettingsCashVat";

interface AdminSettingsCashProps {
	isAdmin: boolean;
	currentSettings: SettingsConfig;
	setCurrentSettings: Dispatch<SetStateAction<SettingsConfig>>;
	menuVatList: Vat[];
}

const AdminSettingsCash = ({ isAdmin, currentSettings, setCurrentSettings, menuVatList }: AdminSettingsCashProps) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const message = useMessage();
	const { cashSystemCheckStatus, cashSystemClosure, cashSystemTest } = useRT();
	const { isCashSystemOutOfPaper, isCashSystemInError, resetPrintingQueue, processPrintingQueue, retrievePrintingQueue } = usePrintingQueue(
		currentSettings.cashSystemIP
	);

	const validRevenueService: boolean = useMemo(
		() => isValidRevenueService(currentSettings.cashSystemRevenueServiceType),
		[currentSettings.cashSystemRevenueServiceType]
	);

	const postCashSystemCheck = async (cashSystemIP: string, revenueService: RevenueService) => {
		await cashSystemCheckStatus(cashSystemIP).then((response: PrintToCashSystemResponse) => {
			if (response.status === 200) {
				if ((response.data.status.printerType ?? RevenueService.IT) !== revenueService) {
					message({
						title: "",
						description: t("system.admin.settings.warningRevenueService"),
						okCallback: () => {},
						okLabel: t("common.ok")
					});
				}
			} else {
				formatCashSystemResponseWithModal(response);
			}
		});
	};

	const handleCashSystemIPChange = async (value: string) => {
		setCurrentSettings((prev: SettingsConfig) => ({ ...prev, cashSystemIP: value }));
		await postCashSystemCheck(value, currentSettings.cashSystemRevenueServiceType);
	};

	const handleRevenueServiceChange = async (event: SelectChangeEvent<RevenueService>) => {
		const revenueService = event.target.value as RevenueService;
		const localValidRevenueService: boolean = isValidRevenueService(revenueService);

		setCurrentSettings((prev: SettingsConfig) => ({
			...prev,
			cashSystemRevenueServiceType: revenueService,
			isFiscalMode: localValidRevenueService ? prev.isFiscalMode : false,
			printDigitalReceipt: localValidRevenueService ? prev.printDigitalReceipt : false
		}));
		await postCashSystemCheck(currentSettings.cashSystemIP, revenueService);
	};

	const settingToggler = (key: keyof SettingsConfig) => {
		setCurrentSettings((prev: SettingsConfig) => ({ ...prev, [key]: !prev[key] }));
	};
	const toggleDigitalReceipt = () => settingToggler("printDigitalReceipt");
	const toggleVariationsSplit = () => settingToggler("printVariationsSplit");
	const toggleQRCode = () => settingToggler("printNonFiscalQRCode");
	const toggleSkipTallonRecap = () => settingToggler("skipTallonRecap");

	const handleFiscalModeChange = () => {
		setCurrentSettings((prev: SettingsConfig) => {
			let newIsFiscalMode: boolean = !prev.isFiscalMode;

			if (!validRevenueService) newIsFiscalMode = false;
			return {
				...prev,
				isFiscalMode: newIsFiscalMode,
				printDigitalReceipt: newIsFiscalMode === false ? false : prev.printDigitalReceipt,
				skipTallonRecap: newIsFiscalMode === true ? false : prev.skipTallonRecap
			};
		});
	};

	const handleReceiptTallonModeChange = () => {
		setCurrentSettings((prev: SettingsConfig) => {
			const newReceiptTallonMode: boolean = !prev.receiptTallonMode;

			return {
				...prev,
				receiptTallonMode: newReceiptTallonMode,
				skipTallonRecap: newReceiptTallonMode === false ? false : prev.skipTallonRecap
			};
		});
	};

	const handleCashSystemEnabledChange = () => {
		setCurrentSettings((prev: SettingsConfig) => {
			const newIsCashSystemEnabled: boolean = !prev.isCashSystemEnabled;

			return {
				...prev,
				isCashSystemEnabled: newIsCashSystemEnabled,
				isFiscalMode: newIsCashSystemEnabled === false ? false : prev.isFiscalMode,
				printDigitalReceipt: newIsCashSystemEnabled === false ? false : prev.printDigitalReceipt,
				skipTallonRecap: newIsCashSystemEnabled === true ? false : prev.skipTallonRecap
			};
		});
	};

	const postCashSystemTest = async () => {
		message({
			title: t("system.admin.settings.cashSystem"),
			description: t("system.admin.settings.alertWaitAction"),
			okCallback: () => {},
			okLabel: ""
		});

		await cashSystemTest(currentSettings.cashSystemIP).then((response: PrintToCashSystemResponse) => formatCashSystemResponseWithModal(response));
	};

	const postCashSystemClosure = async () => {
		message({
			title: "",
			description: t("system.admin.settings.dailyClosureMsg"),
			okCallback: async () => {
				setTimeout(async function () {
					message({
						title: t("system.admin.settings.cashSystem"),
						description: t("system.admin.settings.alertWaitAction"),
						okCallback: () => {},
						okLabel: ""
					});

					await cashSystemClosure(currentSettings.cashSystemIP).then((response: PrintToCashSystemResponse) =>
						formatCashSystemResponseWithModal(response)
					);
				}, 500);
			},
			okLabel: t("common.yes"),
			cancelCallback: () => {},
			cancelLabel: t("common.cancel") ?? ""
		});
	};

	const formatCashSystemResponseWithModal = (response: PrintToCashSystemResponse | callAxiosResponseInterface): void => {
		let isResponseOk: boolean = false;
		let genericErrorCode: string | undefined = undefined;
		let rtErrorCode: number | undefined = undefined;
		if (response.status === 200) {
			isResponseOk = !response.data.status.error === true;
			rtErrorCode = response.data.status.error ? response.data.status.errorCode : undefined;
		} else {
			genericErrorCode = response.data.error_code ?? undefined;
		}

		const formattedDescription: JSX.Element = (
			<Box>
				{isResponseOk
					? null
					: genericErrorCode
						? t(`system.error.${genericErrorCode}`)
						: (rtErrorCode as Rt.ErrorCode)
							? t(`system.error.cashSystem.native.${rtErrorCode}`)
							: null}
				<Box fontSize={"0.8rem"}>
					<Typography fontSize={"0.8rem"}>{t("common.log")}</Typography>
					<pre>{JSON.stringify(response.data, null, 4)}</pre>
				</Box>
			</Box>
		);

		message({
			title: t("system.admin.settings.cashSystem") + ": " + t(isResponseOk ? "common.ok" : "common.error"),
			description: formattedDescription,
			okCallback: () => {},
			okLabel: t("common.ok")
		});
	};

	const processQueueConfirm = (): void => {
		const queue = retrievePrintingQueue();
		if (queue.queue.length === 0) {
			message({
				title: t("system.admin.settings.cashSystem").toUpperCase(),
				description: t("system.admin.settings.isQueueEmpty"),
				okCallback: () => {},
				okLabel: t("common.ok").toUpperCase()
			});
		} else {
			message({
				title: t("system.admin.settings.cashSystem").toUpperCase(),
				description: t("system.admin.settings.processQueue", { item: queue.queue.length.toString() }),
				okLabel: t("common.print").toUpperCase(),
				okCallback: async () => {
					processPrintingQueue();
				},
				cancelLabel: t("common.cancel").toUpperCase() ?? "",
				cancelCallback: () => {},
				thirdActionLabel: t("system.admin.settings.actionEmptyQueue").toUpperCase() ?? "",
				thirdActionCallback: () => {
					resetPrintingQueue();
				}
			});
		}
	};

	useEffect(() => {
		if (isCashSystemOutOfPaper) {
			message({
				title: t("system.error.cashSystem.error").toUpperCase(),
				description: t("system.error.cashSystem.statusError") + ": " + t("system.error.cashSystem.out-of-paper"),
				okCallback: () => {},
				okLabel: t("common.ok").toUpperCase()
			});
		}
		if (isCashSystemInError) {
			message({
				title: t("system.error.cashSystem.error").toUpperCase(),
				description: t("system.error.cashSystem.statusError"),
				okCallback: () => {},
				okLabel: t("common.ok").toUpperCase()
			});
		}
	}, [isCashSystemOutOfPaper, isCashSystemInError, message, t]);

	const handleOpenVirtual = () => window.open("http://" + currentSettings.cashSystemIP, "_blank");

	const handleFiscalModeClick = () => {
		if (!validRevenueService) {
			message({
				title: t("common.warning"),
				description: t("system.admin.settings.enableReceiptWarning"),
				okCallback: () => {},
				okLabel: t("common.ok")
			});
		}
	};

	const handleDigitalReceipt = () => {
		if (!currentSettings.isCashSystemEnabled || !currentSettings.isFiscalMode) {
			message({
				title: t("common.warning"),
				description: t("system.admin.settings.enableDigitalReceiptWarning"),
				okCallback: () => {},
				okLabel: t("common.ok")
			});
		}
	};

	return (
		<Paper
			elevation={0}
			sx={{
				p: "2rem",
				backgroundColor: !currentSettings.isCashSystemEnabled ? theme.systemTheme.palette.grey.A200 : theme.systemTheme.palette.background.paper
			}}
		>
			{isAdmin ? (
				<>
					<FormGroup>
						<FormControlLabel
							control={
								<ToggleItem
									color="success"
									checked={(currentSettings.isCashSystemEnabled as boolean) ?? false}
									onChange={handleCashSystemEnabledChange}
								/>
							}
							label={
								(currentSettings.isCashSystemEnabled ? t("common.disable") : t("common.enable")) + " " + t("system.admin.settings.cashSystem")
							}
						/>
					</FormGroup>
					<hr />
				</>
			) : null}
			&nbsp;
			<Stack direction="row" justifyContent="space-between">
				<Stack direction="row" sx={{ width: "58%" }}>
					<InputSave
						color="secondary"
						label={t("system.admin.settings.cashSystemIP")}
						value={currentSettings.cashSystemIP}
						onSave={(value) => handleCashSystemIPChange(value.toString())}
						InputProps={{ inputProps: { maxLength: 15, minLength: 7 } }}
						disabled={!currentSettings.isCashSystemEnabled || !isAdmin}
						sx={{ width: "100%" }}
					/>
				</Stack>
				<FormControl fullWidth sx={{ width: "40%" }}>
					<InputLabel color="success">{t("system.admin.settings.cashSystemRevenueService")}</InputLabel>
					<Select
						color="success"
						value={currentSettings.cashSystemRevenueServiceType}
						label={t("system.admin.settings.cashSystemRevenueService")}
						onChange={handleRevenueServiceChange}
					>
						{Object.values(RevenueService).map((value: string) => (
							<MenuItem key={value} value={value}>
								<Typography>{value === RevenueService.none ? t("common.none") : getRevenueServiceName(value)}</Typography>
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Stack>
			<Stack direction="row" justifyContent="space-between" sx={{ mt: "0.75rem", "& > button": { lineHeight: 1.25, minHeight: "3.5rem" } }}>
				<Button
					color="success"
					disabled={!currentSettings.isCashSystemEnabled || currentSettings.cashSystemIP === ""}
					sx={{ width: "15%" }}
					variant="contained"
					onClick={postCashSystemTest}
					size="small"
				>
					{t("common.test").toUpperCase()}
				</Button>
				<Button
					color="success"
					disabled={!currentSettings.isCashSystemEnabled || currentSettings.cashSystemIP === "" || !isAdmin}
					sx={{ width: "15%" }}
					variant="contained"
					onClick={handleOpenVirtual}
					size="small"
				>
					{t("common.open").toUpperCase()}
				</Button>
				<Button
					color="success"
					disabled={
						!currentSettings.isCashSystemEnabled || currentSettings.cashSystemIP === "" || !validRevenueService || !currentSettings.isFiscalMode
					}
					sx={{ width: "34%" }}
					variant="contained"
					onClick={postCashSystemClosure}
					size="small"
				>
					{t("system.admin.settings.dailyClosureCashSystem").toUpperCase()}
				</Button>
				<Button
					color="success"
					disabled={!currentSettings.isCashSystemEnabled || currentSettings.cashSystemIP === ""}
					sx={{ width: "34%" }}
					variant="contained"
					onClick={processQueueConfirm}
					size="small"
				>
					{t("system.admin.settings.processQueueAction").toUpperCase()}
				</Button>
			</Stack>
			&nbsp;
			{isAdmin ? (
				<>
					<hr />
					<Stack direction="column">
						<Stack direction="row" alignItems="center" justifyContent="left" onClick={handleFiscalModeClick}>
							<Checkbox
								color="success"
								disabled={!currentSettings.isCashSystemEnabled || !validRevenueService}
								checked={(currentSettings.isFiscalMode as boolean) ?? false}
								onChange={handleFiscalModeChange}
								sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
							/>
							<Typography variant="body1">{t("system.admin.settings.receipt")}</Typography>
						</Stack>
						<Stack direction="row" alignItems="center" justifyContent="left" onClick={handleDigitalReceipt}>
							<Checkbox
								color="success"
								disabled={!currentSettings.isCashSystemEnabled || !currentSettings.isFiscalMode || !validRevenueService}
								checked={(currentSettings.printDigitalReceipt as boolean) ?? false}
								onChange={toggleDigitalReceipt}
								sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
							/>
							<Typography variant="body1">{t("system.admin.settings.digitalReceipt")}</Typography>
						</Stack>
					</Stack>
					<hr />
					<Stack direction="row" alignItems="center" justifyContent="start">
						<Stack direction="row" alignItems="center" justifyContent="left">
							<Checkbox
								color="success"
								disabled={!currentSettings.isCashSystemEnabled}
								checked={(currentSettings.printVariationsSplit as boolean) ?? false}
								onChange={toggleVariationsSplit}
								sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
							/>
							<Typography variant="body1">{t("system.admin.settings.printVariationsSplit")}</Typography>
						</Stack>
					</Stack>
					<hr />
					<Stack direction="row" alignItems="center" justifyContent="start">
						<Stack direction="row" alignItems="center" justifyContent="left">
							<Checkbox
								color="success"
								disabled={!currentSettings.isCashSystemEnabled}
								checked={(currentSettings.printNonFiscalQRCode as boolean) ?? false}
								onChange={toggleQRCode}
								sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
							/>
						</Stack>
						<Typography variant="body1">{t("system.admin.settings.printNotFiscalQRCode")}</Typography>
					</Stack>
					<hr />
					<Stack direction="row" alignItems="center" justifyContent="start">
						<Stack direction="row" alignItems="center" justifyContent="left">
							<Checkbox
								color="success"
								disabled={!currentSettings.isCashSystemEnabled}
								checked={(currentSettings.receiptTallonMode as boolean) ?? false}
								onChange={handleReceiptTallonModeChange}
								sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
							/>
							<Typography variant="body1">{t("system.admin.settings.receiptTallonMode")}</Typography>
						</Stack>
					</Stack>
					<Stack direction="row" alignItems="center" justifyContent="start">
						<Stack direction="row" alignItems="center" justifyContent="left">
							<Checkbox
								color="success"
								disabled={!currentSettings.isCashSystemEnabled || currentSettings.isFiscalMode || !currentSettings.receiptTallonMode}
								checked={(currentSettings.skipTallonRecap as boolean) ?? false}
								onChange={toggleSkipTallonRecap}
								sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
							/>
							<Typography variant="body1">{t("system.admin.settings.skipTallonRecap")}</Typography>
						</Stack>
					</Stack>
					<hr />
					&nbsp;
					<AdminSettingsCashVat currentSettings={currentSettings} setCurrentSettings={setCurrentSettings} menuVatList={menuVatList} />
				</>
			) : null}
		</Paper>
	);
};

export default AdminSettingsCash;
