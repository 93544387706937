import { Box, FormControlLabel, Radio } from "@mui/material";
import { memo, useMemo } from "react";
import { ItemInfoTypology } from "../../../../constants";
import { useCurrency } from "../../../../hooks/useCurrency";
import { CartItem } from "../../../../types";
import i18n from "../../../../utils/i18n/i18n";
import { tItemInfo } from "../../../../utils/i18nMenu";
import { useTheme } from "../../../Theme/ThemeWrapper";
import "./VariationItemSelectableSingle.scss";

interface VariationItemSelectableSingleProps {
	option: CartItem;
}

const VariationItemSelectableSingle = ({ option }: VariationItemSelectableSingleProps) => {
	const { theme, hideSnoozed } = useTheme();
	const { currency } = useCurrency();

	const unavailable: boolean = option.item.itemInfo.snoozed === "true";
	const sign: string = option.item.itemInfo.tipology === ItemInfoTypology.cond_sub ? "-" : "+";
	const label: string = useMemo(
		() => tItemInfo(i18n.language, option.item.itemInfo, "shortText") + (option.resultingPrice ? " (" + sign + currency(option.resultingPrice) + ")" : ""),
		[currency, option.item.itemInfo, option.resultingPrice, sign]
	);

	return (
		<Box
			className={`radioOption ${option.quantity === 1 ? "selected" : ""} `}
			sx={{
				display: hideSnoozed && unavailable ? "none" : "inherit",
				opacity: unavailable ? "0.75" : 1,
				borderColor: theme.systemTheme.palette.grey.A200,
				"&.selected": {
					borderColor: theme.systemTheme.palette.secondary.main
				}
			}}
		>
			<FormControlLabel value={option.uid} control={<Radio />} label={label} />
		</Box>
	);
};

export default memo(VariationItemSelectableSingle);
