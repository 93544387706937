import { Container, ThemeProvider } from "@mui/material";
import { memo, ReactNode } from "react";
import { useTheme } from "../../../Theme/ThemeWrapper";
import "../../OrderMaker.scss";

interface DialogFullProps {
	children?: ReactNode;
}

const DialogFull = ({ children }: DialogFullProps) => {
	const { theme } = useTheme();

	return (
		<ThemeProvider theme={theme.systemTheme}>
			<Container className="dialogContainer" sx={{ backgroundColor: theme.systemTheme.palette.background.paper, minHeight: "100vh" }}>
				{children}
			</Container>
		</ThemeProvider>
	);
};

export default memo(DialogFull);
