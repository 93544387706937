import { useMemo } from "react";
import { useTheme } from "../components/Theme/ThemeWrapper";
import { centsToValue } from "../utils/Numbers";

interface useCurrencyResponse {
	currency: (value: number) => string;
}

export const useCurrency = (): useCurrencyResponse => {
	const { restaurantInfo } = useTheme();

	const language = useMemo(() => localStorage.getItem("language") ?? "it", []);

	const currency = useMemo(
		() =>
			(value: number): string => {
				const currencyFormat = new Intl.NumberFormat(language + "-" + restaurantInfo.country, { style: "currency", currency: restaurantInfo.currency });
				return currencyFormat.format(centsToValue(value));
			},
		[language, restaurantInfo]
	);

	return { currency };
};
