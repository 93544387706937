import { CloudOff } from "@mui/icons-material";
import { Box, Container, Dialog, Grid, LinearProgress } from "@mui/material";
import { t } from "i18next";
import { MouseEvent as ReactMouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate, useParams } from "react-router-dom";
import { CheckoutFlowStep, CheckoutMethod, hasElectronicPaymentMethods, LogChannel, ModalViewName, PaymentMethod, RoutePath } from "../../constants";
import { defaultCategory, defaultCheckoutMenu, defaultOrder, defaultPaymentInfo } from "../../constants/defaults";
import { cart_createEmpty, cart_getSimplifiedUid, useCart } from "../../hooks/useCart";
import { useModal } from "../../hooks/useModal";
import useNetworkStatus from "../../hooks/useNetworkStatus";
import usePrintingQueue from "../../hooks/usePrintingQueue";
import { GetMenuFromStorage, orderNoPayment } from "../../services/4Delivery";
import { CartItem, Category, CheckoutMenu, ItemSelectable, KioskMenu, Order, OrderNoPaymentResponse, PaymentErrorResponse, PaymentInfo } from "../../types";
import { useGetRestaurantFileContent, useGetRestaurantMedia } from "../../utils/cdnAssets";
import { getUpSellingItems } from "../../utils/UpSelling";
import { useLogger } from "../../utils/useLogger";
import LanguageFlagStrip from "../LanguageFlags/LanguageFlagStrip";
import { useMessage } from "../MessageHandler/MessageService";
import { useTheme } from "../Theme/ThemeWrapper";
import CartBottomBar from "./BottomBar/CartBottomBar";
import ItemsCategoriesList from "./Items/ItemsCategoriesList";
import { EditableItemDialog, FilteredOrderReviewDialog, OrderReviewDialog, PaymentMethodModal, PaymentModal, PlacePickerModal, ReceiptModal } from "./Modals";
import "./OrderMaker.scss";
import Sidebar from "./Sidebar/Sidebar";

const OrderMaker = () => {
	const { theme, settings } = useTheme();
	const navigate = useNavigate();
	const message = useMessage();
	const { isOnline } = useNetworkStatus();
	const { method } = useParams();
	const { log, warn } = useLogger();

	const { url: logoUrl, isFallback: isLogoFallback } = useGetRestaurantMedia("logo");
	const { content: disclaimer, isFallback: disclaimerNotFound } = useGetRestaurantFileContent("allergen");
	const { modal, openModal, closeModal } = useModal();
	const { cart, addItemToCart, updateItemCart, removeItemFromCart, resetCart } = useCart();
	const { isCashSystemOutOfPaper, isCashSystemInError, processPrintingQueue } = usePrintingQueue(settings.cashSystemIP);

	const kioskMenu: KioskMenu = useMemo(() => GetMenuFromStorage(), []);
	const checkoutMethod: CheckoutMethod = useMemo(() => Number(method) as CheckoutMethod, [method]);
	const menu: CheckoutMenu = useMemo(
		() => kioskMenu.checkoutMenus.find((cm: CheckoutMenu) => cm.checkout === checkoutMethod) ?? defaultCheckoutMenu,
		[checkoutMethod, kioskMenu]
	);
	const hasElectronicPayment: boolean = useMemo(() => hasElectronicPaymentMethods(settings.availablePaymentMethods), [settings.availablePaymentMethods]);

	const [langStripCondensed, setLangStripCondensed] = useState<boolean>(true);
	const [selectedCategory, setSelectedCategory] = useState<Category>(menu.categories[0] ?? defaultCategory);
	const [selectedCategoryChildCode, setSelectedCategoryChildCode] = useState<string>(menu.categories[0]?.children[0]?.categoryCode ?? "");
	const [order, setOrder] = useState<Order>({ ...defaultOrder, checkoutMethod: checkoutMethod, orderUid: String(Date.now()) });
	const [cartItemSelected, setCartItemSelected] = useState<CartItem>({} as CartItem);
	const [isNewCartItemSelected, setIsNewCartItemSelected] = useState<boolean>(true);
	const [filterUid, setFilterUid] = useState<string>("");
	const [checkoutFlowStep, setCheckoutFlowStep] = useState<CheckoutFlowStep>(CheckoutFlowStep.orderMaking);

	useEffect(() => {
		processPrintingQueue();
	}, [processPrintingQueue]);

	useEffect(() => {
		if (isCashSystemOutOfPaper) {
			message({
				title: t("system.error.cashSystem.error").toUpperCase(),
				description: t("system.error.cashSystem.statusError") + ": " + t("system.error.cashSystem.out-of-paper"),
				okCallback: () => {
					processPrintingQueue();
				},
				okLabel: t("common.retry").toUpperCase(),
				cancelCallback: () => cancelOrder(),
				cancelLabel: t("common.back").toUpperCase()
			});
		}
		if (isCashSystemInError) {
			message({
				title: t("system.error.cashSystem.error").toUpperCase(),
				description: t("system.error.cashSystem.statusError"),
				okCallback: () => cancelOrder(),
				okLabel: t("common.ok").toUpperCase()
			});
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [isCashSystemOutOfPaper, isCashSystemInError]);

	const idleTimerInstance = useIdleTimer({
		name: "2minutes-inactive-timer",
		onIdle: () => {
			if (cart.amount === 0) cancelOrder();
		},
		timeout: 1000 * 60 * 2
	});

	const newCartItem = useCallback(
		(itemSelectable: ItemSelectable, openDetail: boolean = false, referral?: ModalViewName): void => {
			const cartItem: CartItem = cart_createEmpty(itemSelectable, 1);

			if (openDetail || itemSelectable.hasMandatoryFields) {
				setCartItemSelected(cartItem);
				setIsNewCartItemSelected(true);
				openModal(ModalViewName.productDetail, undefined, referral ?? undefined);
			} else {
				addItemToCart(cartItem);
			}
		},
		[addItemToCart, openModal]
	);

	const decreaseItemCart = useCallback(
		(itemSelectable: ItemSelectable): void => {
			// looking for CartItems with the same simplified uid. i.e. two CartItem with different variations set are considered equal
			const filteredCartItems = cart.items.filter((cartItem: CartItem) => {
				return cart_getSimplifiedUid(cartItem.uid) === itemSelectable.itemInfo.uid;
			});

			// if just one variations found...
			if (filteredCartItems.length === 1) {
				const singleCartItem: CartItem = { ...filteredCartItems[0] };
				if (singleCartItem.quantity === 1) {
					removeItemFromCart(singleCartItem.uid);
				} else {
					singleCartItem.quantity = -1;
					addItemToCart(singleCartItem);
				}
				setFilterUid("");
			} else {
				// ...otherwise open Modal and make user choose the one variation to remove
				setFilterUid(itemSelectable.itemInfo.uid);
				openModal(ModalViewName.filteredOrderReview, true);
			}
		},
		[openModal, removeItemFromCart, addItemToCart, cart.items]
	);

	const editCartItem = useCallback(
		(cartItem: CartItem): void => {
			setCartItemSelected(cartItem);
			setIsNewCartItemSelected(false);
			openModal(ModalViewName.productDetail);
		},
		[openModal]
	);

	const confirmAddItem = useCallback(
		(cartItem: CartItem): void => {
			closeModal();

			if (!isNewCartItemSelected) {
				updateItemCart(cartItem);
				setIsNewCartItemSelected(true);
				openModal(ModalViewName.orderReview);
			} else {
				addItemToCart(cartItem);
			}
		},
		[openModal, closeModal, updateItemCart, addItemToCart, isNewCartItemSelected]
	);

	const cancelAddItem = useCallback((): void => {
		closeModal();

		if (!isNewCartItemSelected) {
			setIsNewCartItemSelected(true);
			openModal(ModalViewName.orderReview);
		}
	}, [isNewCartItemSelected, openModal, closeModal]);

	const backToOrderMaking = useCallback(() => {
		closeModal();
		setCheckoutFlowStep(CheckoutFlowStep.orderMaking);
	}, [closeModal]);

	const confirmReviewOrder = useCallback(() => {
		setCheckoutFlowStep(CheckoutFlowStep.orderReview);
	}, []);

	const confirmOrder = () => {
		log(`Cart amount: ${cart.amount}`, LogChannel.order);
		setCheckoutFlowStep(CheckoutFlowStep.checkoutMethod);
	};

	const confirmPlaceNumber = useCallback((newPlaceNumber: string) => {
		setOrder((prev: Order) => ({
			...prev,
			placeNumber: newPlaceNumber
		}));
		setCheckoutFlowStep(CheckoutFlowStep.placeNumber);
	}, []);

	const confirmPaymentMethod = (paymentMethod: PaymentMethod) => {
		setOrder((prev: Order) => ({
			...prev,
			paymentMethod: paymentMethod
		}));
		log(`Payment method: ${paymentMethod}`, LogChannel.order);
		setCheckoutFlowStep(CheckoutFlowStep.paymentMethod);
	};

	const confirmPayment = async (newPaymentInfo: PaymentInfo, newKioskCartId: number | null) => {
		setOrder((prev: Order) => ({
			...prev,
			paymentInfo: newPaymentInfo,
			kioskCartId: newKioskCartId
		}));
		log(`KioskCartId: ${newKioskCartId}`, LogChannel.order);
		setCheckoutFlowStep(CheckoutFlowStep.paymentInfo);
	};

	const confirmReceipt = useCallback((): void => {
		setCheckoutFlowStep(CheckoutFlowStep.orderConfirmation);
	}, []);

	const cancelOrder = useCallback((): void => {
		setCheckoutFlowStep(CheckoutFlowStep.orderCancel);
	}, []);

	const resetAndExit = useCallback((): void => {
		resetCart();
		navigate(RoutePath.homePage);
	}, []);

	useEffect(() => {
		log(checkoutFlowStep, LogChannel.order);

		switch (checkoutFlowStep) {
			case CheckoutFlowStep.orderReview:
				// Show modal in which the user can Review the Cart ad eventually confirm the order
				openModal(ModalViewName.orderReview);
				break;

			case CheckoutFlowStep.checkoutMethod:
				// Show or skip PlacePicker modal depending on Checkout method
				if (checkoutMethod === CheckoutMethod.PLACE_NUMBER) {
					if (settings.skipPlaceNumberChoice) {
						// setting place number to 0 so the backend can handle it properly
						confirmPlaceNumber("0");
					} else {
						openModal(ModalViewName.placePicker);
					}
				} else {
					confirmPlaceNumber("");
				}
				break;

			case CheckoutFlowStep.placeNumber:
				// Show or skip Payment Method modal depending on Payment Method amount
				if (settings.availablePaymentMethods.length > 1) {
					openModal(ModalViewName.paymentMethod);
				} else {
					confirmPaymentMethod(settings.availablePaymentMethods[0]);
				}
				break;

			case CheckoutFlowStep.paymentMethod:
				// Show or skip Payment modal depending on Payment Method type (cash doesn't need payment)
				if (order.paymentMethod !== PaymentMethod.CASH) {
					openModal(ModalViewName.payment);
				} else {
					// make Order without actual Payment
					orderNoPay();
				}
				break;

			case CheckoutFlowStep.paymentInfo:
				// Show or skip Receipt modal depending on Cash System availability
				if (settings.isCashSystemEnabled) {
					openModal(ModalViewName.receipt);
				} else {
					confirmReceipt();
				}
				break;

			case CheckoutFlowStep.orderConfirmation:
			case CheckoutFlowStep.orderCancel:
				resetAndExit();
				break;

			default:
				break;
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [checkoutFlowStep]);

	const orderNoPay = useCallback(async () => {
		// since there's no payment, fiscal mode is forced to false
		await orderNoPayment(false, cart, order)
			.then((response: OrderNoPaymentResponse) => {
				const newPaymentInfo: PaymentInfo = { ...defaultPaymentInfo, order_id: response.order_id };
				log("Sent Order without payment", LogChannel.payment);
				confirmPayment(newPaymentInfo, response.kiosk_cart_id);
			})
			.catch((response: PaymentErrorResponse) => {
				if (!response.is_back_error) {
					warn(`Order without payment - error response: ${response.error_code}`, LogChannel.payment);
				} else {
					warn(`Order without payment - error response from back: ${response.error_code}`, LogChannel.payment);
				}
				message({
					title: t(`system.error.${response.error_code}`).toUpperCase(),
					description: t("system.error.order_not_created"),
					okCallback: () => cancelOrder(),
					okLabel: t("common.ok").toUpperCase()
				});
			});
	}, [cart, message, confirmPayment, order.checkoutMethod, order.placeNumber]);

	const handleCancelOrder = useCallback(() => {
		idleTimerInstance.pause();
		message({
			title: t("checkout.cartBottomBar.cancelOrder").toUpperCase(),
			description: t("checkout.cartBottomBar.cancelOrderConfirm"),
			okCallback: () => {
				idleTimerInstance.resume();
				cancelOrder();
			},
			okLabel: t("common.yes").toUpperCase(),
			cancelCallback: () => {
				idleTimerInstance.resume();
			},
			cancelLabel: t("common.cancel").toUpperCase()
		});
	}, [message, cancelOrder, idleTimerInstance]);

	const scrollToCategoryChild = useCallback((categoryCode?: string): void => {
		function delay(time: number) {
			return new Promise((resolve) => setTimeout(resolve, time));
		}
		// scroll after 100ms... react must render the component before the "scroll" function start looking for anchor DOM elements
		delay(100).then(() => {
			const elementId: string = categoryCode === undefined ? "init" : "category-" + categoryCode;
			const target = window.document.getElementById(elementId);
			if (target) {
				target.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
			}
		});
	}, []);

	const changeSelectedCategory = useCallback(
		(categoryTitle: string): void => {
			const newCategory: Category = menu.categories.find((category: Category) => category.title === categoryTitle) ?? menu.categories[0];

			setSelectedCategory(newCategory);
			setSelectedCategoryChildCode(newCategory.children[0].categoryCode);

			scrollToCategoryChild(newCategory.children[0].categoryCode);
		},
		[scrollToCategoryChild, menu.categories]
	);

	const changeSelectedCategoryChild = useCallback(
		(e: ReactMouseEvent<HTMLDivElement, MouseEvent>, categoryCode: string): void => {
			e.stopPropagation();

			setSelectedCategoryChildCode(categoryCode);
			scrollToCategoryChild(categoryCode);
		},
		[scrollToCategoryChild]
	);

	const handleChooseLanguage = useCallback(() => setLangStripCondensed((prev: boolean) => !prev), []);

	return (
		<Container className="menuContainer">
			{!isOnline && hasElectronicPayment ? <CloudOff color="error" sx={{ position: "absolute", right: "0.25rem", fontSize: "1.25rem" }} /> : null}
			<Box className="header" sx={{ backgroundColor: theme.customTheme.palette.background.default }}>
				<LanguageFlagStrip isCondensed={langStripCondensed} onClick={handleChooseLanguage} />
				{isLogoFallback ? null : <img className="customLogo" src={logoUrl} alt="" loading="lazy" />}
			</Box>

			<Box className="main">
				{menu ? (
					<>
						<Grid container spacing={0}>
							<Grid xs={menu.categories.length > 1 && !settings.singleCategory ? 2 : 1} item={true}>
								{menu.categories.length > 1 && !settings.singleCategory ? (
									<Sidebar
										menu={menu}
										changeSelectedCategory={changeSelectedCategory}
										changeSelectedCategoryChild={changeSelectedCategoryChild}
										selectedCategory={selectedCategory}
										selectedCategoryChildCode={selectedCategoryChildCode}
									/>
								) : null}
							</Grid>
							<Grid xs={10} item={true}>
								{selectedCategory.children.length > 0 ? (
									<ItemsCategoriesList
										selectedCategory={selectedCategory}
										cart={cart}
										onNewCartItem={newCartItem}
										decreaseItemCart={decreaseItemCart}
									/>
								) : null}
							</Grid>
						</Grid>
					</>
				) : (
					<LinearProgress />
				)}
			</Box>

			<Dialog
				open={modal.open}
				fullScreen={!modal.overlay}
				fullWidth={true}
				maxWidth="xl"
				onClose={closeModal}
				className={modal.overlay ? "overlayModal" : ""}
				scroll="paper"
			>
				{modal.name === ModalViewName.productDetail && (
					<EditableItemDialog
						isNewCartItemSelected={isNewCartItemSelected}
						cartItem={cartItemSelected}
						closeModal={cancelAddItem}
						addItemToCart={confirmAddItem}
						categories={kioskMenu.categoryList}
						idleInstance={idleTimerInstance}
						disclaimer={disclaimer}
						disclaimerNotFound={disclaimerNotFound}
					/>
				)}

				{modal.name === ModalViewName.filteredOrderReview && (
					<FilteredOrderReviewDialog
						cart={cart}
						filterUid={filterUid}
						addItemToCart={addItemToCart}
						removeItemFromCart={removeItemFromCart}
						closeModal={closeModal}
					/>
				)}

				{modal.name === ModalViewName.orderReview && (
					<OrderReviewDialog
						cart={cart}
						addItemToCart={addItemToCart}
						removeItemFromCart={removeItemFromCart}
						closeModal={backToOrderMaking}
						confirmOrder={confirmOrder}
						goToItemCartEditor={editCartItem}
						recommendedItems={getUpSellingItems(menu, theme.config.upSellingList, kioskMenu.upSellingList)}
						onNewCartItem={newCartItem}
						setIsNewCartItemSelected={setIsNewCartItemSelected}
					/>
				)}

				{modal.name === ModalViewName.placePicker && <PlacePickerModal closeModal={backToOrderMaking} confirmPlaceNumber={confirmPlaceNumber} />}

				{modal.name === ModalViewName.paymentMethod && (
					<PaymentMethodModal closeModal={backToOrderMaking} confirmPaymentMethod={confirmPaymentMethod} />
				)}

				{modal.name === ModalViewName.payment && <PaymentModal cancelOrder={cancelOrder} confirmPayment={confirmPayment} cart={cart} order={order} />}

				{modal.name === ModalViewName.receipt && <ReceiptModal closeModal={confirmReceipt} cart={cart} order={order} />}
			</Dialog>

			<CartBottomBar
				amount={cart.amount}
				itemsCount={cart.itemsCount}
				cancelOrder={handleCancelOrder}
				reviewOrder={confirmReviewOrder}
				confirmOrder={confirmOrder}
			/>
		</Container>
	);
};

export default OrderMaker;
