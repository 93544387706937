import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LogChannel, PaymentRequestStatus } from "../../../../constants";
import { ElectronicPayment } from "../../../../services/4Delivery";
import { Cart, Order, PaymentErrorResponse, PaymentIntentResponse } from "../../../../types";
import { OrderRequestManager } from "../../../../utils/OrderRequestManager";
import { useLogger } from "../../../../utils/useLogger";
import { useMessage } from "../../../MessageHandler/MessageService";
import { useTheme } from "../../../Theme/ThemeWrapper";

interface CreateIntentProps {
	cart: Cart;
	order: Order;
	electronicPayment: ElectronicPayment;
	paymentIntent: PaymentIntentResponse;
	setRequestStatus: Dispatch<SetStateAction<PaymentRequestStatus>>;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	setPaymentIntent: Dispatch<SetStateAction<PaymentIntentResponse>>;
	cancelOrder: () => void;
}
const CreateIntent = ({ cart, order, electronicPayment, paymentIntent, setRequestStatus, setIsLoading, setPaymentIntent, cancelOrder }: CreateIntentProps) => {
	const { t } = useTranslation();
	const message = useMessage();
	const { warn } = useLogger();
	const { settings } = useTheme();

	const createIntent = async () => {
		const orderRequestManager = new OrderRequestManager(order);

		const handleError = (err: PaymentErrorResponse) => {
			if (!err.is_back_error) {
				orderRequestManager.revertLastOrderRequestId();
				warn(`CreateIntent - error response: ${err.error_code}`, LogChannel.payment);
			} else {
				warn(`CreateIntent - error response from back: ${err.error_code}`, LogChannel.payment);
			}

			message({
				title: t(`system.error.${err.error_code ?? ""}`).toUpperCase(),
				description: t("common.retryQuestion"),
				okCallback: () => {
					createIntent();
				},
				okLabel: t("common.yes").toUpperCase(),
				cancelCallback: () => {
					if (paymentIntent.kiosk_cart_id) {
						setRequestStatus(PaymentRequestStatus.cancelingIntent);
					} else {
						cancelOrder();
					}
				},
				cancelLabel: t("common.cancel").toUpperCase()
			});
		};

		setIsLoading(true);

		await electronicPayment
			.createIntent(settings.isFiscalMode, cart, order)
			.then((response: PaymentIntentResponse) => {
				setPaymentIntent(response);
				setRequestStatus(PaymentRequestStatus.activateReaderRN);
			})
			.catch((err: PaymentErrorResponse) => {
				handleError(err);
			})
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		/* eslint-disable react-hooks/exhaustive-deps */
		createIntent();
	}, []);

	return null;
};

export default CreateIntent;
