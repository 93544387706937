import { Box, TableCell, TableRow, Typography, alpha } from "@mui/material";
import { memo, useCallback, useMemo } from "react";
import { cart_calculatePrice, cart_generateVariationUid, cart_optionAdd } from "../../../../hooks/useCart";
import { useCurrency } from "../../../../hooks/useCurrency";
import { CartItem } from "../../../../types";
import i18n from "../../../../utils/i18n/i18n";
import { tItemInfo } from "../../../../utils/i18nMenu";
import TouchToggleButton from "../../../Layout/Buttons/TouchToggleButton";
import { useTheme } from "../../../Theme/ThemeWrapper";
import "./VariationItemSelectableMultiple.scss";
import { VariationItemSelectableMultipleProps } from "./VariationItemSelectableMultipleAdd";

const VariationItemSelectableMultipleSub = ({
	option,
	initialQuantity,
	currentCartItem,
	setCurrentCartItem,
	itemLinkedListId
}: VariationItemSelectableMultipleProps) => {
	const { theme, hideSnoozed } = useTheme();
	const { currency } = useCurrency();
	const unavailable: boolean = option.item.itemInfo.snoozed === "true";
	const borderColor: string = useMemo(() => alpha(theme.systemTheme.palette.grey[300], 0.8), [theme.systemTheme]);

	const handleToggleQuantity = useCallback(() => {
		const value = option.quantity === 0 ? 1 : -1;

		const newOption: CartItem = {
			...option,
			quantity: value,
			uid: cart_generateVariationUid(option.item.itemInfo.uid, itemLinkedListId)
		};

		const newOptions: CartItem[] = cart_optionAdd(newOption, currentCartItem.options);

		setCurrentCartItem({
			...currentCartItem,
			options: newOptions,
			resultingPrice: currentCartItem.item.itemInfo.price + cart_calculatePrice(newOptions)
		});
	}, [currentCartItem, itemLinkedListId, option, setCurrentCartItem]);

	const price: string = useMemo(() => (option.resultingPrice !== 0 ? "-" + currency(option.resultingPrice) : ""), [option.resultingPrice, currency]);

	return (
		<TableRow
			className="ingredientRow"
			sx={{
				display: hideSnoozed && unavailable ? "none" : "table-row",
				borderColor: borderColor,
				opacity: unavailable ? "0.75" : 1
			}}
		>
			<TableCell className="col-button" colSpan={3}>
				{unavailable ? null : (
					<TouchToggleButton
						active={Boolean(option.quantity)}
						statusToCompare={initialQuantity ? Boolean(initialQuantity) : undefined}
						onToggle={handleToggleQuantity}
					/>
				)}
			</TableCell>
			<TableCell className="col-desc" colSpan={7} align="left">
				<Box>
					<Typography variant="caption" fontSize="1.5rem" className="itemTitle">
						{tItemInfo(i18n.language, option.item.itemInfo, "shortText")}
					</Typography>
				</Box>
			</TableCell>
			<TableCell className="col-price" colSpan={2} align="right">
				{option.quantity ? <Typography>{price}</Typography> : null}
			</TableCell>
		</TableRow>
	);
};

export default memo(VariationItemSelectableMultipleSub);
