import { Box, TableCell, TableRow, Typography, alpha } from "@mui/material";
import { memo, useCallback, useMemo } from "react";
import { cart_calculatePrice, cart_generateVariationUid, cart_optionAdd } from "../../../../hooks/useCart";
import { useCurrency } from "../../../../hooks/useCurrency";
import { CartItem } from "../../../../types";
import i18n from "../../../../utils/i18n/i18n";
import { tItemInfo } from "../../../../utils/i18nMenu";
import CounterButtonGroup from "../../../Layout/Buttons/CounterButtonGroup";
import { useTheme } from "../../../Theme/ThemeWrapper";
import "./VariationItemSelectableMultiple.scss";

export interface VariationItemSelectableMultipleProps {
	option: CartItem;
	initialQuantity: number | undefined;
	currentCartItem: CartItem;
	setCurrentCartItem: (cartItem: CartItem) => void;
	itemLinkedListId: string;
	min: number;
	max: number;
}

const VariationItemSelectableMultipleAdd = ({
	option,
	initialQuantity,
	currentCartItem,
	setCurrentCartItem,
	itemLinkedListId,
	min,
	max
}: VariationItemSelectableMultipleProps) => {
	const { theme, hideSnoozed } = useTheme();
	const { currency } = useCurrency();

	const unavailable: boolean = option.item.itemInfo.snoozed === "true";
	const borderColor: string = useMemo(() => alpha(theme.systemTheme.palette.grey[300], 0.8), [theme.systemTheme]);
	const price: string = useMemo(() => (option.resultingPrice !== 0 ? currency(option.resultingPrice) : ""), [option.resultingPrice, currency]);

	const changeQuantity = useCallback(
		(value: 1 | -1) => {
			const newOption: CartItem = {
				...option,
				quantity: value,
				uid: cart_generateVariationUid(option.item.itemInfo.uid, itemLinkedListId)
			};

			const newOptions: CartItem[] = cart_optionAdd(newOption, currentCartItem.options);

			setCurrentCartItem({
				...currentCartItem,
				options: newOptions,
				resultingPrice: currentCartItem.item.itemInfo.price + cart_calculatePrice(newOptions)
			});
		},
		[option, currentCartItem, itemLinkedListId, setCurrentCartItem]
	);

	const handleDecrement = useCallback(() => changeQuantity(-1), [changeQuantity]);
	const handleIncrement = useCallback(() => changeQuantity(+1), [changeQuantity]);

	return (
		<TableRow
			className="ingredientRow"
			sx={{
				display: hideSnoozed && unavailable ? "none" : "table-row",
				borderColor: borderColor,
				opacity: unavailable ? "0.75" : 1
			}}
		>
			<TableCell className="col-button" colSpan={3}>
				{unavailable ? null : (
					<CounterButtonGroup
						counter={option.quantity}
						quantityToCompare={initialQuantity ?? 0}
						min={min}
						max={max}
						onDecrement={handleDecrement}
						onIncrement={handleIncrement}
					/>
				)}
			</TableCell>
			<TableCell className="col-desc" colSpan={7} align="left">
				<Box>
					<Typography variant="caption" fontSize="1.5rem" className="itemTitle">
						{tItemInfo(i18n.language, option.item.itemInfo, "shortText")}
					</Typography>
				</Box>
			</TableCell>
			<TableCell className="col-price" colSpan={2} align="right">
				<Typography>{price}</Typography>
			</TableCell>
		</TableRow>
	);
};

export default memo(VariationItemSelectableMultipleAdd);
