import { HighlightOffRounded } from "@mui/icons-material";
import { DialogActions, DialogContent, DialogTitle, ThemeProvider } from "@mui/material";
import { memo, ReactNode } from "react";
import { useTheme } from "../../../Theme/ThemeWrapper";
import "../../OrderMaker.scss";

interface DialogCenterProps {
	children?: ReactNode;
	closeModal: () => void;
	modalTitle: string;
}

const DialogCenter = ({ children, closeModal, modalTitle }: DialogCenterProps) => {
	const { theme } = useTheme();

	return (
		<ThemeProvider theme={theme.systemTheme}>
			<DialogActions>
				<HighlightOffRounded sx={{ fontSize: "3.5rem !important", m: "1rem 1rem 0 0" }} onClick={closeModal} />
			</DialogActions>
			<DialogTitle id="alert-dialog-title" variant="h4" color="primary" align="center">
				{modalTitle}
			</DialogTitle>
			<DialogContent>{children}</DialogContent>
		</ThemeProvider>
	);
};

export default memo(DialogCenter);
