import { CreditCard, Euro, HighlightOffRounded } from "@mui/icons-material";
import { Box, Stack, ThemeProvider, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentMethod, WebviewApp, getPaymentMethodName } from "../../../constants";
import useStaticImage from "../../../hooks/useStaticImage";
import TouchButton from "../../Layout/Buttons/TouchButton";
import { useTheme } from "../../Theme/ThemeWrapper";
import ConfirmModal from "./Wrappers/ConfirmModal";

interface PaymentMethodModalProps {
	closeModal: () => void;
	confirmPaymentMethod: (method: PaymentMethod) => void;
}

const PaymentMethodModal = ({ confirmPaymentMethod, closeModal }: PaymentMethodModalProps) => {
	const { t } = useTranslation();
	const { theme, settings, bridge, container } = useTheme();
	const currentMethods: PaymentMethod[] = settings.availablePaymentMethods;
	const { loading: logoSatispayLoading, error: logoSatispayError, image: logoSatispayImage } = useStaticImage("system/logoSatispay.svg");

	const [isUsbReaderConnected, setUsbReaderConnected] = useState<boolean>(false);

	useEffect(() => {
		const getUsbReaderConnection = async (): Promise<void> => {
			let connected: boolean = false;
			if (settings.availablePaymentMethods.includes(PaymentMethod.STRIPE_RN) && container === WebviewApp.reactNative && bridge.bridgeActive) {
				connected = await bridge.testUsbStripeReader();
			}

			setUsbReaderConnected(connected);
		};

		getUsbReaderConnection();
	}, [bridge, container, settings.availablePaymentMethods]);

	const getIcon = useCallback(
		(method: PaymentMethod): JSX.Element => {
			const format = { width: "6rem", height: "5.5rem" };
			switch (method) {
				case PaymentMethod.CASH:
					return <Euro sx={format} />;
				case PaymentMethod.STRIPE:
				case PaymentMethod.STRIPE_RN:
					return <CreditCard sx={format} />;
				case PaymentMethod.SATISPAY:
					return (
						<>
							{!logoSatispayLoading && !logoSatispayError ? (
								<img
									className="logoSatispay"
									src={logoSatispayImage ?? ""}
									alt={t("checkout.paymentModal.type.satispay") ?? ""}
									loading="lazy"
								/>
							) : null}
						</>
					);
				default:
					return <></>;
			}
		},
		[logoSatispayError, logoSatispayImage, logoSatispayLoading, t]
	);

	return (
		<ConfirmModal>
			<Box>
				<Box>
					<Typography color="default" variant="h4" textAlign="center" sx={{ my: "1rem" }}>
						{t("checkout.paymentModal.chooseMethod").toUpperCase()}
					</Typography>

					<ThemeProvider theme={theme.customTheme}>
						{currentMethods
							.sort((a, b) => (a > b ? 1 : -1))
							.map((method: PaymentMethod) => {
								const handleClick = () => confirmPaymentMethod(method);
								const buttonText: string = t(`checkout.paymentModal.type.${getPaymentMethodName(method)}Label`).toUpperCase();
								const buttonIcon = getIcon(method);

								return (
									<Stack key={method}>
										<TouchButton
											variant={method === PaymentMethod.SATISPAY ? "text" : "contained"}
											size="large"
											color="primary"
											onClick={handleClick}
											sx={{
												my: "0.8rem",
												mx: "1.5rem",
												py: "0",
												px: "3.5rem",
												minHeight: "11rem",
												borderRadius: "2rem",
												justifyContent: method === PaymentMethod.SATISPAY ? "center" : "space-between",
												borderWidth: "0.25rem",
												//background: `linear-gradient(0deg, ${theme.customTheme.palette.primary.dark} 0%, ${theme.customTheme.palette.primary.light} 100%)`,
												boxShadow: theme.systemTheme.shadows[10],
												borderColor: method === PaymentMethod.SATISPAY ? theme.systemTheme.palette.satispay.main : undefined,
												borderStyle: method === PaymentMethod.SATISPAY ? "solid" : undefined
											}}
											startIcon={buttonIcon}
											disabled={method === PaymentMethod.STRIPE_RN && !isUsbReaderConnected}
										>
											{method !== PaymentMethod.SATISPAY ? (
												<Typography variant="button" fontSize="2.3rem" lineHeight="1.5" textAlign="center" sx={{ width: "75%" }}>
													{buttonText}
												</Typography>
											) : null}
										</TouchButton>
									</Stack>
								);
							})}
					</ThemeProvider>
				</Box>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<TouchButton color="error" variant="contained" size="large" startIcon={<HighlightOffRounded />} onClick={closeModal}>
					<Typography variant="button">{t("common.cancel")}</Typography>
				</TouchButton>
			</Box>
		</ConfirmModal>
	);
};

export default PaymentMethodModal;
