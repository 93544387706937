import { useCallback } from "react";
import { useTheme } from "../components/Theme/ThemeWrapper";
import { LogChannel, WebviewApp } from "../constants";

export const useLogger = () => {
	const { container, settings, bridge } = useTheme();
	const isDebugging = settings.isDebugEnabled;
	const isProduction = process.env.NODE_ENV === "production";

	const LOG_VALUE_LENGTH = 200;

	const getColor = useCallback((channel: LogChannel): string => {
		switch (channel) {
			case LogChannel.router:
				return "lightgreen";
			case LogChannel.language:
				return "lightyellow";
			case LogChannel.cloudSync:
				return "cyan";
			case LogChannel.order:
				return "magenta";
			case LogChannel.payment:
				return "orange";
			case LogChannel.document:
				return "lightblue";
			case LogChannel.cashSystem:
				return "gray";
			default:
				return "white";
		}
	}, []);

	const write = useCallback(
		(level: "log" | "warn" | "error", val: unknown, prefix: LogChannel): void => {
			const value = val ?? "";
			const prefix_text = prefix.toUpperCase() + ":";
			const printValue: string = JSON.stringify(value);
			const finalString: string = prefix_text + printValue.substring(0, LOG_VALUE_LENGTH) + (printValue.length > LOG_VALUE_LENGTH ? "..." : "");

			// console log Debug
			if (!isProduction) {
				console[level]("%c" + finalString, `color: ${getColor(prefix)}`);
			}

			// persistent lot in FS through bridge with React Native app
			if (isDebugging && container === WebviewApp.reactNative && bridge.bridgeActive) {
				bridge.persistLog(finalString);
			}
		},
		[getColor, isProduction, isDebugging, bridge, container]
	);

	const log = useCallback(
		(value: unknown, prefix: LogChannel = LogChannel.generic): void => {
			write("log", value, prefix);
		},
		[write]
	);

	const warn = useCallback(
		(value: unknown, prefix: LogChannel = LogChannel.generic): void => {
			write("warn", value, prefix);
		},
		[write]
	);

	// const error = useCallback(
	// 	(value: unknown, prefix: LogChannel = LogChannel.generic): void => {
	// 		write("error", value, prefix);
	// 	},
	// 	[write]
	// );

	return { log, warn };
};
