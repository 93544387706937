import { ListItemButton, ListItemButtonProps } from "@mui/material";
import { memo } from "react";
import useSoundEffect, { SoundEffectProps } from "../../../hooks/effects/useSoundEffect";

interface TouchListItemButtonProps extends ListItemButtonProps, SoundEffectProps /*, LongPressEffectProps */ {}

const TouchListItemButton = ({ muteSound, tone, /*hasLongPress,*/ ...rest }: TouchListItemButtonProps) => {
	const { playSound } = useSoundEffect({ muteSound, tone });

	/** All list item should not have the LongPress effect enabled */
	/* const { pressEvent, pressRelease } = useLongPressEffect({ hasLongPress, longPressClickCallback: rest.onClick }); */

	return <ListItemButton disableRipple disableTouchRipple onClickCapture={playSound} /* onTouchStart={pressEvent} onTouchEnd={pressRelease} */ {...rest} />;
};

export default memo(TouchListItemButton);
