// the space each character occupies (relative to a single plain character)
export enum Dim {
	W1H1 = "0", // width 1, height 1
	W1H2 = "1", // width 1, height 2
	W1H3 = "2",
	W1H4 = "3",
	W2H1 = "A",
	W2H2 = "4",
	W2H3 = "5",
	W2H4 = "7",
	W3H1 = "B",
	W3H2 = "C",
	W3H3 = "6",
	W3H4 = "8",
	W4H2 = "D",
	W4H4 = "9" // width 4, height 4
}

export enum Colors {
	blackOnWhite = "0",
	whiteOnBlack = "1",
	whiteOnGray1 = "2",
	whiteOnGray2 = "3",
	blackOnGray1 = "4",
	blackOnGray2 = "5"
}

export enum Weight {
	regular = 0,
	bold = 1
}

export enum Decoration {
	none = 0,
	underline = 1
}

export const getDimWidth = (dim: Dim): number => {
	switch (dim) {
		case Dim.W1H1:
		case Dim.W1H2:
		case Dim.W1H3:
		case Dim.W1H4:
			return 1;
		case Dim.W2H1:
		case Dim.W2H2:
		case Dim.W2H3:
		case Dim.W2H4:
			return 2;
		case Dim.W3H1:
		case Dim.W3H2:
		case Dim.W3H3:
		case Dim.W3H4:
			return 3;
		case Dim.W4H2:
		case Dim.W4H4:
			return 4;
		default:
			return 1;
	}
};
