import { Close, HighlightOffRounded } from "@mui/icons-material";
import { Alert, CircularProgress, Collapse, Divider, Stack, TextField, ThemeProvider } from "@mui/material";
import { t } from "i18next";
import { ChangeEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LogChannel, RoutePath, WebviewApp } from "../../constants";
import { useWebview } from "../../hooks/useWebview";
import { callLogin, useRestaurantInfo } from "../../services/4Delivery";
import { callLoginResponse } from "../../types";
import { callAxiosResponseInterface } from "../../utils/httpClient/AxiosWrapper";
import { useLogger } from "../../utils/useLogger";
import TouchButton from "../Layout/Buttons/TouchButton";
import { useMessage } from "../MessageHandler/MessageService";
import { useTheme } from "../Theme/ThemeWrapper";

const Login = () => {
	const message = useMessage();
	const { log, warn } = useLogger();
	const { theme, setContextRestaurantId, bridge, container } = useTheme();
	const navigate = useNavigate();
	const { getCloudConfigAndMenu } = useRestaurantInfo();
	const { appClose } = useWebview();

	const [username, setUsername] = useState<string>(localStorage.getItem("username") ?? "");
	const [password, setPassword] = useState<string>(localStorage.getItem("password") ?? "");
	const [formError, setFormError] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);

	const handleSaveCredentials = async () => {
		localStorage.setItem("username", username);
		localStorage.setItem("password", password);

		setLoading(true);
		await callLogin()
			.then(async (loginResponse: callLoginResponse) => {
				if (loginResponse.status === 200 && loginResponse.data.success) {
					log("logged in", LogChannel.authentication);
					if (setContextRestaurantId) {
						setContextRestaurantId(username);
					}

					await getCloudConfigAndMenu();

					if (container === WebviewApp.reactNative && bridge.bridgeActive) {
						// TO DO make a call to get the right stripe location
						bridge.setStripeLocationId("tml_FuXgwT641d8Gmm");
					}

					navigate(RoutePath.homePage);
				}
			})
			.catch((err: callAxiosResponseInterface) => {
				setFormError(err.data.error_code);
				warn(err.data.error_code, LogChannel.authentication);
				localStorage.removeItem("username");
				localStorage.removeItem("password");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleCloseApp = useCallback(() => {
		message({
			title: t("system.admin.access.closeApp"),
			description: t("system.admin.access.closeAppConfirm"),
			okCallback: () => {
				appClose();
			},
			okLabel: t("common.yes"),
			cancelCallback: () => {},
			cancelLabel: t("common.cancel") ?? ""
		});
	}, [message, appClose]);

	const handleNavigateHome = useCallback(() => navigate(RoutePath.homePage), [navigate]);
	const handleCloseError = useCallback(() => setFormError(""), []);
	const handleChangeUsername = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setUsername(e.target.value), []);
	const handleChangePassword = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setPassword(e.target.value), []);

	return (
		<ThemeProvider theme={theme.systemTheme}>
			<Stack
				alignItems="center"
				justifyContent="center"
				sx={{
					backgroundColor: theme.systemTheme.palette.grey.A400,
					height: "100vh"
				}}
			>
				<Stack
					alignItems="center"
					component="form"
					borderRadius="1rem"
					boxShadow="10"
					padding="1rem"
					sx={{
						backgroundColor: theme.systemTheme.palette.background.default,
						width: "40vw",
						"& .MuiTextField-root,.MuiButton-root": {
							margin: 2
						}
					}}
					autoComplete="off"
				>
					<TouchButton
						color="info"
						onClick={handleNavigateHome}
						startIcon={<HighlightOffRounded />}
						size="large"
						sx={{
							width: "100%",
							justifyContent: "flex-end",
							"& svg": {
								fontSize: "3rem !important"
							}
						}}
					/>
					<TextField
						error={formError !== ""}
						id="input-username"
						label={t("system.admin.access.restaurantId")}
						defaultValue={username}
						onChange={handleChangeUsername}
					/>
					<TextField
						error={formError !== ""}
						id="input-password"
						label={t("common.password")}
						type="password"
						defaultValue={password}
						onChange={handleChangePassword}
					/>
					<TouchButton
						color="success"
						variant="contained"
						onClick={handleSaveCredentials}
						sx={{ minWidth: "50%" }}
						endIcon={loading ? <CircularProgress sx={{ marginLeft: "2rem" }} /> : null}
						disabled={loading}
					>
						{t("common.login")}
					</TouchButton>
					<Collapse in={formError !== ""}>
						<Alert severity="error" onClose={handleCloseError}>
							{t(`system.error.${formError}`)}
						</Alert>
					</Collapse>
					<Divider sx={{ my: 5 }}></Divider>
					<TouchButton variant="contained" color="primary" onClick={handleCloseApp} endIcon={<Close />} sx={{ mx: 2 }}>
						{t("system.admin.access.closeApp")}
					</TouchButton>
				</Stack>
			</Stack>
		</ThemeProvider>
	);
};

export default Login;
