import { Box, List } from "@mui/material";
import { memo, MouseEvent as ReactMouseEvent } from "react";
import { Category, CheckoutMenu } from "../../../types";
import "./Sidebar.scss";
import SidebarItem from "./SidebarItem";

interface SidebarProps {
	menu: CheckoutMenu;
	changeSelectedCategory: (categoryTitle: string) => void;
	changeSelectedCategoryChild: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>, categoryCode: string) => void;
	selectedCategory: Category;
	selectedCategoryChildCode: string;
}

const Sidebar = ({ menu, changeSelectedCategory, changeSelectedCategoryChild, selectedCategory, selectedCategoryChildCode }: SidebarProps) => {
	return (
		<Box className="sidebar">
			<nav aria-label="main-menu">
				<List className="navigation">
					{menu.categories.map((category, index) => (
						<SidebarItem
							key={index}
							category={category}
							changeSelectedCategory={changeSelectedCategory}
							changeSelectedCategoryChild={changeSelectedCategoryChild}
							isSelectedCategory={selectedCategory.title === category.title}
							selectedCategoryChildCode={selectedCategory.title === category.title ? selectedCategoryChildCode : undefined}
						/>
					))}
				</List>
				<Box className="verticalFiller"></Box>
			</nav>
		</Box>
	);
};

export default memo(Sidebar);
