import { AddShoppingCart, ArrowBack, Cloud, ColorLens, Login, Preview, Refresh, Settings } from "@mui/icons-material";
import { Box, Button, Container, Stack, Tab, Tabs, ThemeProvider, Typography } from "@mui/material";
import { cloneDeep } from "lodash-es";
import { ReactNode, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate, useParams } from "react-router-dom";
import { AdminRole, RoutePath } from "../../constants";
import { defaultConfig, defaultRestaurantInfo } from "../../constants/defaults";
import useStaticImage from "../../hooks/useStaticImage";
import { GetMenuFromStorage, useRestaurantInfo } from "../../services/4Delivery";
import { AppConfig, KioskMenu, LayoutConfig, SettingsConfig, UpSellingItem } from "../../types";
import { useGetRestaurantFileContent } from "../../utils/cdnAssets";
import { useMessage } from "../MessageHandler/MessageService";
import { buildTheme } from "../Theme/ThemeConfig";
import { calculateKioskIdSetting, useTheme } from "../Theme/ThemeWrapper";
import "./Admin.scss";
import AdminAccess from "./AdminAccess";
import AdminCloud from "./AdminCloud";
import AdminPreview from "./AdminPreview";
import AdminSettings from "./AdminSettings";
import AdminTheme from "./AdminTheme";
import AdminUpSelling from "./AdminUpSelling";

interface TabPanelProps {
	children?: ReactNode;
	index: number;
	tabValue: number;
	tabKey: string;
	description: string;
}

const Admin = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const message = useMessage();
	const { theme, settings, hideSnoozed, setContextTheme, setContextSetting, restaurantInfo } = useTheme();
	const { getCloudConfigAndMenu, getCloudConfig, setCloudConfig } = useRestaurantInfo();
	const { loading: logoLoading, error: logoError, image: logoImage } = useStaticImage("system/logoLabware.svg");
	const { loading: iqSelfLoading, error: iqSelfError, image: iqSelfImage } = useStaticImage("system/logoIQSelf.svg");
	const { content: disclaimer, isFallback: disclaimerNotFound } = useGetRestaurantFileContent("allergen");
	const versionNumber: string = defaultRestaurantInfo.config.versionNumber;
	const kioskMenu: KioskMenu = GetMenuFromStorage();
	const { role } = useParams();
	const isAdmin: boolean = (Number(role) as AdminRole) === AdminRole.administrator;

	const [currentConfig, setCurrentConfig] = useState<AppConfig>(theme.config);
	const [currentPin, setCurrentPin] = useState<string>(theme.config.pin);
	const [currentPinOperator, setCurrentPinOperator] = useState<string>(theme.config.pinOperator);
	const [currentUpSellingList, setCurrentUpSellingList] = useState<UpSellingItem[]>(theme.config.upSellingList);
	const [currentSettings, setCurrentSettings] = useState<SettingsConfig>(settings);
	const [currentCustomTheme, setCurrentCustomTheme] = useState<LayoutConfig>(theme.config.themes.custom);
	const [currentHideSnoozed, setCurrentHideSnoozed] = useState<number>(hideSnoozed);
	const [configUploadLoading, setConfigUploadLoading] = useState<boolean>(false);
	const [satispayDisabled, setSatispayDisabled] = useState<boolean>(false);

	const propagateCurrentConfig = useCallback(
		(newConfig: AppConfig, newKioskId: number): void => {
			if (setContextTheme && setContextSetting) {
				localStorage.setItem("localConfig", JSON.stringify(newConfig));
				setContextSetting(calculateKioskIdSetting(newConfig.settings, newKioskId));
				setContextTheme(buildTheme(newConfig));
			}
		},
		[setContextTheme, setContextSetting]
	);

	useIdleTimer({
		name: "15minutes-inactive-go-back-home",
		onIdle: () => {
			navigate(RoutePath.homePage);
		},
		timeout: 1000 * 60 * 5
	});

	// Do not access Admin page without first logging in (with retrieved data)
	useEffect(() => {
		const restaurantId = localStorage.getItem("restaurant_info") ?? "";
		if (!restaurantId) {
			const localConfig = localStorage.getItem("localConfig") ?? "";
			if (localConfig) localStorage.removeItem("localConfig");
			navigate(RoutePath.homePage);
		}
	});

	// Download config from cloud... before starting editing Config.
	useEffect(() => {
		getCloudConfigAndMenu();
		setCurrentSettings(settings);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // I don't want to include settings in dependency list

	useEffect(() => {
		localStorage.setItem("localHideSnoozed", JSON.stringify(currentHideSnoozed));
	}, [currentHideSnoozed]);

	useEffect(() => {
		const localConfig: AppConfig = cloneDeep(currentConfig);
		localConfig.pin = currentPin;
		localConfig.pinOperator = currentPinOperator;
		localConfig.upSellingList = currentUpSellingList;
		localConfig.themes.custom = cloneDeep(currentCustomTheme);
		localConfig.themes.system = defaultConfig.systemTheme;
		localConfig.settings[getIndex(localConfig.settings, currentSettings.kioskId)] = cloneDeep(currentSettings);
		setCurrentConfig(localConfig);
		propagateCurrentConfig(localConfig, currentSettings.kioskId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSettings, currentCustomTheme, currentPin, currentPinOperator, currentUpSellingList, propagateCurrentConfig]); // I don't want to include currentConfig in dependency list

	const getIndex = (settings: SettingsConfig[], kioskId: number): number => {
		if (!kioskId) return 0;
		return settings.findIndex((config: SettingsConfig) => config.kioskId === kioskId);
	};

	const handleChooseKioskId = (newKioskId: number) => {
		let chosenSettings: SettingsConfig;

		const relatedSettingExisting: SettingsConfig | undefined = theme.config.settings.find((config: SettingsConfig) => config.kioskId === newKioskId);
		if (relatedSettingExisting) {
			chosenSettings = relatedSettingExisting;
		} else {
			const newSettings: SettingsConfig = defaultConfig.settings;
			newSettings.kioskId = newKioskId;
			chosenSettings = newSettings;

			const newConfig: AppConfig = cloneDeep(theme.config);
			newConfig.settings.push(newSettings);
			setCurrentConfig(newConfig);
		}

		setCurrentSettings(chosenSettings);
	};

	const handleExitAndUploadConfig = () => {
		if (!isAdmin) {
			navigate(RoutePath.homePage);
		} else {
			message({
				title: t("common.crud.save", { item: t("system.admin.cloud.config") }),
				description: t("system.admin.cloud.saveConfigDesc"),
				okLabel: t("common.yes"),
				okCallback: () => {
					setConfigUploadLoading(true);
					const snoozedParam: number | undefined = currentHideSnoozed === restaurantInfo.hide_snoozed ? undefined : currentHideSnoozed;
					setCloudConfig(theme.config, snoozedParam)
						.then((result: boolean) => {
							if (result) {
								getCloudConfig();
							} else {
								// log("Can't save", LogChannel.cloudSync)
							}
						})
						.finally(() => {
							setConfigUploadLoading(false);
							navigate(RoutePath.homePage);
						});
				},
				thirdActionLabel: t("common.no"),
				thirdActionCallback: () => navigate(RoutePath.homePage),
				cancelLabel: t("common.cancel"),
				cancelCallback: () => {}
			});
		}
	};

	const [tabValue, setTabValue] = useState<number>(0);

	const handleTabChange = (event: SyntheticEvent, newTabValue: number) => {
		setTabValue(newTabValue);
	};

	const TabPanel = (props: TabPanelProps) => {
		const { children, tabValue, index, tabKey, description, ...other } = props;

		return (
			<Box
				role="tabPanel"
				className={`vertical-tabPanel vertical-tabPanel-${tabKey}`}
				hidden={tabValue !== index}
				id={`vertical-tabPanel-${index}`}
				aria-labelledby={`vertical-tab-${index}`}
				sx={{ p: "1.5rem" }}
				{...other}
			>
				<Box className="panelHeader">
					<Typography variant="h4" color="primary">
						{t(`system.admin.panel.${tabKey}`)}
					</Typography>
					<Typography color={theme.systemTheme.palette.text.primary}>{description}</Typography>
				</Box>
				{tabValue === index && children}
				{/* <Box sx={{ height: "30vh" }}></Box> */}
			</Box>
		);
	};

	const a11yProps = (index: number) => {
		return {
			id: `vertical-tab-${index}`,
			"aria-controls": `vertical-tabPanel-${index}`
		};
	};

	return (
		<ThemeProvider theme={theme.systemTheme}>
			<Container className="configContainer" sx={{ backgroundColor: theme.systemTheme.palette.background.paper }}>
				<Box className="header" sx={{ borderColor: `${theme.systemTheme.palette.primary.dark} !important` }}>
					<Button
						className={"back"}
						variant="outlined"
						color="info"
						onClick={handleExitAndUploadConfig}
						size="large"
						endIcon={configUploadLoading ? <Refresh /> : <ArrowBack />}
					>
						{t("common.back")}
					</Button>
					<Stack direction="row" alignItems="baseline" justifyContent="space-between">
						<Box className="iqSelf">
							{!iqSelfLoading && !iqSelfError ? (
								<img className="iqSelfLogo" src={iqSelfImage ?? ""} alt={t("system.admin.title") ?? ""} loading="lazy" />
							) : null}
						</Box>
						<Typography variant="caption" color={theme.systemTheme.palette.text.secondary}>
							{t("checkout.powered")}
						</Typography>
						<Box className="logo">
							{!logoLoading && !logoError ? (
								<img className="labwareLogo" src={logoImage ?? ""} alt={t("system.admin.labware") ?? ""} loading="lazy" />
							) : null}
						</Box>
					</Stack>
				</Box>

				<Box className="main">
					<Tabs
						className="tabMenu"
						orientation="vertical"
						variant="scrollable"
						value={tabValue}
						onChange={handleTabChange}
						aria-label="vertical-tabs"
						sx={{ borderRight: "0.0625rem", borderColor: "divider" }}
					>
						<Tab {...a11yProps(0)} iconPosition="start" icon={<Login />} label={t("system.admin.panel.access")} />
						<Tab {...a11yProps(1)} iconPosition="start" icon={<Settings />} label={t("system.admin.panel.settings")} />
						<Tab {...a11yProps(2)} iconPosition="start" icon={<Cloud />} label={t("system.admin.panel.cloud")} />
						{isAdmin ? <Tab {...a11yProps(3)} iconPosition="start" icon={<ColorLens />} label={t("system.admin.panel.theme")} /> : null}
						{isAdmin ? <Tab {...a11yProps(4)} iconPosition="start" icon={<Preview />} label={t("system.admin.panel.preview")} /> : null}
						{isAdmin ? <Tab {...a11yProps(5)} iconPosition="start" icon={<AddShoppingCart />} label={t("system.admin.panel.upSelling")} /> : null}

						<Tab className="version" disabled label={"v:" + versionNumber} />
					</Tabs>

					<Box className="panels">
						<TabPanel tabValue={tabValue} index={0} tabKey={"access"} description={t("system.admin.access.accessDesc")}>
							<AdminAccess
								isAdmin={isAdmin}
								currentPin={currentPin}
								setCurrentPin={setCurrentPin}
								currentPinOperator={currentPinOperator}
								setCurrentPinOperator={setCurrentPinOperator}
							/>
						</TabPanel>
						<TabPanel tabValue={tabValue} index={1} tabKey={"settings"} description={t("system.admin.settings.kioskProfileDescription")}>
							<AdminSettings
								isAdmin={isAdmin}
								onChooseKioskId={handleChooseKioskId}
								currentSettings={currentSettings}
								setCurrentSettings={setCurrentSettings}
								currentHideSnoozed={currentHideSnoozed}
								setCurrentHideSnoozed={setCurrentHideSnoozed}
								menuVatList={kioskMenu.vatList}
								satispayDisabled={satispayDisabled}
								setSatispayDisabled={setSatispayDisabled}
							/>
						</TabPanel>
						<TabPanel tabValue={tabValue} index={2} tabKey={"cloud"} description={t("system.admin.cloud.cloudDesc")}>
							<AdminCloud isAdmin={isAdmin} disclaimer={disclaimer} disclaimerNotFound={disclaimerNotFound} />
						</TabPanel>
						<TabPanel tabValue={tabValue} index={3} tabKey={"theme"} description={t("system.admin.theme.createThemeAction")}>
							<AdminTheme currentCustomTheme={currentCustomTheme} setCurrentCustomTheme={setCurrentCustomTheme} />
						</TabPanel>
						<TabPanel tabValue={tabValue} index={4} tabKey={"preview"} description={t("system.admin.preview.previewDesc")}>
							<AdminPreview kioskMenu={kioskMenu} />
						</TabPanel>
						<TabPanel tabValue={tabValue} index={5} tabKey={"upSelling"} description={t("system.admin.upSelling.upSellingDesc")}>
							<AdminUpSelling
								pluListFromMenu={kioskMenu.upSellingList}
								currentUpSellingList={currentUpSellingList}
								setCurrentUpSellingList={setCurrentUpSellingList}
							/>
						</TabPanel>
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
	);
};

export default Admin;
