import { memo } from "react";
import useSoundEffect, { SoundEffectProps } from "../../../hooks/effects/useSoundEffect";
import ToggleItem from "../Toggle/ToggleItem";

interface TouchToggleButtonProps extends SoundEffectProps /*, LongPressEffectProps */ {
	active: boolean;
	statusToCompare?: boolean; // toggle buttons become colored when "active" is different from "statusToCompare"
	onToggle: () => void;
}

const TouchToggleButton = ({ muteSound, tone, /*hasLongPress,*/ active, statusToCompare, onToggle }: TouchToggleButtonProps) => {
	const { playSound } = useSoundEffect({ muteSound, tone });
	// const { pressEvent, pressRelease } = useLongPressEffect({ hasLongPress: true, longPressClickCallback: onToggle });

	return (
		<ToggleItem
			disableRipple
			disableTouchRipple
			remDimension={2.75}
			color={active !== statusToCompare ? "success" : "info"}
			checked={active}
			onChange={onToggle}
			onClickCapture={playSound}
			// onTouchStart={pressEvent}
			// onTouchEnd={pressRelease}
			sx={{ my: 0 }}
		/>
	);
};

export default memo(TouchToggleButton);
