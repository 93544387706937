import { useNavigate } from "react-router-dom";
import { useTheme } from "../components/Theme/ThemeWrapper";
import { RoutePath, WebviewApp } from "../constants";
import { callCloseApp, callReloadApp } from "../services/WebviewV1/WebviewV1";

export const useWebview = () => {
	const navigate = useNavigate();
	const { container } = useTheme();

	const appClose = () => {
		switch (container) {
			case WebviewApp.reactNative:
				navigate(RoutePath.closeTrigger);
				break;

			default:
				callCloseApp();
				navigate(RoutePath.homePage);
				break;
		}
	};

	const appReload = () => {
		switch (container) {
			case WebviewApp.reactNative:
				navigate(RoutePath.reloadTrigger);
				break;

			default:
				callReloadApp();
				navigate(RoutePath.homePage);
				break;
		}
	};

	return {
		appClose,
		appReload
	};
};
