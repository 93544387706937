import { CheckCircleOutlineRounded, HighlightOffRounded } from "@mui/icons-material";
import { alpha, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCurrency } from "../../../hooks/useCurrency";
import TouchButton from "../../Layout/Buttons/TouchButton";
import { useTheme } from "../../Theme/ThemeWrapper";
import BottomBar from "./BottomBar";

export interface EditableItemBottomBarProps {
	amount: number;
	cancel: () => void;
	confirm: () => void;
	itemsCount: number;
	disabled: boolean;
}

const EditableItemBottomBar = ({ amount, cancel, confirm, itemsCount, disabled }: EditableItemBottomBarProps) => {
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { currency } = useCurrency();

	const confirmDescription: string = useMemo(() => currency(amount) + " | " + t("checkout.cartBottomBar.addToCart"), [amount, currency, t]);
	const buttonTextColor: string = theme.systemTheme.palette.info.contrastText;
	const buttonBorderColor: string = useMemo(() => alpha(theme.systemTheme.palette.info.contrastText, 0.1), [theme.systemTheme]);

	return (
		<BottomBar>
			<TouchButton
				color="inherit"
				variant="outlined"
				size="large"
				sx={{ color: buttonTextColor, borderColor: buttonBorderColor }}
				startIcon={<HighlightOffRounded />}
				disableElevation
				className="cancel-order-btn"
				onClick={cancel}
			>
				<Typography variant="button">{t("checkout.cartBottomBar.closeDialog")}</Typography>
			</TouchButton>
			<TouchButton
				color="success"
				variant="contained"
				fillBgWhenDisabled={true}
				size="large"
				endIcon={<CheckCircleOutlineRounded />}
				disableElevation
				disabled={disabled}
				onClick={confirm}
				tone="cart"
			>
				<Typography variant="button">{confirmDescription}</Typography>
			</TouchButton>
		</BottomBar>
	);
};

export default memo(EditableItemBottomBar);
