import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LogChannel, RoutePath } from "../constants";
import { useLogger } from "../utils/useLogger";
import SimplePage from "./SimplePage";

const TriggerPage = () => {
	const navigate = useNavigate();
	const { log } = useLogger();

	useEffect(() => {
		log("TriggerPage", LogChannel.router);
		navigate(RoutePath.homePage);
	}, [navigate, log]);

	return <SimplePage />;
};

export default TriggerPage;
