import { memo } from "react";
import { ReactComponent as DE } from "../../assets/flags/de.svg";
import { ReactComponent as ES } from "../../assets/flags/es.svg";
import { ReactComponent as FR } from "../../assets/flags/fr.svg";
import { ReactComponent as GB } from "../../assets/flags/gb.svg";
import { ReactComponent as IT } from "../../assets/flags/it.svg";
import { ReactComponent as NL } from "../../assets/flags/nl.svg";
import { Language } from "../../constants";
import "./LanguageFlag.scss";

export interface LanguageFlagProps {
	language: Language;
	selected?: boolean;
	className?: string;
	onClick?: (param: any) => void;
}

const LangFlagMap = {
	en: GB,
	fr: FR,
	de: DE,
	it: IT,
	es: ES,
	nl: NL
};

const LanguageFlag = ({ language, selected, className, onClick }: LanguageFlagProps) => {
	const LangFlag = LangFlagMap[language];

	return <LangFlag className={(className ?? "") + " language-flag" + ((selected ?? false) ? " selected" : "")} onClick={onClick} />;
};

export default memo(LanguageFlag);
